import {JWTToken} from "../jwt";
import {newStrain, Strain} from "./query";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {CreateStrainRequest, StrainEntity, UpdateStrainRequest} from "../../generated-sources/openapi";
import {CreateUpdateStrainFormValues} from "../../pages/strain/form";


export async function sdGetStrains(token: Promise<JWTToken>): Promise<Strain[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const strains = await client.getAllStrains(undefined, authHeaderBearer(jwt))

    return strains.map(mapStrainEntityToStrain)
}
//
export async function sdCreateStrain(token: Promise<JWTToken>, values: CreateUpdateStrainFormValues): Promise<Strain> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const createStrainRequest: CreateStrainRequest = {
        name: values.name
    }

    const strain = await client.createStrain({createStrainRequest}, authHeaderBearer(jwt))

    return mapStrainEntityToStrain(strain)
}


export async function sdUpdateStrain(token: Promise<JWTToken>, values: CreateUpdateStrainFormValues): Promise<Strain> {
    const {defaultClient} = getAPIClient()
    const jwt = await token

    const {id, name} = values
    const updateStrainRequest: UpdateStrainRequest = {
        name,
    }

    const strain = await defaultClient.updateStrainByID({updateStrainRequest, id}, authHeaderBearer(jwt))

    return mapStrainEntityToStrain(strain)
}



export function mapStrainEntityToStrain(supplier: StrainEntity): Strain {
    return newStrain(
        supplier.id,
        supplier.name,
    )
}