import React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {SpawnForm} from "./form";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";

type SpawnCreateFormProps = {
    onSuccess: () => void
    size: SemanticSIZES
}

export function CreateSpawnBatchModel({size}: SpawnCreateFormProps) {
    const [open, setOpen] = React.useState(false)
    const close = () => setOpen(false)

    const title = "Create Spawn Batch"

    return (
        <Modal
            className={"pad-left-10"}
            closeIcon
            open={open}
            trigger={<Button type={"button"} size={size} color='blue'>{title}</Button>}
            onClose={close}
            onOpen={() => setOpen(true)}
        >
            <Header icon='address card' content='Create Spawn Batch' />
            <Modal.Content>
                <SpawnForm onSuccess={close}/>
            </Modal.Content>
        </Modal>
    )
}
