/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LiquidCultureBatchItemEntity } from './LiquidCultureBatchItemEntity';
import {
    LiquidCultureBatchItemEntityFromJSON,
    LiquidCultureBatchItemEntityFromJSONTyped,
    LiquidCultureBatchItemEntityToJSON,
} from './LiquidCultureBatchItemEntity';
import type { SpawnBatchItemPartialEntity } from './SpawnBatchItemPartialEntity';
import {
    SpawnBatchItemPartialEntityFromJSON,
    SpawnBatchItemPartialEntityFromJSONTyped,
    SpawnBatchItemPartialEntityToJSON,
} from './SpawnBatchItemPartialEntity';

/**
 * create spawnBatch response object
 * @export
 * @interface SpawnBatchEntity
 */
export interface SpawnBatchEntity {
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchEntity
     */
    batchCode: string;
    /**
     * 
     * @type {number}
     * @memberof SpawnBatchEntity
     */
    numberOfContainers: number;
    /**
     * 
     * @type {number}
     * @memberof SpawnBatchEntity
     */
    containerWeightGrams: number;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchEntity
     */
    notes: string;
    /**
     * 
     * @type {Date}
     * @memberof SpawnBatchEntity
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchEntity
     */
    liquidCultureBatchItemId: string;
    /**
     * 
     * @type {LiquidCultureBatchItemEntity}
     * @memberof SpawnBatchEntity
     */
    liquidCultureBatchItem: LiquidCultureBatchItemEntity;
    /**
     * collection for spawn batch item partial
     * @type {Array<SpawnBatchItemPartialEntity>}
     * @memberof SpawnBatchEntity
     */
    items: Array<SpawnBatchItemPartialEntity>;
}

/**
 * Check if a given object implements the SpawnBatchEntity interface.
 */
export function instanceOfSpawnBatchEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "numberOfContainers" in value;
    isInstance = isInstance && "containerWeightGrams" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "liquidCultureBatchItemId" in value;
    isInstance = isInstance && "liquidCultureBatchItem" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function SpawnBatchEntityFromJSON(json: any): SpawnBatchEntity {
    return SpawnBatchEntityFromJSONTyped(json, false);
}

export function SpawnBatchEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpawnBatchEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchCode': json['batchCode'],
        'numberOfContainers': json['numberOfContainers'],
        'containerWeightGrams': json['containerWeightGrams'],
        'notes': json['notes'],
        'startDate': (new Date(json['startDate'])),
        'liquidCultureBatchItemId': json['liquidCultureBatchItemId'],
        'liquidCultureBatchItem': LiquidCultureBatchItemEntityFromJSON(json['liquidCultureBatchItem']),
        'items': ((json['items'] as Array<any>).map(SpawnBatchItemPartialEntityFromJSON)),
    };
}

export function SpawnBatchEntityToJSON(value?: SpawnBatchEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchCode': value.batchCode,
        'numberOfContainers': value.numberOfContainers,
        'containerWeightGrams': value.containerWeightGrams,
        'notes': value.notes,
        'startDate': (value.startDate.toISOString()),
        'liquidCultureBatchItemId': value.liquidCultureBatchItemId,
        'liquidCultureBatchItem': LiquidCultureBatchItemEntityToJSON(value.liquidCultureBatchItem),
        'items': ((value.items as Array<any>).map(SpawnBatchItemPartialEntityToJSON)),
    };
}

