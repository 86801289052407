/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create spawnBatch request object
 * @export
 * @interface UpdateSpawnBatchRequest
 */
export interface UpdateSpawnBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpawnBatchRequest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpawnBatchRequest
     */
    containerWeightGrams: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpawnBatchRequest
     */
    notes: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateSpawnBatchRequest
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpawnBatchRequest
     */
    liquidCultureBatchItemId: string;
}

/**
 * Check if a given object implements the UpdateSpawnBatchRequest interface.
 */
export function instanceOfUpdateSpawnBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "containerWeightGrams" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "liquidCultureBatchItemId" in value;

    return isInstance;
}

export function UpdateSpawnBatchRequestFromJSON(json: any): UpdateSpawnBatchRequest {
    return UpdateSpawnBatchRequestFromJSONTyped(json, false);
}

export function UpdateSpawnBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSpawnBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'containerWeightGrams': json['containerWeightGrams'],
        'notes': json['notes'],
        'startDate': (new Date(json['startDate'])),
        'liquidCultureBatchItemId': json['liquidCultureBatchItemId'],
    };
}

export function UpdateSpawnBatchRequestToJSON(value?: UpdateSpawnBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'containerWeightGrams': value.containerWeightGrams,
        'notes': value.notes,
        'startDate': (value.startDate.toISOString()),
        'liquidCultureBatchItemId': value.liquidCultureBatchItemId,
    };
}

