import {CreateSpawnBatchFormValues} from "../../pages/spawn-batch/form";
import {inMemCreateSpawn, inMemGetSpawnBatches, inMemGetSpawnBatchItems, inMemUpdateSpawnItem} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {SpawnBatch, SpawnBatchItem} from "./entities";
import {sdCreateSpawnBatch, sdGetSpawnBatches, sdGetSpawnBatchItems, sdUpdateSpawnItem} from "./sdapi";
import {JWTToken} from "../jwt";

export async function getSpawnBatches(token: Promise<JWTToken>): Promise<SpawnBatch[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        // return pocketBaseGetSpawns()
        return sdGetSpawnBatches(token)
    }

    return inMemGetSpawnBatches()
}

export async function getSpawnBatchItems(token: Promise<JWTToken>): Promise<SpawnBatchItem[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetSpawnBatchItems(token)
    }

    return inMemGetSpawnBatchItems()
}

export function createSpawnBatchFN(token: Promise<JWTToken>) {
    return async function (values: CreateSpawnBatchFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            return sdCreateSpawnBatch(token, values)
        }

        return inMemCreateSpawn(values)
    }
}


export type UpdateSpawnBatchItemFormValues = {
    id: string
    batchID: string
    contaminated: boolean
}

export function authUpdateSpawnBatchItem(token: Promise<JWTToken>) {
    return async function (values: UpdateSpawnBatchItemFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateSpawnItem(token, values)
            return
        }

        return inMemUpdateSpawnItem(values)
    }
}

let formCache: CreateSpawnBatchFormValues = {
    batchCode: "",
    liquidCultureBatchItemID: "",
    numberContainers: "",
    weightGrams: "",
    notes: "",
    startDate: new Date(),
    markLiquidCultureBatchItemAsSpent: false
}

export async function getSpawnCreateFormCache(): Promise<CreateSpawnBatchFormValues> {
    return formCache
}
export async function updateSpawnCreateFormCache(values: CreateSpawnBatchFormValues) {
    formCache = values
}
