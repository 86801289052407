import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    CultureStarter,
    getCultureStarterCreateFormCache,
    updateCultureStarterCreateFormCache
} from "../../query/culture-starter/query";
import queryKeys from "../../query/keys";
import React, {ChangeEvent, useState} from "react";
import {Formik} from "formik";
import {FormErrors} from "../../components/form/errors";
import {FormGroup, LabelInputStacked, LabelSelectionStacked, selectOption} from "../../components/form/field";
import {DateInput, DateInputOptional} from "../../components/form/DateInput";
import {Form as UIForm} from "semantic-ui-react";
import * as yup from "yup";
import {disableSubmit} from "../../components/form/validation";
import {CreateUpdateStrainModal} from "../strain/modal";
import {getStrains} from "../../query/strain/query";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {getSuppliers} from "../../query/supplier/query";
import {CreateSupplerModal} from "../supplier/modal";
import {useAuthToken} from "../../query/jwt";
import {AuthedFormAction} from "../../components/form/types";
import {resolvePriorityData} from "../../lib/data/resolve";
import {actualOrNull} from "../../lib/null/null";
import {cultureStarters} from "../../query/culture-starter/inmemory";

const DEBUG_LABEL = "CULTURE_STARTER"

export type CreateUpdateCultureStarterFormValues = {
    id: string
    batchCode: string
    source: string
    containerType: string
    incubationStart: Date,
    disposalDate: Date | null,
    strainID: string,
    supplierID: string,
}

export const initialFormValues: CreateUpdateCultureStarterFormValues = {
    id: "",
    batchCode: "",
    source: "outsourced",
    containerType: "syringe",
    incubationStart: new Date(),
    disposalDate: null,
    strainID: "",
    supplierID: "",
}

type CreateFormProps = {
    cultureStarter?: CultureStarter | undefined
    onSuccess: () => void
    formAction: AuthedFormAction<CreateUpdateCultureStarterFormValues>
}

export function CreateUpdateCultureStarterForm({onSuccess, formAction, cultureStarter}: CreateFormProps) {
    const [notifications, setNotifications] = useState(emptyNotifications);
    const getAuthToken = useAuthToken()

    const queryClient = useQueryClient()
    const {mutate: mutateFormCache} = useMutation(updateCultureStarterCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.cultureStarterCreateFormCache)
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)])
        }
    });

    const {mutate: mutateCreateCultureStarter} = useMutation(formAction(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.cultureStarterGet);
            onSuccess()
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `submit error ${err?.message}`)])
        }
    });


    const formCacheQuery = useQuery([queryKeys.cultureStarterCreateFormCache], getCultureStarterCreateFormCache);



    const strainQuery = useQuery([queryKeys.strainGet], () => getStrains(getAuthToken({})))
    const supplierQuery = useQuery([queryKeys.suppliersGet], () => getSuppliers(getAuthToken({})))

    const strainOptions = strainQuery.data
        ? strainQuery.data.map(({name, id}, i) => (selectOption(id, name)))
        : [selectOption("0", "no strain please create")]

    const supplierOptions = supplierQuery.data
        ? supplierQuery.data.map(({name, id}, i) => (selectOption(id, name)))
        : [selectOption("0", "no supplier syringes please create")]


    // set up form state
    const id = cultureStarter?.id || ""
    const [formValues, setFormValues] = useState(resolvePriorityData(formDataFrom(cultureStarter), formCacheQuery.data, initialFormValues))
    const [incubationStart, setIncubationStart] = useState(formValues.incubationStart)
    const [disposalDate, setDisposalDate] = useState(formValues.disposalDate)
    const sourceOptions = [selectOption("insourced", "Insourced"), selectOption("outsourced", "Outsourced")]

    const isCreate = cultureStarter === undefined
    const spent = isCreate ? false : cultureStarter.spent

    return (<div>
        <Formik
            initialValues={formValues}
            onSubmit={(formValues, {setSubmitting}) => {
                setSubmitting(true)
                const newFormValues = {...formValues, incubationStart, disposalDate, id}

                console.log("submitting", newFormValues)

                setFormValues(initialFormValues)
                mutateFormCache(initialFormValues)
                setNotifications([])

                setTimeout(() => {
                    setSubmitting(false)
                    mutateCreateCultureStarter(newFormValues)
                }, 1)
            }}
            validationSchema={buildSchema()}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
              }) => {
                const setFormValuesWithInputChanges = (setFN: (formValues: CreateUpdateCultureStarterFormValues, value: string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                    let newValues = {...values, incubationStart, disposalDate}
                    setFN(newValues, e.target.value)
                    console.log(DEBUG_LABEL, "newValues", newValues)

                    handleChange(e)
                    setFormValues(newValues)
                    mutateFormCache(newValues)
                }

                const disable = disableSubmit(isValid, isSubmitting)

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormErrors errors={JSON.stringify(errors)}/>

                        <FormGroup>
                            <LabelInputStacked
                                name={"batchCode"}
                                label={"Batch Code"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.batchCode = v)}
                            />
                            <LabelSelectionStacked
                                blankOption={false}
                                selected={values.source || ""}
                                options={sourceOptions}
                                name={"source"}
                                label={"Source"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.source = v)}
                            />
                        </FormGroup>


                        {!spent &&
                            <FormGroup>
                                <LabelSelectionStacked
                                    blankOption={true}
                                    selected={values.strainID || ""}
                                    options={strainOptions}
                                    name={"strainID"}
                                    label={"Strain"}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.strainID = v)}
                                />
                                <CreateUpdateStrainModal size={"mini"}/>
                            </FormGroup>
                        }


                        <FormGroup>
                            <LabelSelectionStacked
                                blankOption={true}
                                selected={values.supplierID || ""}
                                options={supplierOptions}
                                name={"supplierID"}
                                label={"Supplier"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.strainID = v)}
                            />
                            <CreateSupplerModal/>
                        </FormGroup>


                        <FormGroup>
                            <DateInput
                                name={"incubationStart"}
                                date={incubationStart}
                                label={"Start"}
                                onChange={setIncubationStart}
                                touched={true}
                            />
                            <DateInputOptional
                                name={"disposalDate"}
                                date={disposalDate}
                                label={"Disposal Date"}
                                onChange={setDisposalDate}
                                touched={true}
                            />
                        </FormGroup>

                        <UIForm.Button positive type={"submit"} disabled={disable}>Create</UIForm.Button>
                    </form>
                )
            }}
        </Formik>
    </div>)
}

function formDataFrom(starter: CultureStarter | undefined): CreateUpdateCultureStarterFormValues | undefined {
    return starter === undefined ? undefined : {
        batchCode: starter.batchCode,
        containerType: starter.containerType,
        disposalDate: actualOrNull(starter.disposalDate),
        id: starter.id,
        incubationStart: starter.incubationStart,
        source: starter.source,
        strainID: starter.strain.id,
        supplierID:starter.supplier.id,
    }
}

function buildSchema() {
     const shape = {
         batchCode: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('batch code required'),
        source: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('source required'),
        incubationStart: yup.date().required("start date required"),
        disposalDate: yup.date().nullable(),
        strainID: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Strain required'),
        supplierID: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Supplier required'),
        markAsSpent: yup.boolean()
    }

    return yup.object().shape(shape)
}