import React, {ChangeEvent, useState} from "react";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getStrainCreateFormCache, Strain, updateStrainCreateFormCache} from "../../query/strain/query";
import queryKeys from "../../query/keys";
import * as yup from "yup";
import {Formik} from "formik";
import {FormErrors} from "../../components/form/errors";
import {LabelInputStacked} from "../../components/form/field";
import {Form as UIForm} from "semantic-ui-react";
import {AuthedFormAction} from "../../components/form/types";
import {resolvePriorityData} from "../../lib/data/resolve";
import {disableSubmit} from "../../components/form/validation";

export type CreateUpdateStrainFormValues = {
    id: string
    name: string
}

const initialStrainFormValues: CreateUpdateStrainFormValues = {
    id: "",
    name: "",
}

type CreateStrainFormProps = {
    onSuccess: () => void
    strain? : Strain
    formAction: AuthedFormAction<CreateUpdateStrainFormValues>
}

export function CreateUpdateStrainForm({strain, formAction, onSuccess}: CreateStrainFormProps) {
    const [notifications, setNotifications] = useState(emptyNotifications);
    const getAuthToken  = useAuthToken()

    const id = strain?.id || ""


    const queryClient = useQueryClient()
    const { mutate: mutateFormCache } = useMutation(updateStrainCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.strainCreateFormCache);
        },
        onError: (err) => {
            setNotifications((notifactions) => [...notifactions, newNotification("error", `form cache error: ${err}`)]  )
        }

    });
    const formCacheQuery = useQuery([queryKeys.strainCreateFormCache], getStrainCreateFormCache)
    const { mutate: mutateCreateStrain } = useMutation(formAction(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.strainGet);
            onSuccess()
        },
        onError: (err) => {
            setNotifications((notifactions) => [...notifactions, newNotification("error", `create strain error: ${err}`)]  )
        }
    });


    const startingState = resolvePriorityData(formDataFrom(strain), formCacheQuery.data, initialStrainFormValues)
    const [formValues, setFormValues] = useState(startingState)


    const formSchema = yup.object().shape({
        name: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    });

    return (<div>
        <Formik
            initialValues={formValues}
            validationSchema={formSchema}
            onSubmit={(formValues, {setSubmitting}) => {
                setSubmitting(true)

                formValues.id = id
                mutateCreateStrain(formValues)
                mutateFormCache(initialStrainFormValues)
                setNotifications([])

                setTimeout(() => {
                    setSubmitting(false)
                    setFormValues(initialStrainFormValues)
                }, 1)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid
              }) => {
                const setFormValuesWithInputChanges = (setFN: (formValues: CreateUpdateStrainFormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                    let newValues = {...values}
                    setFN(newValues, e.target.value)

                    handleChange(e)
                    setFormValues(newValues)
                    mutateFormCache(newValues)
                }

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <FormErrors errors={JSON.stringify(errors)}/>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>

                        <LabelInputStacked
                            name={"name"}
                            label={"Name"}
                            onChange={setFormValuesWithInputChanges((f, v) => f.name = v)}
                        />

                        <UIForm.Button
                            positive
                            disabled={disableSubmit(isValid, isSubmitting, touched)}
                            type={"submit"}>
                                {id==="" ? "Create" : "Update"}
                        </UIForm.Button>
                    </form>)
            }}
        </Formik>
    </div>)
}

function formDataFrom(s: Strain | undefined): CreateUpdateStrainFormValues | undefined{
    return s === undefined ? undefined : {id: s.id, name: s.name}
}