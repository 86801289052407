import {Recipe} from "../recipe/query";
import {UnitType} from "../unit-type/inmemory";

export type BlankBatch = {
    id: string
    batchCode: string
    state: BlankState
    createdDate: Date,
    pasteurizedDate: Date | undefined,
    recipe: Recipe,
    unitType: UnitType,
    numberOfItems: number,
    items: BlankBatchItem[],
    notes: string
}

type BlankState = "unpasteurized" | "pasteurized"

export const displayBlankState = (state: BlankState) => {
    switch (state) {
        case "pasteurized":
            return "Pasteurized"
        default:
            return "Unpasteurized";
    }
}

export const newBlankBatch = (
    id: string,
    batchCode: string,
    createdDate: Date,
    pasteurizedDate: Date | undefined,
    recipe: Recipe,
    unitType: UnitType,
    numberOfItems: number,
    notes: string= "",
    items: BlankBatchItem[] = []
): BlankBatch => ({
    id,
    batchCode,
    state:pasteurizedDate === undefined ? "unpasteurized" : "pasteurized",
    createdDate,
    pasteurizedDate,
    recipe,
    unitType,
    numberOfItems,
    notes,
    items,
})


export type BlankBatchItem = {
    id: string
    batchId: string
    reference: string
    inoculated: boolean
    contaminated: boolean
}

export const newBlankBatchItem = (id: string, batchId: string, reference: string, inoculated: boolean, contaminated: boolean = false): BlankBatchItem => ({id, batchId, reference, inoculated, contaminated})

export const noneContaminated = (item: BlankBatchItem): boolean => !item.contaminated
