import React from "react";
import {Button} from "semantic-ui-react";
import {CreateUpdateCultureStarterForm, CreateUpdateCultureStarterFormValues} from "./form";
import {authCreateCultureStarter, authUpdateCultureStarter, CultureStarter} from "../../query/culture-starter/query";
import {IconEdit} from "../../components/icon/common";
import {BasicModal} from "../../components/modal/basic";
import {useModal} from "../../hooks/useModal";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";
import {AuthedFormAction} from "../../components/form/types";


type CreateModalProps = {
    cultureStarter?: CultureStarter | undefined
    size: SemanticSIZES
}

export function CreateUpdateCultureStarterModal({cultureStarter, size}: CreateModalProps) {
    const {isOpen, onOpen, onClose} = useModal()

    const isCreate = cultureStarter === undefined


    const title = isCreate
        ? "Create Syringe (Culture Starter)"
        : "Update Syringe (Culture Starter)"

    const trigger = isCreate
        ? <Button size={size} color={"blue"} >{title}</Button>
        : <Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>

    const action: AuthedFormAction<CreateUpdateCultureStarterFormValues> = isCreate
        ? authCreateCultureStarter
        : authUpdateCultureStarter

    return <BasicModal
        header={title}
        trigger={trigger}
        isOpen={isOpen}
        open={onOpen}
        close={onClose}
    >
        <CreateUpdateCultureStarterForm cultureStarter={cultureStarter} formAction={action} onSuccess={() => onClose()}/>
    </BasicModal>
}
