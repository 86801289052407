/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpawnBatchDetails } from './SpawnBatchDetails';
import {
    SpawnBatchDetailsFromJSON,
    SpawnBatchDetailsFromJSONTyped,
    SpawnBatchDetailsToJSON,
} from './SpawnBatchDetails';

/**
 * 
 * @export
 * @interface SpawnBatchItemEntity
 */
export interface SpawnBatchItemEntity {
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchItemEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchItemEntity
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchItemEntity
     */
    batchId: string;
    /**
     * 
     * @type {SpawnBatchDetails}
     * @memberof SpawnBatchItemEntity
     */
    batch: SpawnBatchDetails;
    /**
     * 
     * @type {boolean}
     * @memberof SpawnBatchItemEntity
     */
    spent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SpawnBatchItemEntity
     */
    contaminated: boolean;
}

/**
 * Check if a given object implements the SpawnBatchItemEntity interface.
 */
export function instanceOfSpawnBatchItemEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "batchId" in value;
    isInstance = isInstance && "batch" in value;
    isInstance = isInstance && "spent" in value;
    isInstance = isInstance && "contaminated" in value;

    return isInstance;
}

export function SpawnBatchItemEntityFromJSON(json: any): SpawnBatchItemEntity {
    return SpawnBatchItemEntityFromJSONTyped(json, false);
}

export function SpawnBatchItemEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpawnBatchItemEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': json['reference'],
        'batchId': json['batchId'],
        'batch': SpawnBatchDetailsFromJSON(json['batch']),
        'spent': json['spent'],
        'contaminated': json['contaminated'],
    };
}

export function SpawnBatchItemEntityToJSON(value?: SpawnBatchItemEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'batchId': value.batchId,
        'batch': SpawnBatchDetailsToJSON(value.batch),
        'spent': value.spent,
        'contaminated': value.contaminated,
    };
}

