/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create blankBatchItem response object
 * @export
 * @interface BlankBatchItemPartialEntity
 */
export interface BlankBatchItemPartialEntity {
    /**
     * 
     * @type {string}
     * @memberof BlankBatchItemPartialEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BlankBatchItemPartialEntity
     */
    batchId: string;
    /**
     * 
     * @type {string}
     * @memberof BlankBatchItemPartialEntity
     */
    reference: string;
    /**
     * 
     * @type {boolean}
     * @memberof BlankBatchItemPartialEntity
     */
    inoculated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BlankBatchItemPartialEntity
     */
    contaminated: boolean;
}

/**
 * Check if a given object implements the BlankBatchItemPartialEntity interface.
 */
export function instanceOfBlankBatchItemPartialEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "batchId" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "inoculated" in value;
    isInstance = isInstance && "contaminated" in value;

    return isInstance;
}

export function BlankBatchItemPartialEntityFromJSON(json: any): BlankBatchItemPartialEntity {
    return BlankBatchItemPartialEntityFromJSONTyped(json, false);
}

export function BlankBatchItemPartialEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlankBatchItemPartialEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchId': json['batchId'],
        'reference': json['reference'],
        'inoculated': json['inoculated'],
        'contaminated': json['contaminated'],
    };
}

export function BlankBatchItemPartialEntityToJSON(value?: BlankBatchItemPartialEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchId': value.batchId,
        'reference': value.reference,
        'inoculated': value.inoculated,
        'contaminated': value.contaminated,
    };
}

