


// actualOrNull converts null to undefined
export function actualOrUndefined<T>(actual: T | null): T | undefined{
    return actual === null ? undefined : actual
}

// actualOrNull converts undefined to null
export function actualOrNull<T>(actual: T | undefined): T | null{
    return actual === undefined ? null : actual
}