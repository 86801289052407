import {
    inMemCreateLiquidCultureBatch,
    inMemGetLiquidCultureBatches,
    inMemGetLiquidCultureBatchItems,
    inMemUpdateLiquidCultureBatch
} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {CreateLiquidCultureBatchFormValues} from "../../pages/liquid-culture-batch/form";
import {LiquidCultureBatch, LiquidCultureBatchItem} from "./entities";
import {
    sdCreateLiquidCulture,
    sdGetAllLiquidCultureBatchItems,
    sdGetLiquidCultureBatches,
    sdUpdateLiquidCulture
} from "./sdapi";
import {JWTToken} from "../jwt";

export async function getLiquidCultureBatches(token: Promise<JWTToken>): Promise<LiquidCultureBatch[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetLiquidCultureBatches(token)
    }

    return inMemGetLiquidCultureBatches()
}

export async function getLiquidCultureBatchItems(token: Promise<JWTToken>): Promise<LiquidCultureBatchItem[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetAllLiquidCultureBatchItems(token)
    }

    return inMemGetLiquidCultureBatchItems()
}

export function authedCreateLiquidCultureBatch(token: Promise<JWTToken>) {
   return async function (values: CreateLiquidCultureBatchFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdCreateLiquidCulture(token, values)
            return
        }

        return inMemCreateLiquidCultureBatch(values)
    }
}

export function authedUpdateLiquidCultureBatch(token: Promise<JWTToken>) {
    return async function (values: CreateLiquidCultureBatchFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateLiquidCulture(token, values)
            return
        }

        return inMemUpdateLiquidCultureBatch(values)
    }
}

let formCache: CreateLiquidCultureBatchFormValues = {
    id: "",
    batchCode: "initBatchCode",
    cultureStarterID: "initstring",
    // syringe
    numberOfContainers: "initstring",
    startDate: new Date(),
    disposalDate: null,
    volume: "",
}

export async function getLiquidCultureCreateFormCache(): Promise<CreateLiquidCultureBatchFormValues> {
    return formCache
}
export async function updateLiquidCultureCreateFormCache(values: CreateLiquidCultureBatchFormValues) {
    formCache = values
}
