import {Table} from "semantic-ui-react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import React from "react";
import {getLiquidCultureBatches, } from "../../query/liquid-culture-batch/query";
import {LiquidCultureBatch} from "../../query/liquid-culture-batch/entities";
import {CreateUpdateLiquidCultureModal} from "./modal";
import {formatDateString} from "../../components/form/DateInput";
import {useAuthToken} from "../../query/jwt";
import {TableHeader} from "../../components/table/header";


export function LiquidCultureBatchTablePage(props: {}) {
    const getAuthToken = useAuthToken()


    const title = "Liquid Culture Batches"
    const queryKey = queryKeys.liquidCultureBatchesGet
    const headers = [
        "Batch Code",
        "Strain",
        "# Containers / Remaining / Spent",
        "Volume (ml)",
        "Start Date",
        "Disposal Date",
        "Actions",
    ]
    const numberOfCols = headers.length
    const header = <TableHeader headers={headers}/>

    const rowsMapper = (batch: LiquidCultureBatch) => {
        const spentItems = calculateSpent(batch)
        const remaining = batch.numberOfContainers - spentItems

        const className = spentItems === batch.numberOfContainers ? "negative" : ""

        return (
            <Table.Row className={className} key={batch.id}>
                <Table.Cell>{batch.batchCode}</Table.Cell>
                <Table.Cell>{batch.cultureStarter.strain.name}</Table.Cell>
                <Table.Cell>{batch.numberOfContainers} / {remaining} /{spentItems} </Table.Cell>
                <Table.Cell>{batch.volume}</Table.Cell>
                <Table.Cell>{formatDateString(batch.startDate)}</Table.Cell>
                <Table.Cell>{formatDateString(batch.disposalDate)}</Table.Cell>
                <Table.Cell><CreateUpdateLiquidCultureModal size={"small"} batch={batch}/> </Table.Cell>
            </Table.Row>
        )
    }

    const createButton = <CreateUpdateLiquidCultureModal size={"small"}/>
    return(
        <CRUDQueryTablePage<LiquidCultureBatch>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getLiquidCultureBatches(getAuthToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberOfCols}
        />
    )
}


function calculateSpent(batch: LiquidCultureBatch) {
    const spentItems = batch.items.filter(item => item.spent)
    return spentItems.length
}


