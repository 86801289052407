import {getSupplierByID, suppliers} from "../supplier/inmemory";
import { Supplier } from "../supplier/query";
import {CreateUpdateUnitTypeFormValues} from "../../pages/unit-type/form";
import {findAndReplace} from "../inmemory";


export const newUnitType = (id: string, name: string, supplier: Supplier, fillWeight: number): UnitType => ({id, name, supplier, supplierID: supplier.id, fillWeight})
export const UnitTypeOne = newUnitType("unittype-1", "jars", suppliers[1], 2)

export type UnitType = {
    id: string
    name: string
    supplierID: string
    supplier: Supplier
    fillWeight: number
    // Fill weight (kg
}

export type CreateUnitType = {
    name: string
    supplierID: string
}


export let unitTypes = [
    UnitTypeOne,
    newUnitType("unittype-2", "bags", suppliers[1], 11),
]

export const getUnitTypesData = () => unitTypes
export const getUnitTypeByID = (id:string|undefined) => getUnitTypesData().find(_unitType => _unitType.id === (id || ""))

export async function inMemGetUnitTypes(): Promise<UnitType[]> {
    return unitTypes
}

export async function inMemCreateUnitType(create: CreateUpdateUnitTypeFormValues): Promise<void> {
    const supplier = getSupplierByID(create.supplierID)
    if (!supplier) {
        throw new Error(`supplier ID ${create.supplierID} not found`)
    }

    const fillWeightInt = parseInt(create.fillWeight, 10)

    unitTypes = [...unitTypes, newUnitType(`unittype-${unitTypes.length+1}`, create.name, supplier, fillWeightInt)]
}




export async function inMemUpdateUnitType(update: CreateUpdateUnitTypeFormValues): Promise<void> {
    const supplier = getSupplierByID(update.supplierID)
    if (!supplier) {
        throw new Error(`supplier ID ${update.supplierID} not found`)
    }

    const fillWeightInt = parseInt(update.fillWeight, 10)

    unitTypes = findAndReplace(
        unitTypes,
        ({id}) => id === update.id ,
        () => newUnitType(update.id, update.name, supplier, fillWeightInt),
    )
}
