/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubstrateBatchEntity } from './SubstrateBatchEntity';
import {
    SubstrateBatchEntityFromJSON,
    SubstrateBatchEntityFromJSONTyped,
    SubstrateBatchEntityToJSON,
} from './SubstrateBatchEntity';

/**
 * create harvest response object
 * @export
 * @interface HarvestEntity
 */
export interface HarvestEntity {
    /**
     * 
     * @type {string}
     * @memberof HarvestEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HarvestEntity
     */
    flush: string;
    /**
     * 
     * @type {string}
     * @memberof HarvestEntity
     */
    substrateBatchId: string;
    /**
     * 
     * @type {SubstrateBatchEntity}
     * @memberof HarvestEntity
     */
    substrateBatch: SubstrateBatchEntity;
    /**
     * 
     * @type {Array<number>}
     * @memberof HarvestEntity
     */
    weights: Array<number>;
}

/**
 * Check if a given object implements the HarvestEntity interface.
 */
export function instanceOfHarvestEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "flush" in value;
    isInstance = isInstance && "substrateBatchId" in value;
    isInstance = isInstance && "substrateBatch" in value;
    isInstance = isInstance && "weights" in value;

    return isInstance;
}

export function HarvestEntityFromJSON(json: any): HarvestEntity {
    return HarvestEntityFromJSONTyped(json, false);
}

export function HarvestEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarvestEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'flush': json['flush'],
        'substrateBatchId': json['substrateBatchId'],
        'substrateBatch': SubstrateBatchEntityFromJSON(json['substrateBatch']),
        'weights': json['weights'],
    };
}

export function HarvestEntityToJSON(value?: HarvestEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'flush': value.flush,
        'substrateBatchId': value.substrateBatchId,
        'substrateBatch': SubstrateBatchEntityToJSON(value.substrateBatch),
        'weights': value.weights,
    };
}

