import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {formikFormClasses} from "./validation";


export const formatDateString = (date: Date | undefined | null, defaultDisplay?:string) => {
    if (date === undefined || date === null) {
        return defaultDisplay||""
    }

    return date.toISOString().split('T')[0]
}

const InValidDate = new Date("Invalid Date");
export const isValid = (date: Date | undefined | null): boolean => {
    if (date === undefined || date === null) {
        return false
    }

    return date.toString() !== InValidDate.toString()
};

export type SetFieldDateFN = (field: string, value: Date, shouldValidate?: boolean | undefined) => void
interface DatePickerProps {
    name: string
    date: Date | undefined
    label: string
    onChange: (date: Date) => any
    touched: boolean
}

export const DateInput = (props: DatePickerProps): JSX.Element => {
    const {name, touched, date, label, onChange} = props;
    const selectedDate = isValid(date) ? date : new Date()

    return (
        <div className={`field`}>
            <label>{label}: </label>
            <div className="ui fluid input">
                <DatePicker
                    selected={selectedDate}
                    dateFormat={"yyyy-MM-dd"}
                    className={formikFormClasses("", touched) + " form-control"}
                    name={name}
                    onChange={handleBSTBug(onChange)}
                />
            </div>
        </div>
    )
};


interface DateInputOptionalProps {
    name: string
    date: Date | null
    label?: string
    onChange: (date: Date | null) => any
    touched: boolean | undefined
}

export const initOptionalDate = (): Date | null => null

export const DateInputOptional = (props: DateInputOptionalProps): JSX.Element => {
    const {name, touched, date, label, onChange} = props;
    const selectedDate = isValid(date) ? date : null

    console.log("selectedDate", selectedDate       )

    const displayLabel = label === undefined ? "" : label+":"

    return (
        <div className={`field`}>
            <label>{displayLabel}</label>
            <div className="ui fluid input">
                <DatePicker
                    selected={selectedDate}
                    dateFormat={"yyyy-MM-dd"}
                    className={formikFormClasses("", touched) + " form-control"}
                    name={name}
                    onChange={handleBSTBugNull(onChange)}
                />
            </div>
        </div>
    )
};


// This is really Hacky, but it works foir now
// Since these are date pickers we don't care about the time. So to account BST we set the time to 00:01:00 then add 1 hour
// That way in conversion doesn't push the date back into the previous day
function handleBSTBug(onChange: (date: Date) => any): (date: Date) => any {
    return (date: Date) => {
        const newDate = new Date(date.getTime())
        newDate.setHours(1)
        onChange(newDate)
    }
}

function handleBSTBugNull(onChange: (date: Date | null) => any): (date: Date | null) => any {
    return (date: Date| null) => {
        if (date === null) {
            onChange(null)
            return
        }

        const newDate = new Date(date.getTime())
        newDate.setHours(1)
        onChange(newDate)
    }
}
