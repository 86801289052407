import React from "react";
import {Button} from "semantic-ui-react";
import {CreateUpdateStrainForm, CreateUpdateStrainFormValues} from "./form";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";
import {BasicModal} from "../../components/modal/basic";
import {useModal} from "../../hooks/useModal";
import {createStrainFN, Strain, updateStrainFN} from "../../query/strain/query";
import {AuthedFormAction} from "../../components/form/types";
import {IconEdit} from "../../components/icon/common";


type StrainCreateUpdateModalProps = {
    strain?: Strain | undefined
    size?: SemanticSIZES
}

export function CreateUpdateStrainModal({strain, size}: StrainCreateUpdateModalProps) {
    const isCreate = strain === undefined
    const title = isCreate? "Create Strain" : "Update Strain"
    const {isOpen, onOpen, onClose} = useModal()

    const action: AuthedFormAction<CreateUpdateStrainFormValues> = isCreate ? createStrainFN : updateStrainFN

    const trigger = isCreate
        ? <Button type={"button"} size={size} color='blue'>{title}</Button>
        : <Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>

    return (
        <BasicModal
            close={onClose}
            trigger={trigger}
            header={title}
            isOpen={isOpen}
            open={onOpen}>
                <CreateUpdateStrainForm strain={strain} formAction={action} onSuccess={onClose}/>
        </BasicModal>
    )
}
