import {Table} from "semantic-ui-react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import React from "react";
import {CreateUnitTypeModal, UpdateUnitTypeModal} from "./modal";
import {UnitType} from "../../query/unit-type/inmemory";
import {getUnitTypes} from "../../query/unit-type/query";
import {useAuthToken} from "../../query/jwt";
import {TableHeader} from "../../components/table/header";


export function UnitTypeTablePage(props: {}) {
    const getToken = useAuthToken()
    // header: JSX.Element
    const queryKey = queryKeys.unitTypeGet
    const title = "Unit Types"
    const headers = ["Name", "Supplier", "Fill Weight", "Actions"]
    const header = <TableHeader headers={headers}/>
    const numberCols = headers.length

    const rowsMapper = (unitType: UnitType) => {
        return (
            <Table.Row key={unitType.id}>
                <Table.Cell>{unitType.name}</Table.Cell>
                <Table.Cell>{unitType.supplier.name}</Table.Cell>
                <Table.Cell>{unitType.fillWeight}</Table.Cell>
                <Table.Cell><UpdateUnitTypeModal unitType={unitType} /></Table.Cell>
            </Table.Row>
        )
    }

    const createButton = <CreateUnitTypeModal size={"sml"} supplierID={undefined} supplierName={undefined}/>

    return(
        <CRUDQueryTablePage<UnitType>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getUnitTypes(getToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberCols}
        />
    )
}