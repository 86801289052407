import {useMutation, useQuery, useQueryClient} from "react-query";
import {createSpawnBatchFN, getSpawnCreateFormCache, updateSpawnCreateFormCache} from "../../query/spawn-batch/query";
import queryKeys from "../../query/keys";
import React, {ChangeEvent, useState} from "react";
import * as yup from "yup";
import {Formik} from "formik";
import {FormErrors} from "../../components/form/errors";
import {FormGroup, LabelInputStacked, LabelSelectionStacked, selectOption} from "../../components/form/field";
import {Form as UIForm} from "semantic-ui-react";
import {DateInput} from "../../components/form/DateInput";
import {getLiquidCultureBatchItems} from "../../query/liquid-culture-batch/query";
import logger from "../../log/logger"
import {CreateUpdateLiquidCultureModal} from "../liquid-culture-batch/modal";
import {disableSubmit} from "../../components/form/validation";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {LiquidCultureBatchItem} from "../../query/liquid-culture-batch/entities";
import {CheckBox} from "../../components/form/CheckBox";
import {sortByDate} from "../../util/sort/datesort";

// const LOG_LABEL = "SPAWN_BATCH_FORM"

export type CreateSpawnBatchFormValues = {
    batchCode: string
    liquidCultureBatchItemID: string
    numberContainers: string
    weightGrams: string,
    notes: string
    startDate: Date,
    markLiquidCultureBatchItemAsSpent: boolean
}

const initialSpawnBatchFormValues: CreateSpawnBatchFormValues = {
    batchCode: "",
    liquidCultureBatchItemID: "",
    numberContainers: "1",
    weightGrams: "",
    notes: "",
    startDate: new Date(),
    markLiquidCultureBatchItemAsSpent: false
}

type CreateSpawnFormProps = {
    onSuccess: () => void
}

export function SpawnForm({onSuccess}: CreateSpawnFormProps) {
    const [notifications, setNotifications] = useState(emptyNotifications);
    const [checkLiquidCultureSpent, setCheckLiquidCultureSpent] = useState(false)

    const getAuthToken = useAuthToken()
    const queryClient = useQueryClient()
    // form cache
    const { mutate: formCacheMutate } = useMutation(updateSpawnCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.spawnBatchCreateFormCache).catch(err => logger.warning(err));
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)]  )
        }
    });

    // mutate create spawn batch
    const { mutate: mutateCreateSpawnBatch } = useMutation(createSpawnBatchFN(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.spawnBatchesGet);
            onSuccess()
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `submit error ${err?.message}`)]  )
        }
    });


    const formCacheQuery = useQuery([queryKeys.spawnBatchCreateFormCache], getSpawnCreateFormCache)
    const liquidCultureItemQuery = useQuery([queryKeys.liquidCultureBatchItemsGet], () => getLiquidCultureBatchItems(getAuthToken()))

    // set up form state
    const startingState = formCacheQuery.data || initialSpawnBatchFormValues
    const [formValues, setFormValues] = useState(startingState)
    const [startDate, setStartDate] = useState(startingState.startDate)

    const liquidCultureOptions = liquidCultureItemQuery.data
        ? liquidCultureItemQuery.data
            .filter(({spent}) => !spent) // todo do this on the api endpoint
            .sort(sortByDate<LiquidCultureBatchItem>(({batch}) => batch.startDate ))
            .map(({reference, id, batch}, i) => (selectOption(id, ` ${batch.cultureStarter.strain.name} | ${reference}`)))
        : [selectOption("0", "no strain please create")]

    const formSchema = yup.object().shape({
        batchCode: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        liquidCultureBatchItemID: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        numberContainers: yup.number()
            .min(0, 'Too Short!')
            .required('Required'),

        weightGrams: yup.number()
            .min(0, 'Too Short!')
            .required('Required'),
        notes: yup.string().max(1000, 'Too Long!'),
        startDate: yup.date().required(),
    });

    return (<div>
        <Formik
            initialValues={formValues}
            validate={createFormValidate}
            validationSchema={formSchema}
            onSubmit={(formValues, {setSubmitting}) => {
                setSubmitting(true)
                const newValues = {...formValues, startDate, markLiquidCultureBatchItemAsSpent: checkLiquidCultureSpent}

                console.log("newValues", newValues)

                setFormValues(initialSpawnBatchFormValues)
                formCacheMutate(initialSpawnBatchFormValues)
                setNotifications([])

                setTimeout(() => {
                    mutateCreateSpawnBatch(newValues)
                    setSubmitting(false)
                }, 1)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                isValid,
                isSubmitting,
                  handleChange,
                  handleSubmit,
              }) => {
                const setFormValuesWithInputChanges = (setFN: (formValues: CreateSpawnBatchFormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                    let newValues = {...values, startDate}
                    setFN(newValues, e.target.value)

                    handleChange(e)
                    setFormValues(newValues)
                    formCacheMutate(newValues)
                }

                const disable = disableSubmit(isValid, isSubmitting, touched)

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormErrors errors={JSON.stringify(errors)}/>

                        <FormGroup>
                            <LabelInputStacked
                                name={"batchCode"}
                                label={"Batch Code"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.batchCode = v)}
                            />

                            <LabelInputStacked
                                name={"numberContainers"}
                                type={"number"}
                                label={"# Jars"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.numberContainers = v)}
                            />
                            <LabelInputStacked
                                name={"weightGrams"}
                                type={"number"}
                                label={"Weight (g)"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.weightGrams = v)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <LabelSelectionStacked
                                blankOption={true}
                                selected={values.liquidCultureBatchItemID || ""}
                                options={liquidCultureOptions}
                                name={"liquidCultureBatchItemID"}
                                label={"Liquid Culture Item"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.liquidCultureBatchItemID = v)}
                            />
                            <CreateUpdateLiquidCultureModal size={"small"}/>
                        </FormGroup>
                        <FormGroup>
                            {/*onChange={setFormValuesWithInputChanges((f, v) => f.liquidCultureBatchItemSpent = v)}*/}
                            <CheckBox name={"liquidCultureBatchItemSpent"} label={"Mark Liquid Culture Batch Item as Spent"} onChange={() => setCheckLiquidCultureSpent(!checkLiquidCultureSpent)} />
                        </FormGroup>


                        <FormGroup>
                            <DateInput
                                name={"startDate"}
                                date={startDate}
                                label={"Start"}
                                onChange={setStartDate}
                                touched={true}
                            />
                        </FormGroup>


                        <LabelInputStacked
                            name={"notes"}
                            label={"Notes"}
                            onChange={setFormValuesWithInputChanges((f, v) => f.notes = v)}
                        />

                        <UIForm.Button positive type={"submit"} disabled={disable}>Create</UIForm.Button>
                    </form>)
            }}
        </Formik>
    </div>)
}

function createFormValidate() {
    return undefined
}
