import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'semantic-ui-react';
import mainClassNames, {classes} from "../../styling/css";

const css = mainClassNames

export function Login() {
    const {loginWithRedirect} = useAuth0();

    return (<Button color="teal" onClick={() => loginWithRedirect()}>Log in</Button>)
}



function LandingPage() {
    const {loginWithRedirect} = useAuth0();

    // // State for the email input
    // const [email, setEmail] = useState('');
    //
    // // Function to handle form submission
    // const handleSubmit = (e: React.FormEvent<HTMLFormElement> ) => {
    //     e.preventDefault();
    //     // Handle form submission logic here (e.g., send data to a server)
    //     console.log(`Email submitted: ${email}`);
    // };
    //
    // const divStyle = {
    //     height: '100%',
    //     // position: "fixed",
    // };
    //
    // return (
    //     <div style={divStyle}>
    //         <Segment vertical textAlign="center" className="hero" >
    //             <Container textAlign="right">
    //                 <Login/>
    //             </Container>
    //             <Container >
    //                 <div className="ui huge header">Sporedrive MAKE TEXT REALLY LARGE</div>
    //                 <Header as="h1" content="Mushroom Tracking and Planning Application" />
    //                 <p>
    //                     Sporedrive is a mushroom tracking and planning application. Built for small and large scale mushroom growers. Sporedrive mission is simple, help a grower track his current inventory of substrate along its growth cycle as well as help plan substrate for orders in the future.
    //                 </p>
    //                 <p>
    //                     Currently Sporedrive is development and will open for sign up in the future. If you are interested in being a beta tester please sign up below.
    //                 </p>
    //             </Container>
    //         </Segment>
    //
    //         <Segment vertical textAlign="center" className="footer">
    //             <Container>
    //                 <p>&copy; {new Date().getFullYear()} Glasgow Mushroom Company. All rights reserved.</p>
    //             </Container>
    //         </Segment>
    //     </div>
    // );


    const LoginText = () => <p onClick={() => loginWithRedirect()} className={classes(css.heavyFontFlex)}> Sporedrive </p>

    return (
        <div className={classes(css.app)}>
            <header className={classes(css.appHeader)}>
                {/*<Button as={LoginText} onClick={() => loginWithRedirect()}/>*/}
                <LoginText/>
                <p className={classes(css.tinyFontFlex)}>&copy; {new Date().getFullYear()} Glasgow Mushroom Company. All rights reserved.</p>
            </header>
        </div>
    );
}

export default LandingPage;