import React from "react";
import {Table} from "semantic-ui-react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import {CultureStarter, getCultureStarters} from "../../query/culture-starter/query";
import {CreateUpdateCultureStarterModal} from "./modal";
import {formatDateString} from "../../components/form/DateInput";
import {useAuthToken} from "../../query/jwt";
import {sortByDate} from "../../util/sort/datesort";
import {TableHeader} from "../../components/table/header";


export function CultureStarterTablePage(props: {}) {
    const getAuthToken = useAuthToken()
    const queryKey = queryKeys.cultureStarterGet
    const title = "Syringes"
    const headers = ["Batch Code", "Strain", "Start Date", "Disposal Date", "Supplier", "Actions"]
    const numberCols = headers.length
    const header = <TableHeader headers={headers}/>

    const rowsMapper = (cultureStarter: CultureStarter) => {
        const className = cultureStarter.spent ? "negative" : ""

        return (
            <Table.Row className={className} key={cultureStarter.id}>
                <Table.Cell>{cultureStarter.batchCode}</Table.Cell>
                <Table.Cell>{cultureStarter.strain.name}</Table.Cell>
                <Table.Cell>{formatDateString(cultureStarter.incubationStart, "--")}</Table.Cell>
                <Table.Cell>{formatDateString(cultureStarter.disposalDate, "--")}</Table.Cell>
                <Table.Cell>{cultureStarter.supplier.name}</Table.Cell>
                <Table.Cell><CreateUpdateCultureStarterModal size={"small"} cultureStarter={cultureStarter}/></Table.Cell>
            </Table.Row>
        )
    }

    const createButton = <CreateUpdateCultureStarterModal size={"small"}/>

    return(
        <CRUDQueryTablePage<CultureStarter>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getCultureStarters(getAuthToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberCols}
            rowSort={(items: CultureStarter[]): CultureStarter[] => items.sort(cultureStartersSorter)}
        />
    )
}


export const cultureStartersSorter = sortByDate<CultureStarter>( (starter) => starter.incubationStart )


