import {
    SpawnBatch,
    SpawnBatchItem,
    SpawnBatchItemPartial,
    spawnBatchItemPartialToSpawnBatchItem,
    spawnBatchItemToPartial
} from "../spawn-batch/entities";
import {
    inMemSpawnBatchOne, spawnBatch1Item1, spawnBatch1Item2,
    spawnBatchItemPartialStore, spawnBatchStore,
    updateInMemorySpawnBatchItems
} from "../spawn-batch/inmemory";
import {
    newSubstrateBatchV2,
    SubstrateBatchV2
} from "./entities";
import {CreateSubstrateBatchFormValuesV2} from "./query";
import {blankBatchItemStore} from "../blank-batch/inmemory";
import {inMemoryStoreFromArray, newInmemoryStore} from "../inmemory";
import {BlankBatchItem} from "../blank-batch/entities";

// V2 store
export const substrateBatchesV2Store = newInmemoryStore<SubstrateBatchV2>()
substrateBatchesV2Store.add(newSubstrateBatchV2("sb-001", "ba-001", new Date('2023-12-04T00:00:00'), spawnBatchItemPartialToSpawnBatchItem(spawnBatch1Item1, inMemSpawnBatchOne), [
    blankBatchItemStore.getByIndexUnsafe(0),
    blankBatchItemStore.getByIndexUnsafe(2),
]))
substrateBatchesV2Store.add(newSubstrateBatchV2("sb-002", "ba-002", new Date('2023-12-05T00:00:00'), spawnBatchItemPartialToSpawnBatchItem(spawnBatch1Item2, inMemSpawnBatchOne), [
    blankBatchItemStore.getByIndexUnsafe(5),
]))

// Update blank batches with incoulated
const updateBlankBatchItemAsInoculated = (i: BlankBatchItem): BlankBatchItem => {
    i.inoculated = true
    return i
}
blankBatchItemStore.updateAtIndexWith(0, updateBlankBatchItemAsInoculated)
blankBatchItemStore.updateAtIndexWith(2, updateBlankBatchItemAsInoculated)
blankBatchItemStore.updateAtIndexWith(5, updateBlankBatchItemAsInoculated)



export async function inMemGetSubstrateBatchesWithItemsV2(): Promise<SubstrateBatchV2[]> {
    return substrateBatchesV2Store.toArray()
}

export function inMemCreateSubstrateBatchV2({spawnBatchItemId, blankBatchItemIds, createdDate, batchCode}: CreateSubstrateBatchFormValuesV2) {
    const blankBatchItems = blankBatchItemStore.find(item => blankBatchItemIds.indexOf(item.id) !== -1)
    if (blankBatchItems.length !== blankBatchItemIds.length) {
        throw new Error(`can not find all blank batch items found ${blankBatchItems.length} expected ${blankBatchItemIds.length}`)
    }




    const spawnBatchItemPartial = spawnBatchItemPartialStore.findFirst((item: SpawnBatchItemPartial) => item.id === spawnBatchItemId)
    if (spawnBatchItemPartial === undefined) {
        console.log("authedCreateSubstrateBatch error", spawnBatchItemPartialStore.toArray())
        throw new Error(`can not find spawnBatchItem ${spawnBatchItemId}`)
    }

    spawnBatchItemPartialStore.updateWith((item: SpawnBatchItemPartial) => item.id === spawnBatchItemId, (item: SpawnBatchItemPartial) => {
        if (item.id === spawnBatchItemId) {
            // this is not technically correct, a spawn batch is only spent when there is no more items left to get material from
            spawnBatchItemPartial.spent = true
            return spawnBatchItemPartial
        }

        return spawnBatchItemPartial
    })


    const spawnBatch = spawnBatchStore.findFirst(({id}: SpawnBatch) => id === spawnBatchItemPartial.spawnBatchID)
    if (spawnBatch === undefined) {
        throw new Error(`spawn batch ${spawnBatchItemPartial.spawnBatchID} not found`)
    }

    const spawnBatchItem = spawnBatchItemPartialToSpawnBatchItem(spawnBatchItemPartial, spawnBatch)
    substrateBatchesV2Store.add(newSubstrateBatchV2(`sub-batch-${substrateBatchesV2Store.nextIndex()}`, batchCode, createdDate, spawnBatchItem, blankBatchItems))


    console.log("authedCreateSubstrateBatch created", substrateBatchesV2Store.toArray())
}

export function inMemUpdateSubstrateBatchV2(values: CreateSubstrateBatchFormValuesV2) {
    // delete the old stuff
    // get the old date and unset the relationships
    const oldBatch = substrateBatchesV2Store.findFirst(item => item.id === values.id)
    if (oldBatch === undefined) {
        throw new Error(`can not find substrate batch ${values.id}`)
    }


    // set blank items inoculate to false
    oldBatch.blankBatchItems.forEach((item: BlankBatchItem) => {
        blankBatchItemStore.update(bb => bb.id === item.id, old => {
            old.inoculated = false

            return old
        })
    })

    spawnBatchItemPartialStore.updateWith((spawnBatch => spawnBatch.id === oldBatch.spawnBatchItem.id), (spawnBatchItemPartial) => {
        spawnBatchItemPartial.spent = false

        return spawnBatchItemPartial
    })

    // delete the substrate batch v2

    substrateBatchesV2Store.deleteAll(item => item.id === values.id)

    // run this request through create
    console.log("authedUpdateSubstrateBatch", values)

    // todo next prefrom an inmemory update

    inMemCreateSubstrateBatchV2(values)

    console.log("authedUpdateSubstrateBatch created", substrateBatchesV2Store.toArray())
}