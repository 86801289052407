import {
    emptyLiquidCultureBatchItem,
    LiquidCultureBatchItem
} from "../liquid-culture-batch/entities";
import {emptyStrain, Strain} from "../strain/query";
import {getStrainFromLiquidCultureBatchItemID} from "../liquid-culture-batch/inmemory";

export type SpawnBatch = {
    id:string
    batchCode: string
    liquidCultureBatchItemID: string
    numberContainers: number
    weightGrams: number,
    notes: string
    startDate: Date,
    // spent: boolean
    liquidCultureBatchItem: LiquidCultureBatchItem
    items: SpawnBatchItemPartial[]
}

export type SpawnBatchItemPartial = {
    id:string
    reference: string
    spawnBatchID: string
    spent: boolean
    contaminated: boolean
}

export function newSpawnBatchItemPartial(id:string, reference: string, spawnBatchID: string, spent: boolean, contaminated: boolean): SpawnBatchItemPartial {
    return {id, reference, spawnBatchID, spent, contaminated}
}

export type SpawnBatchDetails = {
    id:string
    batchCode: string
    strain: Strain
    startDate: Date
}

export const newSpawnBatchDetails = (id: string, batchCode: string, strain: Strain, startDate: Date):SpawnBatchDetails => ({id, batchCode, strain, startDate})

export const toDetails = ({id, batchCode, liquidCultureBatchItem, startDate}: SpawnBatch) => newSpawnBatchDetails(id, batchCode, liquidCultureBatchItem.batch.cultureStarter.strain, startDate)

export const newSpawnBatch = (
    id: string,
    batchCode: string,
    numberContainers: number,
    weightGrams: number,
    notes: string,
    startDate: Date,
    liquidCultureBatchItem: LiquidCultureBatchItem,
    items: SpawnBatchItemPartial[]
):SpawnBatch => ({id, batchCode, numberContainers, weightGrams, notes, startDate, liquidCultureBatchItemID: liquidCultureBatchItem.id, liquidCultureBatchItem, items})

export const spawnBatchStrainName = (batch: SpawnBatch) => {
    console.log("spawnBatchStrainName", batch)
    return batch.liquidCultureBatchItem.batch.cultureStarter.strain.name
}

export const newSpawnBatchItemFromIndex= (
    id: string,
    number: number,
    spawnBatch: SpawnBatch
):SpawnBatchItem => newSpawnBatchItem(id, `${spawnBatch.batchCode}-${number}`, newSpawnBatchDetails(spawnBatch.id, spawnBatch.batchCode, spawnBatch.liquidCultureBatchItem.batch.cultureStarter.strain, spawnBatch.startDate), false, false)

export const newSpawnBatchItem = (
    id: string,
    reference: string,
    spawnBatch: SpawnBatchDetails,
    spent: boolean,
    contaminated: boolean
):SpawnBatchItem => ({id, reference, spawnBatchID: spawnBatch.id, spawnBatch: newSpawnBatchDetails(spawnBatch.id, spawnBatch.batchCode, spawnBatch.strain, spawnBatch.startDate), spent, contaminated})



export type SpawnBatchItem = {
    id:string
    reference: string
    spawnBatchID: string
    // endDate: Date,
    spawnBatch: SpawnBatchDetails
    spent: boolean
    contaminated: boolean
}

export const emptySpawnBatch: SpawnBatch = {
    id:"",
    batchCode: "emptySpawnBatchItem",
    liquidCultureBatchItemID: "",
    numberContainers: 0,
    weightGrams: 0,
    notes: "",
    startDate: new Date(),
    liquidCultureBatchItem: emptyLiquidCultureBatchItem,
    items: []
}

export function emptySpawnBatchDetails(): SpawnBatchDetails {
    return newSpawnBatchDetails("", "", emptyStrain(), new Date())
}

export const emptySpawnBatchItem: SpawnBatchItem = {
    id:"",
    reference: "emptySpawnBatchItem",
    spawnBatchID: "",
    spawnBatch: emptySpawnBatchDetails(),
    spent: false,
    contaminated: false
}


export const noneSpent = (item: SpawnBatchItemPartial) => !item.spent
export const noneContaminatedSpawnItem = (item: SpawnBatchItemPartial) => !item.contaminated


export const spawnBatchItemToPartial = (item: SpawnBatchItem): SpawnBatchItemPartial =>
    newSpawnBatchItemPartial(item.id, item.reference, item.spawnBatchID, item.spent, item.contaminated)

export const spawnBatchItemPartialToSpawnBatchItem = (item: SpawnBatchItemPartial, spawnBatch: SpawnBatch): SpawnBatchItem =>
    newSpawnBatchItem(item.id, item.reference, spawnBatchToDetails(spawnBatch), item.spent, item.contaminated)


export const spawnBatchToDetails = ({id, batchCode, startDate, liquidCultureBatchItemID}: SpawnBatch): SpawnBatchDetails => {
    const strain = getStrainFromLiquidCultureBatchItemID(liquidCultureBatchItemID)
    if (strain === undefined) {
        throw new Error("getStrainFromliquidCultureBatchItemID: strain not found")
    }


    return newSpawnBatchDetails(id, batchCode, strain, startDate)
}
