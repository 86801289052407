import {isEnvIntegration, isEnvProduction} from "../env";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {GetTokenSilentlyOptions} from "@auth0/auth0-spa-js/src/global";

export  type JWTToken = string

export function useAuthToken(): (params?: GetTokenSilentlyOptions) => Promise<JWTToken> {
    const [token] = React.useState("local-dev-token")
    const {getAccessTokenSilently} = useAuth0()

    if (isEnvProduction()) {
        return (params?: GetTokenSilentlyOptions) => getAccessTokenSilently(params)
    }

    if (isEnvIntegration()) {
        return (params?: GetTokenSilentlyOptions) => getAccessTokenSilently(params)
    }

    return (params?: GetTokenSilentlyOptions) => Promise.resolve(token)
}