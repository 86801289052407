/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create spawnBatch request object
 * @export
 * @interface CreateSpawnBatchRequest
 */
export interface CreateSpawnBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSpawnBatchRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSpawnBatchRequest
     */
    batchCode: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSpawnBatchRequest
     */
    numberOfContainers: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSpawnBatchRequest
     */
    containerWeightGrams: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSpawnBatchRequest
     */
    notes: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSpawnBatchRequest
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateSpawnBatchRequest
     */
    liquidCultureBatchItemId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSpawnBatchRequest
     */
    markLiquidCultureBatchItemAsSpent: boolean;
}

/**
 * Check if a given object implements the CreateSpawnBatchRequest interface.
 */
export function instanceOfCreateSpawnBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "numberOfContainers" in value;
    isInstance = isInstance && "containerWeightGrams" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "liquidCultureBatchItemId" in value;
    isInstance = isInstance && "markLiquidCultureBatchItemAsSpent" in value;

    return isInstance;
}

export function CreateSpawnBatchRequestFromJSON(json: any): CreateSpawnBatchRequest {
    return CreateSpawnBatchRequestFromJSONTyped(json, false);
}

export function CreateSpawnBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSpawnBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchCode': json['batchCode'],
        'numberOfContainers': json['numberOfContainers'],
        'containerWeightGrams': json['containerWeightGrams'],
        'notes': json['notes'],
        'startDate': (new Date(json['startDate'])),
        'liquidCultureBatchItemId': json['liquidCultureBatchItemId'],
        'markLiquidCultureBatchItemAsSpent': json['markLiquidCultureBatchItemAsSpent'],
    };
}

export function CreateSpawnBatchRequestToJSON(value?: CreateSpawnBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchCode': value.batchCode,
        'numberOfContainers': value.numberOfContainers,
        'containerWeightGrams': value.containerWeightGrams,
        'notes': value.notes,
        'startDate': (value.startDate.toISOString()),
        'liquidCultureBatchItemId': value.liquidCultureBatchItemId,
        'markLiquidCultureBatchItemAsSpent': value.markLiquidCultureBatchItemAsSpent,
    };
}

