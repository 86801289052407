import {GridColumn, GridRow, SemanticWIDTHS, Table} from "semantic-ui-react";
import React from "react";
import {useQuery} from "react-query";

type TableProps<Entity> = {
    title: string
    header: JSX.Element
    createButtonRow: JSX.Element
    rowsMapper: (item: Entity) => JSX.Element
    queryKey: string
    queryFunction: () => Promise<Entity[]>
    numberCols: number
    rowSort?: (items: Entity[]) => Entity[]
}

export function CRUDQueryTablePage<Enity>(props: TableProps<Enity>) {
    const { header, queryKey, queryFunction, rowsMapper, numberCols, createButtonRow, rowSort} = props

    const sortRows = rowSort===undefined ? (items: Enity[]): Enity[] => items : rowSort

    const { isLoading, isError, data, error }  = useQuery([queryKey], queryFunction)

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>An error occured {JSON.stringify(error)}</div>
    }

    const rows = !!data
        ? sortRows(data).map(rowsMapper)
        : <Table.Row key="0"><Table.Cell width={numberCols as SemanticWIDTHS}>No Data Available</Table.Cell></Table.Row>

    return (
        <>
            {/*Title Row*/}
            <GridRow columns={1}>
                <GridColumn>
                    <h1>{props.title}</h1>
                </GridColumn>
            </GridRow>
            {/*Action Row Row*/}
            <GridRow columns={1}>
                <GridColumn>
                    <div style={{display:"flex", margin:"5px", background:"rgb(245,245,245)"}}>
                        {createButtonRow}
                    </div>
                </GridColumn>
            </GridRow>
            {/*Data Row*/}
            <GridRow columns={1}>
                <GridColumn>
                    <Table celled striped padded>
                        <Table.Header>
                            {header}
                        </Table.Header>
                        <Table.Body>
                            {rows}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={`${numberCols}`}></Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </GridColumn>
            </GridRow>
        </>
    )

}