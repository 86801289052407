const queryKeys = {
    blankBatchFormCache: "blankBatchFormCache",
    blankBatchesWithItems: "blankBatchesWithItems",

    cultureStarterGet: "cultureStarterGet",
    cultureStarterCreateFormCache:"cultureStarterCreateFormCache",

    harvestsGet:"harvestsGet",

    inventoryCreateFormCache: "inventoryCreateFormCache",

    liquidCultureBatchesGet: "liquidCultureBatchesGet",
    liquidCulturesBatchCreateFormCache: "liquidCulturesCreateFormCache",
    liquidCultureBatchItemsGet: "liquidCultureBatchItemsGet" ,

    recipesGet: "recipesGet",
    recipesCreateFormCache: "recipesCreateFormCache",


    spawnBatchesGet: "spawnBatchesGet",
    spawnBatchItemsGet: "spawnBatchItemsGet",
    spawnBatchCreateFormCache: "spawnBatchCreateFormCache",

    strainGet: "strainGet",
    strainCreateFormCache: "strainCreateFormCache",

    substrateBatchesGet: "substrateBatchesGet",

    suppliersGet: "suppliersGet",
    suppliersCreateFormCache: "suppliersCreateFormCache",

    unitTypeGet: "unitTypeGet",
    unityTypeCreateFormCache: "unityTypeCreateFormCache",
}

export default queryKeys