import {Table} from "semantic-ui-react";
import React from "react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import {useAuthToken} from "../../query/jwt";
import {BlankBatch, displayBlankState} from "../../query/blank-batch/entities";
import {getBlankBatchesWithItems} from "../../query/blank-batch/query";
import {TableHeader} from "../../components/table/header";
import {CreateUpdateBlankBatchModal} from "./batch-modals";
import {formatDateString} from "../../components/form/DateInput";
import {BlankBatchItemsModal} from "./items-modal";

export function BlankBatchPage(props: {}) {
    const getToken = useAuthToken()
    const headers = ["Batch Code", "State", "Create Date", "Pasteurized Date", "# Bags", "Actions"]
    const numberOfColumns= headers.length

    const header =  <TableHeader headers={headers}/>

    const rowsMapper = (batch: BlankBatch) => {
        return (
            <Table.Row key={batch.id}>
                <Table.Cell>{batch.batchCode}</Table.Cell>
                <Table.Cell>{displayBlankState(batch.state)}</Table.Cell>
                <Table.Cell>{formatDateString(batch.createdDate)}</Table.Cell>
                <Table.Cell>{formatDateString(batch.pasteurizedDate)}</Table.Cell>
                <Table.Cell><BlankBatchItemsModal items={batch.items}/></Table.Cell>
                {/*actions*/}
                <Table.Cell><CreateUpdateBlankBatchModal size={"small"} batch={batch}/></Table.Cell>
            </Table.Row>
        );
    }

    const createButton = <CreateUpdateBlankBatchModal size={"small"}/>

    const queryKey = queryKeys.blankBatchesWithItems

    return(
        <CRUDQueryTablePage<BlankBatch>
            title={"Basic Batches"}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getBlankBatchesWithItems(getToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberOfColumns}
        />
    )

}