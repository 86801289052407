import {CreateLiquidCultureBatchFormValues} from "../../pages/liquid-culture-batch/form";
import {
    LiquidCultureBatch,
    LiquidCultureBatchItem,
    newLiquidCultureBatch,
    newLiquidCultureBatchItemPartial,
    newLiquidCultureItemFromIndex
} from "./entities";
import {getCultureStarters} from "../culture-starter/query";
import {CultureStarterByID, cultureStarters} from "../culture-starter/inmemory";
import {autoIncrementID} from "../../lib/uuid/uuid";
import {arrayRange} from "../../lib/collections/collection";
import {newInmemoryStore} from "../inmemory";
import {Strain} from "../strain/query";



const liquidCultureBatchOne = newLiquidCultureBatch("liquidCulture-id-1", "oy-ba-1", cultureStarters[0],2, new Date(), null, 10,  [
    newLiquidCultureBatchItemPartial("liquidCulture-id-1-1","liquidCulture-id-1-1", false),
    newLiquidCultureBatchItemPartial("liquidCulture-id-1-2","liquidCulture-id-1-2", true),
])
export const liquidCultureBatchStore = newInmemoryStore<LiquidCultureBatch>()
liquidCultureBatchStore.add(liquidCultureBatchOne)

export const liquidCultureBatchItemStore = newInmemoryStore<LiquidCultureBatchItem>()
liquidCultureBatchItemStore.add(newLiquidCultureItemFromIndex("liquid-culture-batch-1",1, liquidCultureBatchOne))
liquidCultureBatchItemStore.add(newLiquidCultureItemFromIndex("liquid-culture-batch-2",2, liquidCultureBatchOne))

export async function inMemGetLiquidCultureBatches(): Promise<LiquidCultureBatch[]> {
    return liquidCultureBatchStore.toArray()
}

export async function inMemGetLiquidCultureBatchItems(): Promise<LiquidCultureBatchItem[]> {
    return liquidCultureBatchItemStore.toArray()
}

export async function inMemCreateLiquidCultureBatch(create: CreateLiquidCultureBatchFormValues): Promise<void> {
    const numberOfContainers = parseInt(create.numberOfContainers, 10)
    const volume = parseInt(create.volume, 10)

    const {batchCode, cultureStarterID, startDate, disposalDate} = create

    const starter = CultureStarterByID(cultureStarterID)
    if (starter===undefined) {
        console.log("error creating liquid culture cannot find culture starter", getCultureStarters(new Promise(res => res(""))), cultureStarterID)
        throw new Error("inventory create can not find unitType")
    }

    // create liquid culture batch
    const batch = newLiquidCultureBatch(`liquidCulture-id-${liquidCultureBatchStore.length()+1}`, batchCode, starter , numberOfContainers, startDate, disposalDate, volume)
    liquidCultureBatchStore.add(batch)

    // create liquid culture item
    const batchItems = arrayRange(numberOfContainers).map((_, i) => newLiquidCultureItemFromIndex(autoIncrementID(), i+1, batch))
    liquidCultureBatchItemStore.addMany(batchItems)


    console.log("inMemCreateLiquidCultureBatch", liquidCultureBatchItemStore.toArray())
}

export async function inMemUpdateLiquidCultureBatch(update: CreateLiquidCultureBatchFormValues): Promise<void> {
    const numberOfContainers = parseInt(update.numberOfContainers, 10)
    const volume = parseInt(update.volume, 10)

    const {batchCode, cultureStarterID, startDate, disposalDate} = update

    const starter = CultureStarterByID(cultureStarterID)
    if (starter===undefined) {
        console.log("error creating liquid culture cannot find culture starter", getCultureStarters(new Promise(res => res(""))), cultureStarterID)
        throw new Error("inventory create can not find unitType")
    }

    const batch = newLiquidCultureBatch(update.id, batchCode, starter , numberOfContainers, startDate, disposalDate, volume)

    liquidCultureBatchStore.updateWith(({id}) => id === update.id , () => batch)
}


export function getStrainFromLiquidCultureBatchItemID(liquidCultureBatchItemID: string): Strain | undefined {
    // create a liquid strain store
    const liquidCultureBatchItem = liquidCultureBatchItemStore.findFirst(({id}: LiquidCultureBatchItem) => id === liquidCultureBatchItemID)
    if (liquidCultureBatchItem === undefined) {
        throw new Error(`getStrainFromliquidCultureBatchItemID: can not find liquidCultureBatchItem ${liquidCultureBatchItemID}`)
    }

    // get liquid strain batch
    const liquidCultureBatch = liquidCultureBatchStore.findFirst(({id}: LiquidCultureBatch) => id === liquidCultureBatchItem.batchID)
    if (liquidCultureBatch === undefined) {
        throw new Error(`getStrainFromliquidCultureBatchItemID: can not find liquidCultureBatch ${liquidCultureBatchItem.batchID}`)
    }

    return liquidCultureBatch.cultureStarter.strain
}