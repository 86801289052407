



const ENV_INTEGRATION = "integration"
const ENV_PRODUCTION = "production"
const ENV_DEVELOPMENT = "development"

export const getEnv = () => process.env.REACT_APP_ENV || ENV_DEVELOPMENT
export const isEnvProduction = () => getEnv() === ENV_PRODUCTION
export const isEnvIntegration = () => getEnv() === ENV_INTEGRATION

export const isEnvDev = () => getEnv() === ENV_DEVELOPMENT