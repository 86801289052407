/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BlankBatchItemPartialEntity } from './BlankBatchItemPartialEntity';
import {
    BlankBatchItemPartialEntityFromJSON,
    BlankBatchItemPartialEntityFromJSONTyped,
    BlankBatchItemPartialEntityToJSON,
} from './BlankBatchItemPartialEntity';
import type { RecipeEntity } from './RecipeEntity';
import {
    RecipeEntityFromJSON,
    RecipeEntityFromJSONTyped,
    RecipeEntityToJSON,
} from './RecipeEntity';
import type { UnitTypeEntity } from './UnitTypeEntity';
import {
    UnitTypeEntityFromJSON,
    UnitTypeEntityFromJSONTyped,
    UnitTypeEntityToJSON,
} from './UnitTypeEntity';

/**
 * create blankBatch response object
 * @export
 * @interface BlankBatchEntity
 */
export interface BlankBatchEntity {
    /**
     * 
     * @type {string}
     * @memberof BlankBatchEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BlankBatchEntity
     */
    batchCode: string;
    /**
     * calculated based the pasteurized date being set
     * @type {string}
     * @memberof BlankBatchEntity
     */
    state: string;
    /**
     * 
     * @type {Date}
     * @memberof BlankBatchEntity
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BlankBatchEntity
     */
    pasteurizedDate?: Date;
    /**
     * 
     * @type {RecipeEntity}
     * @memberof BlankBatchEntity
     */
    recipe: RecipeEntity;
    /**
     * 
     * @type {UnitTypeEntity}
     * @memberof BlankBatchEntity
     */
    unitType: UnitTypeEntity;
    /**
     * 
     * @type {Array<BlankBatchItemPartialEntity>}
     * @memberof BlankBatchEntity
     */
    items: Array<BlankBatchItemPartialEntity>;
    /**
     * 
     * @type {string}
     * @memberof BlankBatchEntity
     */
    notes: string;
}

/**
 * Check if a given object implements the BlankBatchEntity interface.
 */
export function instanceOfBlankBatchEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "recipe" in value;
    isInstance = isInstance && "unitType" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "notes" in value;

    return isInstance;
}

export function BlankBatchEntityFromJSON(json: any): BlankBatchEntity {
    return BlankBatchEntityFromJSONTyped(json, false);
}

export function BlankBatchEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlankBatchEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchCode': json['batchCode'],
        'state': json['state'],
        'createdDate': (new Date(json['createdDate'])),
        'pasteurizedDate': !exists(json, 'pasteurizedDate') ? undefined : (new Date(json['pasteurizedDate'])),
        'recipe': RecipeEntityFromJSON(json['recipe']),
        'unitType': UnitTypeEntityFromJSON(json['unitType']),
        'items': ((json['items'] as Array<any>).map(BlankBatchItemPartialEntityFromJSON)),
        'notes': json['notes'],
    };
}

export function BlankBatchEntityToJSON(value?: BlankBatchEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchCode': value.batchCode,
        'state': value.state,
        'createdDate': (value.createdDate.toISOString()),
        'pasteurizedDate': value.pasteurizedDate === undefined ? undefined : (value.pasteurizedDate.toISOString()),
        'recipe': RecipeEntityToJSON(value.recipe),
        'unitType': UnitTypeEntityToJSON(value.unitType),
        'items': ((value.items as Array<any>).map(BlankBatchItemPartialEntityToJSON)),
        'notes': value.notes,
    };
}

