import {CreateFlushRequest, Harvest} from "./entities";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {inMemCreateHarvest, inMemGetHarvests} from "./inmemory";
import {JWTToken} from "../jwt";
import {sdCreateHarvest, sdGetHarvests} from "./sdapi";

export async function getHarvests(token: Promise<JWTToken>): Promise<Harvest[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetHarvests(token)
    }

    return inMemGetHarvests()
}


export function createHarvestFN(token: Promise<JWTToken>) {
    return async function createHarvest(create: CreateFlushRequest): Promise<Harvest> {
        if (isEnvProduction() || isEnvIntegration()) {
            return await sdCreateHarvest(token,create)
        }

        return inMemCreateHarvest(create)
    }
}
