/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create cultureStarter request object
 * @export
 * @interface UpdateCultureStarterRequest
 */
export interface UpdateCultureStarterRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCultureStarterRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCultureStarterRequest
     */
    batchCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCultureStarterRequest
     */
    source: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateCultureStarterRequest
     */
    incubationStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateCultureStarterRequest
     */
    disposalDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateCultureStarterRequest
     */
    strainId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCultureStarterRequest
     */
    supplierId: string;
}

/**
 * Check if a given object implements the UpdateCultureStarterRequest interface.
 */
export function instanceOfUpdateCultureStarterRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "incubationStart" in value;
    isInstance = isInstance && "strainId" in value;
    isInstance = isInstance && "supplierId" in value;

    return isInstance;
}

export function UpdateCultureStarterRequestFromJSON(json: any): UpdateCultureStarterRequest {
    return UpdateCultureStarterRequestFromJSONTyped(json, false);
}

export function UpdateCultureStarterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCultureStarterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'batchCode': json['batchCode'],
        'source': json['source'],
        'incubationStart': (new Date(json['incubationStart'])),
        'disposalDate': !exists(json, 'disposalDate') ? undefined : (new Date(json['disposalDate'])),
        'strainId': json['strainId'],
        'supplierId': json['supplierId'],
    };
}

export function UpdateCultureStarterRequestToJSON(value?: UpdateCultureStarterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'batchCode': value.batchCode,
        'source': value.source,
        'incubationStart': (value.incubationStart.toISOString()),
        'disposalDate': value.disposalDate === undefined ? undefined : (value.disposalDate.toISOString()),
        'strainId': value.strainId,
        'supplierId': value.supplierId,
    };
}

