import React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {HarvestBatchCreateForm} from "./batch-form";


export function HarvestModel(props: {}) {
    const [open, setOpen] = React.useState(false)
    const close = () => setOpen(false)

    return (
        <Modal
            closeIcon
            open={open}
            trigger={<Button style={{marginBottom:"1%"}} color={"blue"}>Create Harvest</Button>}
            onClose={close}
            onOpen={() => {
                setOpen(true)
            }}
        >
            <Header icon='table' content={`Create Harvest`} />
            <Modal.Content>
                <HarvestBatchCreateForm onSuccess={close}/>
            </Modal.Content>
        </Modal>
    )
}
