import React from "react";
import {
    createRecipeFN,
    updateRecipeFN,
    Recipe,
} from "../../query/recipe/query";
import {Button, Header, Modal} from "semantic-ui-react";
// @ts-ignore
import {IconEdit} from "../../components/icon/common";
import {CreateUpdateForm} from "./form";

type CreateModalProps = {
    size: "sml" | "mini"
}

export function CreateRecipeModal(props: CreateModalProps) {
    const [open, setOpen] = React.useState(false)

    const close = () => setOpen(false)

    return (
        <Modal
            className={"pad-left-10"}
            closeIcon
            open={open}
            trigger={<Button className={props.size} color='blue' type={"button"}>Create Recipe</Button>}
            onClose={close}
            onOpen={() => setOpen(true)}
        >
            <Header icon='address card' content='Create Recipe' />
            <Modal.Content>
                <CreateUpdateForm formAction={createRecipeFN} onSuccess={close}/>
            </Modal.Content>
        </Modal>
    )
}


type UpdateModalProps = {
    size: "sml" | "mini"
    recipe: Recipe
}

export function UpdateModal(props: UpdateModalProps) {
    const [open, setOpen] = React.useState(false)

    const close = () => setOpen(false)

    return (
        <Modal
            className={"pad-left-10"}
            closeIcon
            open={open}
            trigger={<Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>}
            onClose={close}
            onOpen={() => {
                console.log("open")
                setOpen(true)
            }}
        >
            <Header icon='address card' content='Update Recipe' />
            <Modal.Content>
                <CreateUpdateForm recipe={props.recipe}  formAction={updateRecipeFN} onSuccess={close}/>
            </Modal.Content>
        </Modal>
    )
}