import React from "react";
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import {BlankBatchForm, BlankBatchFormValues} from "./batch-form";
import {BlankBatch} from "../../query/blank-batch/entities";
import {BasicModal} from "../../components/modal/basic";
import {useModal} from "../../hooks/useModal";
import {IconEdit} from "../../components/icon/common";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";
import {authCreateBlankBatch, authUpdateBlankBatch} from "../../query/blank-batch/query";
import {AuthedFormAction} from "../../components/form/types";
type CreateModalProps =  {
    size: SemanticSIZES
    batch?: BlankBatch | undefined
}

export function CreateUpdateBlankBatchModal({batch, size}: CreateModalProps) {
    const {isOpen, onOpen, onClose} = useModal()

    const isCreate = batch === undefined

    const header = isCreate ? "Create Basic Batch" : "Update Basic Batch"

    const trigger = isCreate
        ? <Button size={size} color={"blue"} >{"Create Basic Batch"}</Button>
        : <Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>

    const formAction: AuthedFormAction<BlankBatchFormValues> = isCreate ? authCreateBlankBatch : authUpdateBlankBatch

    return (
        <BasicModal header={header} isOpen={isOpen} open={onOpen} close={onClose} trigger={trigger}>
                <BlankBatchForm batch={batch} onSuccess={onClose} formAction={formAction}/>
        </BasicModal>
    )
}
