/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StrainEntity } from './StrainEntity';
import {
    StrainEntityFromJSON,
    StrainEntityFromJSONTyped,
    StrainEntityToJSON,
} from './StrainEntity';
import type { SupplierEntity } from './SupplierEntity';
import {
    SupplierEntityFromJSON,
    SupplierEntityFromJSONTyped,
    SupplierEntityToJSON,
} from './SupplierEntity';

/**
 * create cultureStarter response object
 * @export
 * @interface CultureStarterEntity
 */
export interface CultureStarterEntity {
    /**
     * 
     * @type {string}
     * @memberof CultureStarterEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CultureStarterEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CultureStarterEntity
     */
    batchCode: string;
    /**
     * 
     * @type {string}
     * @memberof CultureStarterEntity
     */
    source: string;
    /**
     * 
     * @type {Date}
     * @memberof CultureStarterEntity
     */
    incubationStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof CultureStarterEntity
     */
    disposalDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CultureStarterEntity
     */
    strainId: string;
    /**
     * 
     * @type {StrainEntity}
     * @memberof CultureStarterEntity
     */
    strain: StrainEntity;
    /**
     * 
     * @type {string}
     * @memberof CultureStarterEntity
     */
    supplierId: string;
    /**
     * 
     * @type {SupplierEntity}
     * @memberof CultureStarterEntity
     */
    supplier: SupplierEntity;
    /**
     * 
     * @type {boolean}
     * @memberof CultureStarterEntity
     */
    spent: boolean;
}

/**
 * Check if a given object implements the CultureStarterEntity interface.
 */
export function instanceOfCultureStarterEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "incubationStart" in value;
    isInstance = isInstance && "strainId" in value;
    isInstance = isInstance && "strain" in value;
    isInstance = isInstance && "supplierId" in value;
    isInstance = isInstance && "supplier" in value;
    isInstance = isInstance && "spent" in value;

    return isInstance;
}

export function CultureStarterEntityFromJSON(json: any): CultureStarterEntity {
    return CultureStarterEntityFromJSONTyped(json, false);
}

export function CultureStarterEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): CultureStarterEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'batchCode': json['batchCode'],
        'source': json['source'],
        'incubationStart': (new Date(json['incubationStart'])),
        'disposalDate': !exists(json, 'disposalDate') ? undefined : (new Date(json['disposalDate'])),
        'strainId': json['strainId'],
        'strain': StrainEntityFromJSON(json['strain']),
        'supplierId': json['supplierId'],
        'supplier': SupplierEntityFromJSON(json['supplier']),
        'spent': json['spent'],
    };
}

export function CultureStarterEntityToJSON(value?: CultureStarterEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'batchCode': value.batchCode,
        'source': value.source,
        'incubationStart': (value.incubationStart.toISOString()),
        'disposalDate': value.disposalDate === undefined ? undefined : (value.disposalDate.toISOString()),
        'strainId': value.strainId,
        'strain': StrainEntityToJSON(value.strain),
        'supplierId': value.supplierId,
        'supplier': SupplierEntityToJSON(value.supplier),
        'spent': value.spent,
    };
}

