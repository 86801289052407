/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LiquidCultureBatchEntity } from './LiquidCultureBatchEntity';
import {
    LiquidCultureBatchEntityFromJSON,
    LiquidCultureBatchEntityFromJSONTyped,
    LiquidCultureBatchEntityToJSON,
} from './LiquidCultureBatchEntity';

/**
 * create liquidCultureBatchItem response object
 * @export
 * @interface LiquidCultureBatchItemEntity
 */
export interface LiquidCultureBatchItemEntity {
    /**
     * 
     * @type {string}
     * @memberof LiquidCultureBatchItemEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LiquidCultureBatchItemEntity
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof LiquidCultureBatchItemEntity
     */
    batchId: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiquidCultureBatchItemEntity
     */
    spent: boolean;
    /**
     * 
     * @type {LiquidCultureBatchEntity}
     * @memberof LiquidCultureBatchItemEntity
     */
    batch: LiquidCultureBatchEntity;
}

/**
 * Check if a given object implements the LiquidCultureBatchItemEntity interface.
 */
export function instanceOfLiquidCultureBatchItemEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "batchId" in value;
    isInstance = isInstance && "spent" in value;
    isInstance = isInstance && "batch" in value;

    return isInstance;
}

export function LiquidCultureBatchItemEntityFromJSON(json: any): LiquidCultureBatchItemEntity {
    return LiquidCultureBatchItemEntityFromJSONTyped(json, false);
}

export function LiquidCultureBatchItemEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiquidCultureBatchItemEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': json['reference'],
        'batchId': json['batchId'],
        'spent': json['spent'],
        'batch': LiquidCultureBatchEntityFromJSON(json['batch']),
    };
}

export function LiquidCultureBatchItemEntityToJSON(value?: LiquidCultureBatchItemEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'batchId': value.batchId,
        'spent': value.spent,
        'batch': LiquidCultureBatchEntityToJSON(value.batch),
    };
}

