import {JWTToken} from "../jwt";
import {
    LiquidCultureBatch,
    LiquidCultureBatchItem, LiquidCultureBatchItemPartial,
    newLiquidCultureBatch, newLiquidCultureBatchItemPartial,
    newLiquidCultureItem,
} from "./entities";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {CreateLiquidCultureBatchFormValues} from "../../pages/liquid-culture-batch/form";
import {
    CreateLiquidCultureBatchRequest,
    LiquidCultureBatchEntity,
    LiquidCultureBatchItemEntity, LiquidCultureBatchItemPartialEntity
} from "../../generated-sources/openapi";
import {actualOrNull, actualOrUndefined} from "../../lib/null/null";
import {mapCultureStarterEntityToCultureStarter} from "../culture-starter/sdapi";


export async function sdGetLiquidCultureBatches(token: Promise<JWTToken>): Promise<LiquidCultureBatch[]> {
    const {coreClient} = getAPIClient()
    const jwt = await token

    const liquidCultureBatch = await coreClient.getAllLiquidCultureBatches(undefined, authHeaderBearer(jwt))

    return liquidCultureBatch.map(mapLiquidCultureBatchEntityToLiquidCultureBatch)
}

export async function sdCreateLiquidCulture(token: Promise<JWTToken>, values: CreateLiquidCultureBatchFormValues): Promise<LiquidCultureBatch> {
    const {coreClient} = getAPIClient()
    const jwt = await token

    const volumeInt = parseInt(values.volume, 10)
    const numberOfContainersInt = parseInt(values.numberOfContainers, 10)

    const createLiquidCultureBatchRequest: CreateLiquidCultureBatchRequest = {
        batchCode: values.batchCode,
        volumePerContainer: volumeInt,
        numberOfContainers: numberOfContainersInt,
        disposalDate: actualOrUndefined(values.disposalDate),
        startDate: values.startDate,
        cultureStarterId: values.cultureStarterID,

    }

    const cultureStarterEntity = await coreClient.createLiquidCultureBatch({createLiquidCultureBatchRequest}, authHeaderBearer(jwt))

    return mapLiquidCultureBatchEntityToLiquidCultureBatch(cultureStarterEntity)
}

export async function sdUpdateLiquidCulture(token: Promise<JWTToken>, values: CreateLiquidCultureBatchFormValues): Promise<LiquidCultureBatch> {
    const {defaultClient} = getAPIClient()
    const jwt = await token

    const { id, batchCode, disposalDate, startDate, cultureStarterID} = values
    const volumeInt = parseInt(values.volume, 10)
    const numberOfContainersInt = parseInt(values.numberOfContainers, 10)

    const updateLiquidCultureBatchRequest: CreateLiquidCultureBatchRequest = {
        batchCode: batchCode,
        volumePerContainer: volumeInt,
        numberOfContainers: numberOfContainersInt,
        disposalDate: actualOrUndefined(disposalDate),
        startDate: startDate,
        cultureStarterId: cultureStarterID,

    }

    const cultureStarterEntity = await defaultClient.updateLiquidCultureBatchByID({updateLiquidCultureBatchRequest, id}, authHeaderBearer(jwt))

    return mapLiquidCultureBatchEntityToLiquidCultureBatch(cultureStarterEntity)
}


export async function sdGetAllLiquidCultureBatchItems(token: Promise<JWTToken>): Promise<LiquidCultureBatchItem[]> {
    const {coreClient} = getAPIClient()
    const jwt = await token

    const liquidCultureBatchItems = await coreClient.getAllLiquidCultureBatchItems({}, authHeaderBearer(jwt))

    return liquidCultureBatchItems.map(mapLiquidCultureBatchEntityItemToLiquidCultureBatchItem)
}

export function mapLiquidCultureBatchEntityToLiquidCultureBatch(batch: LiquidCultureBatchEntity): LiquidCultureBatch {
    const cultureStarter = mapCultureStarterEntityToCultureStarter(batch.cultureStarter)

    return newLiquidCultureBatch(
        batch.id,
        batch.batchCode,
        cultureStarter,
        batch.numberOfContainers,
        batch.startDate,
        actualOrNull(batch.disposalDate),
        batch.volumePerContainer,
        mapLiquidCultureBatchItemPartials(batch.items),
    )
}



export function mapLiquidCultureBatchEntityItemToLiquidCultureBatchItem(item: LiquidCultureBatchItemEntity): LiquidCultureBatchItem {
    const batch = mapLiquidCultureBatchEntityToLiquidCultureBatch(item.batch)

    return newLiquidCultureItem(
        item.id,
        item.reference,
        batch,
        item.spent
    )
}

function mapLiquidCultureBatchItemPartials(items: LiquidCultureBatchItemPartialEntity[]): LiquidCultureBatchItemPartial[] {
    return items.map( ({id, spent, reference}) => newLiquidCultureBatchItemPartial(id, reference, spent))
}
