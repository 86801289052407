
export function findAndReplace<T >(items: T[], find: (searchElement: T) => boolean, replaceWith: (old: T) => T ): T[] {
    return items.map((item) => find(item) ?  replaceWith(item) : item)
}

export function newInmemoryStore<T>() {
    return new Store<T>()
}

export function inMemoryStoreFromArray<T>(...items: T[]): Store<T> {
    const store = newInmemoryStore<T>()

    return items.reduce((acc, item) => {
        acc.add(item)
        return acc
    }, store)
}


export class Store<T> {
    private data: T[]
    constructor() {
        this.data = []
    }

    update(find: (searchElement: T) => boolean, replaceWith: (old: T) => T) {
        this.data = findAndReplace(this.data, find, replaceWith)
    }

    updateAtIndexWith(index: number, replaceWith: (i: T) => T): boolean {
        if (this.data.length <= index) {
            return false
        }

        const item = this.data[index]
        this.data[index] = replaceWith(item)

        return true
    }


    // updates many
    updateWith(find: (searchElement: T) => boolean, replaceWith: (i: T) => T): boolean {
        let updated = false

        this.data = this.data.map(item => {
            if (find(item)) {
                console.log("authedUpdateSubstrateBatch found")
                updated = true
                return replaceWith(item)
            }

            return item
        })


        return updated
    }

    add(item: T) {
        this.data = [...this.data, item]
    }

    addMany(item: T[]) {
        this.data = [...this.data, ...item]
    }


    length(): number {
        return this.data.length
    }

    toArray(): T[] {
        return this.data
    }

    find(find: (searchElement: T) => boolean): T[] {
        return this.data.filter(find)
    }

    findFirst(find: (searchElement: T) => boolean): T | undefined {
        const found = this.data.filter(find)

        return found.length > 0 ? found[0] : undefined
    }

    deleteAll(find: (searchElement: T) => boolean) {
        this.data = this.data.filter((i) => !find(i))
    }


    getByIndex(index: number): T | undefined {
        if (this.data.length <= index) {
            return undefined
        }

        return this.data[index]
    }

    getByIndexUnsafe(index: number): T {
        if (this.data.length <= index) {
            throw new Error(`index out of bounds len:${this.data.length} i:${index}`)
        }

        return this.data[index]
    }

    nextIndex(): number {
        return this.data.length
    }
}




