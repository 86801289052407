import {Table} from "semantic-ui-react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import React from "react";
import {getSuppliers} from "../../query/supplier/query";
import {CreateSupplerModal, UpdateSupplerModal} from "./modal";
import {Supplier} from "../../query/supplier/query";
import {useAuthToken} from "../../query/jwt";
import {TableHeader} from "../../components/table/header";


export function SupplierTablePage() {
    const getAuthToken = useAuthToken()
    // header: JSX.Element
    const queryKey = queryKeys.suppliersGet
    const title = "Suppliers"
    const headers = ["Name", "Actions"]
    const numberCols = headers.length
    const header = <TableHeader headers={headers}/>


    const rowsMapper = (supplier: Supplier) => {
        return (
            <Table.Row key={supplier.id}>
                <Table.Cell>{supplier.name}</Table.Cell>
                <Table.Cell><UpdateSupplerModal supplier={supplier}/></Table.Cell>
            </Table.Row>
        )
    }

    const createButton = <CreateSupplerModal/>

    return(
        <CRUDQueryTablePage<Supplier>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getSuppliers(getAuthToken({authorizationParams: {audience: "https://sporedrive.io"}}))}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberCols}
        />
    )
}