import {inMemCreateCultureStarter, inMemGetCultureStarters, inMemUpdateCultureStarter} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {CreateUpdateCultureStarterFormValues} from "../../pages/culture-starter/form";
import {emptyStrain, Strain} from "../strain/query";
import {emptySupplier, Supplier} from "../supplier/query";
import {sdCreateCultureStarter, sdGetCultureStarters, sdUpdateCultureStarter} from "./sdapi";
import {JWTToken} from "../jwt";

export type Source = "insourced" | "outsourced"

export type ContainerType = "syringe"

export type CultureStarter = {
    id: string
    batchCode: string
    containerType: ContainerType
    source: Source
    incubationStart: Date
    spent: boolean
    disposalDate: Date | null | undefined
    strain: Strain
    supplier: Supplier
}


export const emptyCultureStarter: CultureStarter = {
    id: "emptyCultureStarter",
    batchCode: "emptyCultureStarter",
    containerType: "syringe",
    source: "outsourced",
    incubationStart: new Date(),
    disposalDate: new Date(),
    spent: false,
    strain: emptyStrain(),
    supplier: emptySupplier(),
}


export async function getCultureStarters(token: Promise<JWTToken>): Promise<CultureStarter[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetCultureStarters(token)
    }

    return inMemGetCultureStarters()
}

export function authCreateCultureStarter(token: Promise<JWTToken>) {
    return async function (values: CreateUpdateCultureStarterFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdCreateCultureStarter(token, values)
            return
        }

        return inMemCreateCultureStarter(values)
    }
}

export function authUpdateCultureStarter(token: Promise<JWTToken>) {
    return async function (values: CreateUpdateCultureStarterFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateCultureStarter(token, values)
            return
        }

        return inMemUpdateCultureStarter(values)
    }
}


let formCache: CreateUpdateCultureStarterFormValues = {
    id: "",
    batchCode: "",
    containerType: "syringe",
    source: "outsourced",
    incubationStart: new Date(),
    disposalDate: null,
    strainID: "",
    supplierID: "",
}

export async function getCultureStarterCreateFormCache(): Promise<CreateUpdateCultureStarterFormValues> {
    return formCache
}
export async function updateCultureStarterCreateFormCache(values: CreateUpdateCultureStarterFormValues) {
    formCache = values
}
