import {Strain} from "../strain/query";
import {SpawnBatchItem} from "../spawn-batch/entities";
import {BlankBatchItem} from "../blank-batch/entities";

export type SubstrateBatchV2 = {
    id: string
    batchCode: string
    createdDate: Date
    spawnBatchItem: SpawnBatchItem
    blankBatchItems: BlankBatchItem[]
    notes: string
}

export const newSubstrateBatchV2 = (id: string, batchCode: string, createdDate: Date, spawnBatchItem: SpawnBatchItem, blankBatchItems: BlankBatchItem[], notes: string = "") => ({
    id,
    batchCode,
    createdDate,
    spawnBatchItem,
    blankBatchItems,
    notes: notes,
})


export const getSubstrateBatchStrain = (batch: SubstrateBatchV2): Strain => {
    return batch.spawnBatchItem.spawnBatch.strain
}

export type SubstrateBatchStateItemEvent = {
    state: SubstrateBatchItemState
    date: Date | null
}

export type SubstrateBatchItemState =
    | "inoculation"
    | "incubation"
    | "storage"
    | "pinning"
    | "fruitingFirstFlush"
    | "fruitingSecondFlush"


export function substrateBatchItemStateFrom(state: string): SubstrateBatchItemState {
    switch (state) {
        case "inoculation":
            return "inoculation"
        case "incubation":
            return "incubation"
        case "storage":
            return "storage"
        case "pinning":
            return "pinning"
        case "fruitingFirstFlush":
            return "fruitingFirstFlush"
        case "fruitingSecondFlush":
            return "fruitingSecondFlush"
        default:
            console.error("unknown substrate batch item state", state)
            throw new Error(`unknown substrate batch item state ${state}`)
    }
}
