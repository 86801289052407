/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StrainEntity } from './StrainEntity';
import {
    StrainEntityFromJSON,
    StrainEntityFromJSONTyped,
    StrainEntityToJSON,
} from './StrainEntity';

/**
 * 
 * @export
 * @interface SpawnBatchDetails
 */
export interface SpawnBatchDetails {
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchDetails
     */
    batchCode: string;
    /**
     * 
     * @type {StrainEntity}
     * @memberof SpawnBatchDetails
     */
    strain: StrainEntity;
    /**
     * 
     * @type {Date}
     * @memberof SpawnBatchDetails
     */
    startDate: Date;
}

/**
 * Check if a given object implements the SpawnBatchDetails interface.
 */
export function instanceOfSpawnBatchDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "strain" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function SpawnBatchDetailsFromJSON(json: any): SpawnBatchDetails {
    return SpawnBatchDetailsFromJSONTyped(json, false);
}

export function SpawnBatchDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpawnBatchDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchCode': json['batchCode'],
        'strain': StrainEntityFromJSON(json['strain']),
        'startDate': (new Date(json['startDate'])),
    };
}

export function SpawnBatchDetailsToJSON(value?: SpawnBatchDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchCode': value.batchCode,
        'strain': StrainEntityToJSON(value.strain),
        'startDate': (value.startDate.toISOString()),
    };
}

