/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubstrateBatchDetails } from './SubstrateBatchDetails';
import {
    SubstrateBatchDetailsFromJSON,
    SubstrateBatchDetailsFromJSONTyped,
    SubstrateBatchDetailsToJSON,
} from './SubstrateBatchDetails';
import type { SubstrateBatchItemEvent } from './SubstrateBatchItemEvent';
import {
    SubstrateBatchItemEventFromJSON,
    SubstrateBatchItemEventFromJSONTyped,
    SubstrateBatchItemEventToJSON,
} from './SubstrateBatchItemEvent';

/**
 * 
 * @export
 * @interface SubstrateBatchItemEntity
 */
export interface SubstrateBatchItemEntity {
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchItemEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchItemEntity
     */
    reference: string;
    /**
     * 
     * @type {SubstrateBatchDetails}
     * @memberof SubstrateBatchItemEntity
     */
    substrateBatchDetails: SubstrateBatchDetails;
    /**
     * 
     * @type {Date}
     * @memberof SubstrateBatchItemEntity
     */
    incubationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchItemEntity
     */
    currentState: string;
    /**
     * 
     * @type {Array<SubstrateBatchItemEvent>}
     * @memberof SubstrateBatchItemEntity
     */
    states: Array<SubstrateBatchItemEvent>;
    /**
     * 
     * @type {Date}
     * @memberof SubstrateBatchItemEntity
     */
    contaminated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SubstrateBatchItemEntity
     */
    spent: boolean;
}

/**
 * Check if a given object implements the SubstrateBatchItemEntity interface.
 */
export function instanceOfSubstrateBatchItemEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "substrateBatchDetails" in value;
    isInstance = isInstance && "currentState" in value;
    isInstance = isInstance && "states" in value;
    isInstance = isInstance && "spent" in value;

    return isInstance;
}

export function SubstrateBatchItemEntityFromJSON(json: any): SubstrateBatchItemEntity {
    return SubstrateBatchItemEntityFromJSONTyped(json, false);
}

export function SubstrateBatchItemEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubstrateBatchItemEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': json['reference'],
        'substrateBatchDetails': SubstrateBatchDetailsFromJSON(json['substrateBatchDetails']),
        'incubationDate': !exists(json, 'incubationDate') ? undefined : (new Date(json['incubationDate'])),
        'currentState': json['currentState'],
        'states': ((json['states'] as Array<any>).map(SubstrateBatchItemEventFromJSON)),
        'contaminated': !exists(json, 'contaminated') ? undefined : (new Date(json['contaminated'])),
        'spent': json['spent'],
    };
}

export function SubstrateBatchItemEntityToJSON(value?: SubstrateBatchItemEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'substrateBatchDetails': SubstrateBatchDetailsToJSON(value.substrateBatchDetails),
        'incubationDate': value.incubationDate === undefined ? undefined : (value.incubationDate.toISOString()),
        'currentState': value.currentState,
        'states': ((value.states as Array<any>).map(SubstrateBatchItemEventToJSON)),
        'contaminated': value.contaminated === undefined ? undefined : (value.contaminated.toISOString()),
        'spent': value.spent,
    };
}

