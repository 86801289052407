import React from "react";
import {Button} from "semantic-ui-react";
import {CreateUpdateForm, CreateLiquidCultureBatchFormValues} from "./form";
import {LiquidCultureBatch} from "../../query/liquid-culture-batch/entities";
import {BasicModal} from "../../components/modal/basic";
import {useModal} from "../../hooks/useModal";
import {IconEdit} from "../../components/icon/common";
import {AuthedFormAction} from "../../components/form/types";
import {authedCreateLiquidCultureBatch, authedUpdateLiquidCultureBatch} from "../../query/liquid-culture-batch/query";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";


type CreateModalProps = {
    batch?: LiquidCultureBatch | undefined
    onSuccess?: () => void
    size: SemanticSIZES
}

export function CreateUpdateLiquidCultureModal({batch, size}: CreateModalProps) {
    const title = "Create Liquid Culture Batch"
    const {isOpen, onOpen, onClose} = useModal()

    const isCreate = batch === undefined

    const trigger = isCreate
        ? <Button type={"button"} className={size} color='blue'>{title}</Button>
        : <Button className={size} color={"blue"} icon={<IconEdit/>}/>

    const action: AuthedFormAction<CreateLiquidCultureBatchFormValues> = isCreate
        ? authedCreateLiquidCultureBatch
        : authedUpdateLiquidCultureBatch

    return (
        <BasicModal
            close={onClose}
            header={title}
            isOpen={isOpen}
            open={onOpen}
            trigger={trigger}
        >
            <CreateUpdateForm batch={batch} onSuccess={onClose} formAction={action}/>
        </BasicModal>
    )
};
