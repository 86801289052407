import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {loadCSS} from "./css";
import {Auth0Provider} from "@auth0/auth0-react";
import {getEnv} from "./env";
import {setAPIClient} from "./query/base-url";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || ""
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ""
const sporedriveAPIBaseUrl = process.env.REACT_APP_SD_API || ""
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "https://sporedrive.io"

console.log(`running in ${getEnv()} env`)
console.log(`auth0 domain: '${auth0Domain}'`)
console.log(`auth0 client: '${auth0ClientId}'`)
setAPIClient(sporedriveAPIBaseUrl)
console.log(`api base url: '${sporedriveAPIBaseUrl}'`)
console.log(`auth0 audience: '${audience}'`)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          authorizationParams={{
              audience: audience,
              redirect_uri: window.location.origin
          }}
      >
          <App />
      </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// because this import is broken: import 'semantic-ui-css/semantic.min.css'
loadCSS("https://cdn.jsdelivr.net/npm/semantic-ui@2/dist/semantic.min.css")
