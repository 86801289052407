import {CreateSupplier, newSupplier, Supplier, UpdateSupplier} from "./query";
import {findAndReplace} from "../inmemory";

export let suppliers = [
    newSupplier("s1", "jar supplier"),
    newSupplier("s2", "bag supplier"),
]

export const getSuppliersData = () => suppliers
export const getSupplierByID = (id: string | undefined): Supplier | undefined =>  getSuppliersData().find((supplierItem) => supplierItem.id === id)


export async function inMemGetSuppliers(): Promise<Supplier[]> {
    return suppliers
}

export async function inMemCreateSupplier(create: CreateSupplier): Promise<void> {
    suppliers = [...suppliers, newSupplier(`s${suppliers.length+1}`, create.name)]
}

export async function inMemUpdateSupplier(update: UpdateSupplier): Promise<void> {
    suppliers = findAndReplace(suppliers, ({id}) => id === update.id , () => newSupplier(update.id, update.name))
}
