import React from 'react';
import './App.css';
import {Menu} from "semantic-ui-react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import mainClassNames , {classes} from "./styling/css";
import InventoryPage from "./pages/inventory/inventory";
import {QueryClient, QueryClientProvider} from "react-query";
import {isEnvDev} from "./env";
import {DEBUG, setLogLevel} from "./log/logger";
import {ComponentLibrary} from "./pages/library/ComponentLibrary";
import {HarvestPage} from "./pages/harvest/page";
import LandingPage from "./pages/landing/login";
import {LogoutButton} from "./pages/landing/logout";
import {useUserAuthenticated} from "./hooks/userAuthenticated";

const css = mainClassNames

const queryClient = new QueryClient()

function App() {
    setLogLevel(DEBUG)

    console.log("app component running")

    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                <SideNavApp ></SideNavApp>
            </div>
        </QueryClientProvider>
    );
}

export default App;

function SideNavApp() {
    const { user, isAuthenticated, isLoading } = useUserAuthenticated()

    if (isLoading) {
        return <div>Dashboard Loading ...</div>
    }

    if (!isAuthenticated) {
        return <LandingPage/>
    }

    if (user=== undefined) {
        return <div>user undefined</div>
    }

    return (
        <div>
            <Router>
                {/*add in menu*/}
                <div id="side-nav" className="side-nav">
                    <Menu fluid inverted vertical >
                        <Menu.Item
                            name={`Signed in as: ${user.name}`}
                            active={false}
                        />
                        <Menu.Item
                            name={"log"}
                            active={false}
                            as={LogoutButton}
                        />
                        {/*<Link to="/">*/}
                        {/*    <Menu.Item*/}
                        {/*        name={"dashboard"}*/}
                        {/*        active={false}*/}
                        {/*    />*/}
                        {/*</Link>*/}
                        {/*<Link to="/about"><Menu.Item*/}
                        {/*    name={"about"}*/}
                        {/*    active={false}*/}
                        {/*/></Link>*/}

                        {/*farming production section*/}
                        <Link to="/inventory"><Menu.Item
                                name={"inventory"}
                                active={false}
                        /></Link>
                        <Link to="/harvest"><Menu.Item
                            name={"Harvest"}
                            active={false}
                        /></Link>

                        {isEnvDev() && (
                            <>
                                <Menu.Item
                                    name={"development tools"}
                                    active={false}
                                />
                                <Link to="/playground"><Menu.Item
                                    name={"playground"}
                                    active={false}
                                /></Link>
                                <Link to="/library"><Menu.Item
                                    name={"ComponentLibrary"}
                                    active={false}
                                /></Link>
                            </>
                        )}
                    </Menu>
                </div>
                <Routes>
                    {/*all rout children must have side-nav-content css class */}
                    <Route path="/" element={<InventoryPage />}/> {/*todo dashboard./st */}
                    <Route path="/about" element={<About/>}/>
                    <Route path="/inventory" element={<InventoryPage/>}/>
                    <Route path="/library" element={<ComponentLibrary/>}/>
                    <Route path="/harvest" element={<HarvestPage/>}></Route>
                </Routes>
            </Router>
        </div>

    )
}

function About() {
    return (
        <header className={classes(css.appHeader, css.mainContent)}>
            <p>
                About.
            </p>
        </header>
    )
}

