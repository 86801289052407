

const mainClassNames = {
    mainContent: "main-content",
    appHeader: "App-header",
    app: "App",
    appLink: "App-link",
    heavyFontFlex: "Heavy-font-flex",
    lightFontFlex: "Light-font-flex",
    tinyFontFlex: "Tiny-font-flex",
}

export default mainClassNames


export function classes(...classNames: string[]) {
    return classNames.join(" ")
}