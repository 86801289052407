import React, {ChangeEvent} from "react";
import {Field} from "formik";

export const NoOptionSelectedID = "0"

export type FormGroupProps = {
    children: JSX.Element | JSX.Element[]
}

export function FormGroup(props: FormGroupProps) {
    return (
        <div className="equal width fields">
            {props.children}
        </div>
    )
}

export type LabelInputStackedProps = {
    // children: JSX.Element | JSX.Element[]
    name: string
    label: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => any
    type?: string
    as?: string
    disabled?: boolean
    placeholder?: string
}

export function LabelInputStacked(props: LabelInputStackedProps) {
    const type = props.type || "text"
    const as = props.as || "input"
    const placeholder = props.placeholder || ""
    const disabled = props.disabled ? "disabled" : ""

    return (
        <div className="field">
            <label>{props.label}</label>
            <div className={`ui fluid input ${disabled}`}>
                <Field
                    type={type}
                    name={props.name}
                    as={as}
                    placeholder={placeholder}
                    onChange={props.onChange}
                />
            </div>
        </div>
    )
}



export type LabelInputWithValueStackedProps = {
    // children: JSX.Element | JSX.Element[]
    name: string
    label: string
    value: any
    onChange: (e: ChangeEvent<HTMLInputElement>) => any
    type?: string
    as?: string
    disabled?: boolean
    placeholder?: string
}

export function LabelInputWithValueStacked(props: LabelInputWithValueStackedProps) {
    const type = props.type || "text"
    const as = props.as || "input"
    const placeholder = props.placeholder || ""
    const disabled = props.disabled ? "disabled" : ""

    return (
        <div className="field">
            <label>{props.label}</label>
            <div className={`ui fluid input ${disabled}`}>
                <Field
                    type={type}
                    name={props.name}
                    as={as}
                    placeholder={placeholder}
                    onChange={props.onChange}
                    value={props.value}
                />
            </div>
        </div>
    )
}



export type LabelSelectionStackedProps = {
    name: string
    label: string
    onChange: (e: ChangeEvent<HTMLSelectElement>) => any
    options: SelectOption[]
    blankOption: boolean
    // id of a corresponding option key
    selected: string
    disabled?: boolean
}

export function LabelSelectionStacked(props: LabelSelectionStackedProps) {
    const {
        selected,
        disabled,
        label,
        name,
        options,
        blankOption,
        onChange} = props

    const optionsViews = options.map(o => selected===o.id? <LabelSelectionOption selected={true} key={o.id} id={o.id} value={o.value}/> : <LabelSelectionOption key={o.id} selected={false} id={o.id} value={o.value}/>)
    const firstOptionLabel = optionsViews.length===0?"no option please create" : "please select"
    const firstOption = blankOption ?[<LabelSelectionOption key="1" selected={false} id={""} value={firstOptionLabel}/> ]: []

    return(
        <div className="field">
            <label>{label}</label>
            <select disabled={disabled} name={name} onChange={onChange}>
        {[...firstOption, ...optionsViews]}
    </select>
    </div>
)
}

export type SelectOption = {
    id: string
    value: string
}

export function selectOption(id:string|number, value:string): SelectOption {
    return {id: `${id}`, value}
}

export type LabelSelectionOptionProps = {
    selected: boolean
    id: string
    value: string
}

export function LabelSelectionOption(props: LabelSelectionOptionProps) {
    return (
        <option selected={props.selected} value={props.id}>{props.value}</option>

    )
}