import {getRecipeCreateFormCache, Recipe, updateRecipeCreateFormCache} from "../../query/recipe/query";
import React, {ChangeEvent, useState} from "react";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {useMutation, useQuery, useQueryClient} from "react-query";
import queryKeys from "../../query/keys";
import * as yup from "yup";
import {Formik} from "formik";
import {disableSubmit} from "../../components/form/validation";
import {FormErrors} from "../../components/form/errors";
import {FormGroup, LabelInputStacked} from "../../components/form/field";
import {Form as UIForm} from "semantic-ui-react";
import {AuthedFormAction} from "../../components/form/types";
import {resolvePriorityData} from "../../lib/data/resolve";

export type CreateUpdateRecipeFormValues = {
    id: string
    name: string
}

const initialFormValues: CreateUpdateRecipeFormValues = {
    id: "", // never set by form
    name: "",
}

type CreateFormProps = {
    onSuccess: () => void
    recipe?: Recipe
    formAction: AuthedFormAction<CreateUpdateRecipeFormValues>
}

export function CreateUpdateForm({onSuccess, recipe, formAction}: CreateFormProps) {
    const id = recipe?.id || ""
    const [notifications, setNotifications] = useState(emptyNotifications);

    const authToken = useAuthToken()

    const queryClient = useQueryClient()
    const { mutate: mutateFormCache } = useMutation(updateRecipeCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.recipesCreateFormCache)
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)]  )
        }
    });

    const { mutate: mutateCreateRecipe } = useMutation(formAction(authToken()), {
        onSuccess: () => {
            // clear notifications
            queryClient.invalidateQueries(queryKeys.recipesGet);
            onSuccess()
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `submit error ${err?.message}`)]  )
        }
    });

    const formCacheQuery = useQuery([queryKeys.recipesCreateFormCache], getRecipeCreateFormCache)

    const [formValues, setFormValues] = useState(resolvePriorityData(recipeToUpdateFormMaybe(recipe), formCacheQuery.data, initialFormValues))

    const formSchema = yup.object().shape({
        name: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Name required'),
    });

    return (<div>
        <Formik
            initialValues={formValues}
            validationSchema={formSchema}
            onSubmit={(formValues, {setSubmitting}) => {
                // set the ID
                formValues.id = id

                setSubmitting(true)
                mutateFormCache(initialFormValues)
                setNotifications([])
                // allow form cache to clear
                setTimeout(() => {
                    setSubmitting(false)
                    mutateCreateRecipe(formValues)
                }, 1)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
              }) => {
                const setFormValuesWithChanges = (setFN: (formValues: CreateUpdateRecipeFormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement>) => {
                    const newValues = {...values, id}
                    setFN(newValues, e.target.value)

                    handleChange(e)
                    setFormValues(newValues)
                    mutateFormCache(newValues)
                }

                const disable = disableSubmit(isValid, isSubmitting, touched)

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormErrors errors={JSON.stringify(errors)}/>
                        <FormGroup>
                            <LabelInputStacked
                                name="name"
                                label="Name"
                                onChange={setFormValuesWithChanges((f, v) => f.name = v)}
                            />
                        </FormGroup>

                        <UIForm.Button positive type={"submit"} disabled={disable}>Create</UIForm.Button>
                    </form>)
            }}
        </Formik>
    </div>)
}

const recipeToUpdateFormMaybe = (recipe: Recipe | undefined): CreateUpdateRecipeFormValues | undefined => {
    return recipe !== undefined ? {id: recipe.id, name: recipe.name} : undefined
}