import {SubstrateBatchV2} from "../../query/substrate/entities";
import {useModal} from "../../hooks/useModal";
import {Button} from "semantic-ui-react";
import {IconEdit} from "../../components/icon/common";
import {JWTToken} from "../../query/jwt";
import {BasicModal} from "../../components/modal/basic";
import React from "react";
import {CreateSubstrateBatchForm, CreateSubstrateBatchFormValues} from "./innoculation-form";

type InoculationModelProps = {
    batch?: SubstrateBatchV2
}

export function InoculationModel({batch}: InoculationModelProps) {
    const {isOpen, onOpen, onClose} = useModal()
    const isCreate = batch === undefined
    const title = isCreate ? "Create Substrate Batch" : "Update Substrate Batch"
    const trigger = isCreate
        ? <Button type={"button"} size={"small"} color='blue'>{title}</Button>
        : <Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>

    const formElement = isCreate
        ? <CreateSubstrateBatchForm onSuccess={onClose}/>
        : <CreateSubstrateBatchForm batch={batch}  onSuccess={onClose}/>


    const action = (token: Promise<JWTToken>) => (v: CreateSubstrateBatchFormValues): Promise<void> => Promise.resolve()

    return (
        <BasicModal
            close={onClose}
            trigger={trigger}
            header={title}
            isOpen={isOpen}
            open={onOpen}
            size={"large"}
        >
            {formElement}
        </BasicModal>
    )
}