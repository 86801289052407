/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create blankBatch request object
 * @export
 * @interface UpdateBlankBatchRequest
 */
export interface UpdateBlankBatchRequest {
    /**
     * 
     * @type {Date}
     * @memberof UpdateBlankBatchRequest
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateBlankBatchRequest
     */
    pasteurizedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateBlankBatchRequest
     */
    recipeId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBlankBatchRequest
     */
    unitTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBlankBatchRequest
     */
    notes: string;
}

/**
 * Check if a given object implements the UpdateBlankBatchRequest interface.
 */
export function instanceOfUpdateBlankBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "recipeId" in value;
    isInstance = isInstance && "unitTypeId" in value;
    isInstance = isInstance && "notes" in value;

    return isInstance;
}

export function UpdateBlankBatchRequestFromJSON(json: any): UpdateBlankBatchRequest {
    return UpdateBlankBatchRequestFromJSONTyped(json, false);
}

export function UpdateBlankBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBlankBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': (new Date(json['createdDate'])),
        'pasteurizedDate': !exists(json, 'pasteurizedDate') ? undefined : (new Date(json['pasteurizedDate'])),
        'recipeId': json['recipeId'],
        'unitTypeId': json['unitTypeId'],
        'notes': json['notes'],
    };
}

export function UpdateBlankBatchRequestToJSON(value?: UpdateBlankBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': (value.createdDate.toISOString()),
        'pasteurizedDate': value.pasteurizedDate === undefined ? undefined : (value.pasteurizedDate.toISOString()),
        'recipeId': value.recipeId,
        'unitTypeId': value.unitTypeId,
        'notes': value.notes,
    };
}

