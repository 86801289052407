import {BlankBatch, BlankBatchItem, newBlankBatch, newBlankBatchItem} from "./entities";
import {newInmemoryStore, Store} from "../inmemory";
import {BlankBatchFormValues} from "../../pages/blank-batch/batch-form";
import {arrayRange} from "../../lib/collections/collection";
import {RecipeOne, recipes} from "../recipe/inmemory";
import {UnitTypeOne, unitTypes} from "../unit-type/inmemory";
import {actualOrNull, actualOrUndefined} from "../../lib/null/null";
import {UpdateBlankBatchItemFormValues} from "./query";


const blankBatchStore = newInmemoryStore<BlankBatch>()
blankBatchStore.add(newBlankBatch("bb-1", "blank_batch_1", new Date("2023-11-20T03:24:00"), undefined, RecipeOne, UnitTypeOne, 3, "notes"))
blankBatchStore.add(newBlankBatch("bb-2", "blank_batch_2", new Date("2023-11-21T03:24:00"), undefined, RecipeOne, UnitTypeOne, 3, "notes"))
blankBatchStore.add(newBlankBatch("bb-3", "blank_batch_3", new Date("2023-11-22T03:24:00"), undefined, RecipeOne, UnitTypeOne, 3, "notes"))


export const blankBatchItemStore = newInmemoryStore<BlankBatchItem>()
createAndStoreItems(blankBatchItemStore, blankBatchStore.toArray()[0])
createAndStoreItems(blankBatchItemStore, blankBatchStore.toArray()[1])
createAndStoreItems(blankBatchItemStore, blankBatchStore.toArray()[2])


export async function inMemGetBlankBatchesWithItems(): Promise<BlankBatch[]> {
    return blankBatchStore.toArray().map((batch: BlankBatch) => {
        batch.items = blankBatchItemStore.find((item) => item.batchId === batch.id)

        return batch
    })
}

export async function inMemCreateBlankBatchesWithItems(values: BlankBatchFormValues): Promise<void> {
    const {batchCode, notes, createdDate, pasteurizedDate, numberOfItems, recipeId, unitTypeId} = values
    const numItems = parseInt(numberOfItems)

    const recipe = recipes.find(r => r.id === recipeId)
    if (recipe === undefined) {
        throw new Error(`can not find recipe ${recipeId}`)
    }

    const unitType = unitTypes.find(u => u.id === unitTypeId)
    if (unitType === undefined) {
        throw new Error(`can not find unitType ${unitTypeId}`)
    }


    const created = newBlankBatch(`bb-${blankBatchStore.length()+1}`, batchCode, createdDate, actualOrUndefined(pasteurizedDate), recipe, unitType, numItems, notes)

    blankBatchStore.add(created)
    createAndStoreItems(blankBatchItemStore, created)

    console.log("blankBatch", blankBatchStore.toArray())

}


export async function inMemUpdateBlankBatchesWithItems(values: BlankBatchFormValues): Promise<void> {
    const {id, notes, createdDate, pasteurizedDate, recipeId, unitTypeId} = values

    const originalSearch = blankBatchStore.find((item) => item.id === id)
    if (originalSearch.length === 0) {
        throw new Error(`can not find blank batch ${id}`)
    }

    const original = originalSearch[0]

    const recipe = recipes.find(r => r.id === recipeId)
    if (recipe === undefined) {
        throw new Error(`can not find recipe ${recipeId}`)
    }

    const unitType = unitTypes.find(u => u.id === unitTypeId)
    if (unitType === undefined) {
        throw new Error(`can not find unitType ${unitTypeId}`)
    }
    // if pateurized, date set then is pateurised
    blankBatchStore.update(
        (item) => item.id === id,
        () => newBlankBatch(id, original.batchCode, createdDate, actualOrUndefined(pasteurizedDate), recipe, unitType, original.numberOfItems, notes),
    )

    console.log("blankBatch", blankBatchStore.toArray())
}


export async function inMemUpdateBlankBatchItem(values: UpdateBlankBatchItemFormValues): Promise<void> {
    const {id, contaminated} = values


    const updated = blankBatchItemStore.updateWith( item => item.id === id, item => {
        item.contaminated = contaminated
        return item
    })

    if (!updated) {
        throw new Error("unable to update")
    }

    console.log("blankBatchItem", blankBatchItemStore.toArray())
}


function createBlankItemsFrom(batch: BlankBatch): BlankBatchItem[] {
    return arrayRange(batch.numberOfItems).map((_, i:number) => newBlankBatchItem(`${batch.id}-${i+1}`, batch.id, `${batch.batchCode} - ${i+1}`, false))
}

function createAndStoreItems(blankBatchItemStore: Store<BlankBatchItem>, batch: BlankBatch | undefined ) {
    if (batch === undefined) {
        return
    }

    createBlankItemsFrom(batch).forEach(b => blankBatchItemStore.add(b))
}