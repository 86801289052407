/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create substrateBatch request object
 * @export
 * @interface CreateUpdateSubstrateBatchRequest
 */
export interface CreateUpdateSubstrateBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubstrateBatchRequest
     */
    batchCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubstrateBatchRequest
     */
    spawnBatchItemId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateSubstrateBatchRequest
     */
    blankBatchItemIds: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSubstrateBatchRequest
     */
    createdDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubstrateBatchRequest
     */
    notes: string;
}

/**
 * Check if a given object implements the CreateUpdateSubstrateBatchRequest interface.
 */
export function instanceOfCreateUpdateSubstrateBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "spawnBatchItemId" in value;
    isInstance = isInstance && "blankBatchItemIds" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "notes" in value;

    return isInstance;
}

export function CreateUpdateSubstrateBatchRequestFromJSON(json: any): CreateUpdateSubstrateBatchRequest {
    return CreateUpdateSubstrateBatchRequestFromJSONTyped(json, false);
}

export function CreateUpdateSubstrateBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSubstrateBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batchCode': json['batchCode'],
        'spawnBatchItemId': json['spawnBatchItemId'],
        'blankBatchItemIds': json['blankBatchItemIds'],
        'createdDate': (new Date(json['createdDate'])),
        'notes': json['notes'],
    };
}

export function CreateUpdateSubstrateBatchRequestToJSON(value?: CreateUpdateSubstrateBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batchCode': value.batchCode,
        'spawnBatchItemId': value.spawnBatchItemId,
        'blankBatchItemIds': value.blankBatchItemIds,
        'createdDate': (value.createdDate.toISOString()),
        'notes': value.notes,
    };
}

