import {JWTToken} from "../jwt";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {
    BlankBatchEntity,
    BlankBatchItemPartialEntity,
    CreateBlankBatchRequest,
    UpdateBlankBatchItemByIDRequest, UpdateBlankBatchItemRequest,
    UpdateBlankBatchRequest,
} from "../../generated-sources/openapi";
import {BlankBatch, BlankBatchItem, newBlankBatch, newBlankBatchItem} from "./entities";
import {mapRecipeEntityToRecipe} from "../recipe/sdapi";
import {mapUnitTypeEntityToUnitType} from "../unit-type/sdapi";
import {BlankBatchFormValues} from "../../pages/blank-batch/batch-form";
import {actualOrNull, actualOrUndefined} from "../../lib/null/null";
import {UpdateBlankBatchItemFormValues} from "./query";


export async function sdGetBlankBatches(token: Promise<JWTToken>): Promise<BlankBatch[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const batches = await client.getAllBlankBatches(undefined, authHeaderBearer(jwt))

    return batches.map(mapBlankBatchEntityToBlankBatch)
}
//
export async function sdCreateBlankBatches(token: Promise<JWTToken>, values: BlankBatchFormValues): Promise<BlankBatch> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token


    const {batchCode, createdDate, recipeId, unitTypeId, pasteurizedDate, notes, numberOfItems} = values

    const nItems = parseInt(numberOfItems)

    const createBlankBatchRequest: CreateBlankBatchRequest = {
        batchCode,
        createdDate,
        recipeId,
        unitTypeId,
        pasteurizedDate: actualOrUndefined(pasteurizedDate),
        numberOfItems: nItems,
        notes,
    }

    const batch = await client.createBlankBatch({createBlankBatchRequest}, authHeaderBearer(jwt))

    return mapBlankBatchEntityToBlankBatch(batch)
}




export async function sdUpdateBlankBatches(token: Promise<JWTToken>, values: BlankBatchFormValues): Promise<BlankBatch> {
    const {defaultClient: client} = getAPIClient()
    const jwt = await token
    const {id, createdDate, recipeId, unitTypeId, pasteurizedDate, notes} = values

    if (id === "") {
        throw new Error("update blank batch ID should not be empty")
    }

    const updateBlankBatchRequest: UpdateBlankBatchRequest = {
        createdDate,
        recipeId,
        unitTypeId,
        pasteurizedDate: actualOrUndefined(pasteurizedDate),
        notes,
    }

    const batch = await client.updateBlankBatchByID({id, updateBlankBatchRequest}, authHeaderBearer(jwt))

    return mapBlankBatchEntityToBlankBatch(batch)
}

export async function sdUpdateBlankBatchItem(token: Promise<JWTToken>, values: UpdateBlankBatchItemFormValues): Promise<BlankBatchItem> {
    const {defaultClient: client} = getAPIClient()
    const jwt = await token
    const {id, batchId, contaminated} = values

    if (id === "") {
        throw new Error("update blank batch item ID should not be empty")
    }

    const updateBlankBatchItemRequest: UpdateBlankBatchItemRequest = {contaminated}

    const batchItem = await client.updateBlankBatchItemByID({id: batchId, itemId: id, updateBlankBatchItemRequest}, authHeaderBearer(jwt))

    return mapBlankBatchItemEntityToBlankBatchItem(batchItem)
}



function mapBlankBatchEntityToBlankBatch(batch: BlankBatchEntity): BlankBatch {
    return newBlankBatch(
        batch.id,
        batch.batchCode,
        batch.createdDate,
        batch.pasteurizedDate,
        mapRecipeEntityToRecipe(batch.recipe),
        mapUnitTypeEntityToUnitType(batch.unitType),
        batch.items.length,
        batch.notes,
        batch.items.map(mapBlankBatchItemEntityToBlankBatchItem)
    )
}

export function mapBlankBatchItemEntityToBlankBatchItem({id, batchId, reference, inoculated, contaminated}: BlankBatchItemPartialEntity): BlankBatchItem {
    return newBlankBatchItem(id, batchId, reference, inoculated, contaminated)
}