import {JWTToken} from "../jwt";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {
    CreateSpawnBatchRequest,
    SpawnBatchEntity,
    SpawnBatchItemEntity, SpawnBatchItemPartialEntity, UpdateSpawnBatchItemRequest
} from "../../generated-sources/openapi";
import {
    newSpawnBatch,
    newSpawnBatchDetails,
    newSpawnBatchItem, newSpawnBatchItemPartial,
    SpawnBatch,
    SpawnBatchItem, SpawnBatchItemPartial
} from "./entities";
import {mapLiquidCultureBatchEntityItemToLiquidCultureBatchItem} from "../liquid-culture-batch/sdapi";
import {CreateSpawnBatchFormValues} from "../../pages/spawn-batch/form";
import {UpdateSpawnBatchItemFormValues} from "./query";

const mapSpawnBatchItemPartialEntitySpawnBatchItemPartial = ({id, reference, spent, contaminated}: SpawnBatchItemPartialEntity ) => newSpawnBatchItemPartial(id, reference, "todo: this probably breaking", spent, contaminated)
const mapSpawnBatchItemPartialEntitiesSpawnBatchItemPartials = (items: SpawnBatchItemPartialEntity[]): SpawnBatchItemPartial[] =>  items.map(mapSpawnBatchItemPartialEntitySpawnBatchItemPartial)




export async function sdGetSpawnBatches(token: Promise<JWTToken>): Promise<SpawnBatch[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const spawnBatches = await client.getAllSpawnBatches(undefined, authHeaderBearer(jwt))

    return spawnBatches.map(mapSpawnBatchEntitySpawnBatch)
}


export async function sdGetSpawnBatchItems(token: Promise<JWTToken>): Promise<SpawnBatchItem[]> {
    const {defaultClient: client} = getAPIClient()
    const jwt = await token

    const spawnBatchItems = await client.getAllSpawnBatchItems(authHeaderBearer(jwt))

    return spawnBatchItems.map(mapSpawnBatchItemEntitySpawnBatchItem)
}

export async function sdCreateSpawnBatch(token: Promise<JWTToken>, values: CreateSpawnBatchFormValues): Promise<SpawnBatch> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const numberContainers = parseInt(values.numberContainers, 10)
    const weightGrams = parseInt(values.weightGrams, 10)

    const createSpawnBatchRequest: CreateSpawnBatchRequest = {
        id: "",
        batchCode: values.batchCode,
        numberOfContainers: numberContainers,
        containerWeightGrams: weightGrams,
        notes: values.notes,
        startDate: values.startDate,
        liquidCultureBatchItemId: values.liquidCultureBatchItemID,
        markLiquidCultureBatchItemAsSpent: values.markLiquidCultureBatchItemAsSpent
    }

    const spawnBatch = await client.createSpawnBatch({createSpawnBatchRequest}, authHeaderBearer(jwt))

    return mapSpawnBatchEntitySpawnBatch(spawnBatch)
}

export async function sdUpdateSpawnItem(token: Promise<JWTToken>, {id, contaminated}: UpdateSpawnBatchItemFormValues): Promise<SpawnBatchItemPartial> {
    console.log("sdUpdateSpawnItem", id, contaminated)



    const {defaultClient: client} = getAPIClient()

    const updateSpawnBatchItemRequest: UpdateSpawnBatchItemRequest = {
        contaminated
    }

    const item = await client.updateSpawnBatchItem({id, updateSpawnBatchItemRequest}, authHeaderBearer(await token))

    return mapSpawnBatchItemPartialEntitySpawnBatchItemPartial(item)
}

function mapSpawnBatchEntitySpawnBatch(spawnBatch: SpawnBatchEntity): SpawnBatch {
    return newSpawnBatch(
        spawnBatch.id,
        spawnBatch.batchCode,
        spawnBatch.numberOfContainers,
        spawnBatch.containerWeightGrams,
        spawnBatch.notes,
        spawnBatch.startDate,
        mapLiquidCultureBatchEntityItemToLiquidCultureBatchItem(spawnBatch.liquidCultureBatchItem),
        mapSpawnBatchItemPartialEntitiesSpawnBatchItemPartials(spawnBatch.items)
    )
}

export function mapSpawnBatchItemEntitySpawnBatchItem(item: SpawnBatchItemEntity): SpawnBatchItem {
    return newSpawnBatchItem(
        item.id,
        item.reference,
        newSpawnBatchDetails(item.batch.id, item.batch.batchCode, item.batch.strain, item.batch.startDate),
        item.spent,
        item.contaminated
    )
}
