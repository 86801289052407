import {inMemCreateSupplier, inMemGetSuppliers, inMemUpdateSupplier} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {JWTToken} from "../jwt";
import {sdCreateSupplier, sdGetSuppliers, sdUpdateSupplier} from "./sdapi";
import {FormValues} from "../../pages/supplier/form";


export type Supplier = {
    id: string
    name: string
}

export type CreateSupplier =  {
    name: string
}

export type UpdateSupplier =  {
    id: string
    name: string
}


export const newSupplier = (id: string, name: string): Supplier => ({id, name})
export const emptySupplier = () => newSupplier("emptySupplier-001", "emptySupplier")
let formCache: FormValues = {id: "", name: ""}

export async function getSupplierCreateFormCache(): Promise<FormValues> {
    return formCache
}

export async function updateSupplierCreateFormCache(values: FormValues) {
    formCache = values
}

export async function getSuppliers(token: Promise<JWTToken>): Promise<Supplier[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetSuppliers(token)
    }

    return inMemGetSuppliers()
}


export function createSupplierFN(token: Promise<JWTToken>) {
    return async ( values: FormValues) => {
            if (isEnvProduction() || isEnvIntegration()) {
                await sdCreateSupplier(token, values)
                return
            }


            return inMemCreateSupplier(values)
        }
}


export function updateSupplierFN(token: Promise<JWTToken>) {
    return async ( values: FormValues) => {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateSupplier(token, values)
            return
        }

        return inMemUpdateSupplier(values)
    }
}




export function mapSupplierEntityToSupplier(entity: any): Supplier {
    return newSupplier(entity.id, entity.name)
}