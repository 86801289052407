import {Table} from "semantic-ui-react";



// TableHeader returns semantic ui library
export const TableHeader = (props: {headers: string[]}) => {
    return (
        <Table.Row>
            {props.headers.map((h, i) => <Table.HeaderCell key={i}>{h}</Table.HeaderCell>)}
        </Table.Row>
    )
}