import React from 'react';
import SideNavContent from "../../components/side-nav-content";
import {
    Grid,
} from "semantic-ui-react";
import {HarvestTable} from "./table";


export const HarvestPage = () => {
    return (
        <SideNavContent>
            <Grid>
                <HarvestTable/>
            </Grid>
        </SideNavContent>
    );
};


