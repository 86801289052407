import {SubstrateBatchV2} from "../substrate/entities";

export type Harvest = {
    id: string
    batch: SubstrateBatchV2
    flush: Flush
    weights: number[]
}

export type Flush = "firstFlush"|"secondFlush"

export function flushFrom(maybeFlush: string): Flush {
    if (maybeFlush === "firstFlush" || maybeFlush === "secondFlush") {
        return maybeFlush
    }

    throw new Error(`Invalid flush: ${maybeFlush}`)
}

export type CreateFlushRequest = {
    batchID: string
    flush: Flush
    harvestNumbers: number[]
}


export const newHarvest = (id: string, batch: SubstrateBatchV2, flush: Flush, weights: number[]): Harvest => {
    return {id, batch, flush, weights}
}

export const totalWeight = (harvest: Harvest): number => {
    return harvest.weights.reduce((prev, curr) => curr+prev, 0)
}