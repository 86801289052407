/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BlankBatchEntity,
  CreateBlankBatchRequest,
  CreateCultureStarterRequest,
  CreateHarvestRequest,
  CreateLiquidCultureBatchRequest,
  CreateRecipeRequest,
  CreateSpawnBatchRequest,
  CreateStrainRequest,
  CreateSuppliersRequest,
  CreateUnitTypeRequest,
  CreateUpdateSubstrateBatchRequest,
  CultureStarterEntity,
  ErrorResponse,
  HarvestEntity,
  LiquidCultureBatchEntity,
  LiquidCultureBatchItemEntity,
  RecipeEntity,
  SpawnBatchEntity,
  StrainEntity,
  SubstrateBatchEntity,
  SupplierEntity,
  UnitTypeEntity,
} from '../models/index';
import {
    BlankBatchEntityFromJSON,
    BlankBatchEntityToJSON,
    CreateBlankBatchRequestFromJSON,
    CreateBlankBatchRequestToJSON,
    CreateCultureStarterRequestFromJSON,
    CreateCultureStarterRequestToJSON,
    CreateHarvestRequestFromJSON,
    CreateHarvestRequestToJSON,
    CreateLiquidCultureBatchRequestFromJSON,
    CreateLiquidCultureBatchRequestToJSON,
    CreateRecipeRequestFromJSON,
    CreateRecipeRequestToJSON,
    CreateSpawnBatchRequestFromJSON,
    CreateSpawnBatchRequestToJSON,
    CreateStrainRequestFromJSON,
    CreateStrainRequestToJSON,
    CreateSuppliersRequestFromJSON,
    CreateSuppliersRequestToJSON,
    CreateUnitTypeRequestFromJSON,
    CreateUnitTypeRequestToJSON,
    CreateUpdateSubstrateBatchRequestFromJSON,
    CreateUpdateSubstrateBatchRequestToJSON,
    CultureStarterEntityFromJSON,
    CultureStarterEntityToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HarvestEntityFromJSON,
    HarvestEntityToJSON,
    LiquidCultureBatchEntityFromJSON,
    LiquidCultureBatchEntityToJSON,
    LiquidCultureBatchItemEntityFromJSON,
    LiquidCultureBatchItemEntityToJSON,
    RecipeEntityFromJSON,
    RecipeEntityToJSON,
    SpawnBatchEntityFromJSON,
    SpawnBatchEntityToJSON,
    StrainEntityFromJSON,
    StrainEntityToJSON,
    SubstrateBatchEntityFromJSON,
    SubstrateBatchEntityToJSON,
    SupplierEntityFromJSON,
    SupplierEntityToJSON,
    UnitTypeEntityFromJSON,
    UnitTypeEntityToJSON,
} from '../models/index';

export interface CreateBlankBatchOperationRequest {
    createBlankBatchRequest?: CreateBlankBatchRequest;
}

export interface CreateCultureStarterOperationRequest {
    createCultureStarterRequest?: CreateCultureStarterRequest;
}

export interface CreateHarvestOperationRequest {
    createHarvestRequest?: CreateHarvestRequest;
}

export interface CreateLiquidCultureBatchOperationRequest {
    createLiquidCultureBatchRequest?: CreateLiquidCultureBatchRequest;
}

export interface CreateRecipeOperationRequest {
    createRecipeRequest?: CreateRecipeRequest;
}

export interface CreateSpawnBatchOperationRequest {
    createSpawnBatchRequest?: CreateSpawnBatchRequest;
}

export interface CreateStrainOperationRequest {
    createStrainRequest?: CreateStrainRequest;
}

export interface CreateSubstrateBatchRequest {
    createUpdateSubstrateBatchRequest?: CreateUpdateSubstrateBatchRequest;
}

export interface CreateSupplierRequest {
    createSuppliersRequest?: CreateSuppliersRequest;
}

export interface CreateUnitTypeOperationRequest {
    createUnitTypeRequest?: CreateUnitTypeRequest;
}

export interface GetAllBlankBatchesRequest {
    deleted?: string;
}

export interface GetAllCultureStartersRequest {
    deleted?: string;
}

export interface GetAllHarvestsRequest {
    deleted?: string;
}

export interface GetAllLiquidCultureBatchItemsRequest {
    deleted?: string;
}

export interface GetAllLiquidCultureBatchesRequest {
    deleted?: string;
}

export interface GetAllRecipesRequest {
    deleted?: string;
}

export interface GetAllSpawnBatchesRequest {
    deleted?: string;
}

export interface GetAllStrainsRequest {
    deleted?: string;
}

export interface GetAllSubstrateBatchesRequest {
    deleted?: string;
}

export interface GetAllSuppliersRequest {
    deleted?: string;
}

export interface GetAllUnitTypesRequest {
    deleted?: string;
}

/**
 * 
 */
export class CoreApi extends runtime.BaseAPI {

    /**
     * Create a new BlankBatch api
     */
    async createBlankBatchRaw(requestParameters: CreateBlankBatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlankBatchEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/blank-batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBlankBatchRequestToJSON(requestParameters.createBlankBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlankBatchEntityFromJSON(jsonValue));
    }

    /**
     * Create a new BlankBatch api
     */
    async createBlankBatch(requestParameters: CreateBlankBatchOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlankBatchEntity> {
        const response = await this.createBlankBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new CultureStarter api
     */
    async createCultureStarterRaw(requestParameters: CreateCultureStarterOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CultureStarterEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/culture-starter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCultureStarterRequestToJSON(requestParameters.createCultureStarterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CultureStarterEntityFromJSON(jsonValue));
    }

    /**
     * Create a new CultureStarter api
     */
    async createCultureStarter(requestParameters: CreateCultureStarterOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CultureStarterEntity> {
        const response = await this.createCultureStarterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Harvest api
     */
    async createHarvestRaw(requestParameters: CreateHarvestOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HarvestEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/harvests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHarvestRequestToJSON(requestParameters.createHarvestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HarvestEntityFromJSON(jsonValue));
    }

    /**
     * Create a new Harvest api
     */
    async createHarvest(requestParameters: CreateHarvestOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HarvestEntity> {
        const response = await this.createHarvestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new LiquidCultureBatch api
     */
    async createLiquidCultureBatchRaw(requestParameters: CreateLiquidCultureBatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiquidCultureBatchEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/liquid-culture-batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLiquidCultureBatchRequestToJSON(requestParameters.createLiquidCultureBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiquidCultureBatchEntityFromJSON(jsonValue));
    }

    /**
     * Create a new LiquidCultureBatch api
     */
    async createLiquidCultureBatch(requestParameters: CreateLiquidCultureBatchOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiquidCultureBatchEntity> {
        const response = await this.createLiquidCultureBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Recipe api
     */
    async createRecipeRaw(requestParameters: CreateRecipeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipeEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/recipes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRecipeRequestToJSON(requestParameters.createRecipeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeEntityFromJSON(jsonValue));
    }

    /**
     * Create a new Recipe api
     */
    async createRecipe(requestParameters: CreateRecipeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipeEntity> {
        const response = await this.createRecipeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new SpawnBatch api
     */
    async createSpawnBatchRaw(requestParameters: CreateSpawnBatchOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpawnBatchEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/spawn-batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSpawnBatchRequestToJSON(requestParameters.createSpawnBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpawnBatchEntityFromJSON(jsonValue));
    }

    /**
     * Create a new SpawnBatch api
     */
    async createSpawnBatch(requestParameters: CreateSpawnBatchOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpawnBatchEntity> {
        const response = await this.createSpawnBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Strain api
     */
    async createStrainRaw(requestParameters: CreateStrainOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrainEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/strains`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStrainRequestToJSON(requestParameters.createStrainRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrainEntityFromJSON(jsonValue));
    }

    /**
     * Create a new Strain api
     */
    async createStrain(requestParameters: CreateStrainOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrainEntity> {
        const response = await this.createStrainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new SubstrateBatch api
     */
    async createSubstrateBatchRaw(requestParameters: CreateSubstrateBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubstrateBatchEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/substrate-batches`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSubstrateBatchRequestToJSON(requestParameters.createUpdateSubstrateBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubstrateBatchEntityFromJSON(jsonValue));
    }

    /**
     * Create a new SubstrateBatch api
     */
    async createSubstrateBatch(requestParameters: CreateSubstrateBatchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubstrateBatchEntity> {
        const response = await this.createSubstrateBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Supplier api
     */
    async createSupplierRaw(requestParameters: CreateSupplierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/suppliers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSuppliersRequestToJSON(requestParameters.createSuppliersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierEntityFromJSON(jsonValue));
    }

    /**
     * Create a new Supplier api
     */
    async createSupplier(requestParameters: CreateSupplierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierEntity> {
        const response = await this.createSupplierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new UnitType api
     */
    async createUnitTypeRaw(requestParameters: CreateUnitTypeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnitTypeEntity>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/unit-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUnitTypeRequestToJSON(requestParameters.createUnitTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitTypeEntityFromJSON(jsonValue));
    }

    /**
     * Create a new UnitType api
     */
    async createUnitType(requestParameters: CreateUnitTypeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnitTypeEntity> {
        const response = await this.createUnitTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all BlankBatches api
     */
    async getAllBlankBatchesRaw(requestParameters: GetAllBlankBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BlankBatchEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/blank-batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BlankBatchEntityFromJSON));
    }

    /**
     * Get all BlankBatches api
     */
    async getAllBlankBatches(requestParameters: GetAllBlankBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BlankBatchEntity>> {
        const response = await this.getAllBlankBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all CultureStarters api
     */
    async getAllCultureStartersRaw(requestParameters: GetAllCultureStartersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CultureStarterEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/culture-starter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CultureStarterEntityFromJSON));
    }

    /**
     * Get all CultureStarters api
     */
    async getAllCultureStarters(requestParameters: GetAllCultureStartersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CultureStarterEntity>> {
        const response = await this.getAllCultureStartersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Harvests api
     */
    async getAllHarvestsRaw(requestParameters: GetAllHarvestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HarvestEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/harvests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HarvestEntityFromJSON));
    }

    /**
     * Get all Harvests api
     */
    async getAllHarvests(requestParameters: GetAllHarvestsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HarvestEntity>> {
        const response = await this.getAllHarvestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all LiquidCultureBatchItems api
     */
    async getAllLiquidCultureBatchItemsRaw(requestParameters: GetAllLiquidCultureBatchItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LiquidCultureBatchItemEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batch-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LiquidCultureBatchItemEntityFromJSON));
    }

    /**
     * Get all LiquidCultureBatchItems api
     */
    async getAllLiquidCultureBatchItems(requestParameters: GetAllLiquidCultureBatchItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LiquidCultureBatchItemEntity>> {
        const response = await this.getAllLiquidCultureBatchItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all LiquidCultureBatches api
     */
    async getAllLiquidCultureBatchesRaw(requestParameters: GetAllLiquidCultureBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LiquidCultureBatchEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LiquidCultureBatchEntityFromJSON));
    }

    /**
     * Get all LiquidCultureBatches api
     */
    async getAllLiquidCultureBatches(requestParameters: GetAllLiquidCultureBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LiquidCultureBatchEntity>> {
        const response = await this.getAllLiquidCultureBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Recipes api
     */
    async getAllRecipesRaw(requestParameters: GetAllRecipesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RecipeEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/recipes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecipeEntityFromJSON));
    }

    /**
     * Get all Recipes api
     */
    async getAllRecipes(requestParameters: GetAllRecipesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RecipeEntity>> {
        const response = await this.getAllRecipesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all SpawnBatches api
     */
    async getAllSpawnBatchesRaw(requestParameters: GetAllSpawnBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpawnBatchEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/spawn-batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpawnBatchEntityFromJSON));
    }

    /**
     * Get all SpawnBatches api
     */
    async getAllSpawnBatches(requestParameters: GetAllSpawnBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpawnBatchEntity>> {
        const response = await this.getAllSpawnBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Strains api
     */
    async getAllStrainsRaw(requestParameters: GetAllStrainsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StrainEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/strains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StrainEntityFromJSON));
    }

    /**
     * Get all Strains api
     */
    async getAllStrains(requestParameters: GetAllStrainsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StrainEntity>> {
        const response = await this.getAllStrainsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all SubstrateBatches api
     */
    async getAllSubstrateBatchesRaw(requestParameters: GetAllSubstrateBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubstrateBatchEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/substrate-batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubstrateBatchEntityFromJSON));
    }

    /**
     * Get all SubstrateBatches api
     */
    async getAllSubstrateBatches(requestParameters: GetAllSubstrateBatchesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubstrateBatchEntity>> {
        const response = await this.getAllSubstrateBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Suppliers api
     */
    async getAllSuppliersRaw(requestParameters: GetAllSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SupplierEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SupplierEntityFromJSON));
    }

    /**
     * Get all Suppliers api
     */
    async getAllSuppliers(requestParameters: GetAllSuppliersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SupplierEntity>> {
        const response = await this.getAllSuppliersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all UnitTypes api
     */
    async getAllUnitTypesRaw(requestParameters: GetAllUnitTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UnitTypeEntity>>> {
        const queryParameters: any = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/unit-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitTypeEntityFromJSON));
    }

    /**
     * Get all UnitTypes api
     */
    async getAllUnitTypes(requestParameters: GetAllUnitTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UnitTypeEntity>> {
        const response = await this.getAllUnitTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
