import {Configuration, CoreApi, DefaultApi, FetchParams, ResponseContext} from "../generated-sources/openapi";

// Not sure why but the code gen library produces to different clients, core and default, both have slightly different request methods the implement.
// this module returns both clients with the same preMiddleware function applied to both.


let coreClient= new CoreApi().withPreMiddleware(preMiddleware);
let defaultClient = new DefaultApi().withPreMiddleware(preMiddleware);


export function setAPIClient(baseURL: string) {
    coreClient = new CoreApi(new Configuration({
        basePath: baseURL
    })).withPreMiddleware(preMiddleware);

    defaultClient = new DefaultApi(new Configuration({
        basePath: baseURL
    })).withPreMiddleware(preMiddleware);
}


export function getAPIClient(): {coreClient: CoreApi, defaultClient: DefaultApi} {
    return {
        coreClient: coreClient,
        defaultClient: defaultClient
    }
}

function preMiddleware(context: ResponseContext): Promise<FetchParams | void> {
    return Promise.resolve({
        url: context.url,
        init: {
            ...context.init,
            mode: "cors"
        },
    });
}

export function authHeaderBearer(jwtToken: string): RequestInit {
    return {
        headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
        }
    }
}
