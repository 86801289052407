import {JWTToken} from "../jwt";
import {
    newSubstrateBatchV2,
    SubstrateBatchV2,
} from "./entities";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {
    CreateUpdateSubstrateBatchRequest,
    SubstrateBatchEntity,
} from "../../generated-sources/openapi";
import {CreateSubstrateBatchFormValuesV2} from "./query";
import {mapSpawnBatchItemEntitySpawnBatchItem} from "../spawn-batch/sdapi";
import {mapBlankBatchItemEntityToBlankBatchItem} from "../blank-batch/sdapi";

export async function sdGetSubstrateBatchesWithItems(token: Promise<JWTToken>): Promise<SubstrateBatchV2[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token
    const batches = await client.getAllSubstrateBatches(undefined, authHeaderBearer(jwt))

    return batches.map(mapSubstrateBatchEntityToSubstrateBatch)
}

export async function sdCreateSubstrateBatch(token: Promise<JWTToken>, values: CreateSubstrateBatchFormValuesV2): Promise<SubstrateBatchV2> {
    console.log("Create Substrate Batch sdCreateSubstrateBatch")
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const {batchCode, spawnBatchItemId, blankBatchItemIds, createdDate} = values

    const createUpdateSubstrateBatchRequest: CreateUpdateSubstrateBatchRequest = {
        batchCode,
        spawnBatchItemId,
        blankBatchItemIds,
        createdDate,
        notes: "",
    }

    const batch = await client.createSubstrateBatch({createUpdateSubstrateBatchRequest}, authHeaderBearer(jwt))

    return mapSubstrateBatchEntityToSubstrateBatch(batch)
}


export async function sdUpdateSubstrateBatch(token: Promise<JWTToken>, values: CreateSubstrateBatchFormValuesV2): Promise<SubstrateBatchV2> {
    console.log("Update Substrate Batch sdUpdateSubstrateBatch")
    const {defaultClient: client} = getAPIClient()
    const jwt = await token

    const {id, batchCode, spawnBatchItemId, blankBatchItemIds, createdDate} = values
    const createUpdateSubstrateBatchRequest: CreateUpdateSubstrateBatchRequest = {
        batchCode,
        spawnBatchItemId,
        blankBatchItemIds,
        createdDate,
        notes: "",
    }

    const batch = await client.updateSubstrateBatchByID({id, createUpdateSubstrateBatchRequest}, authHeaderBearer(jwt))

    return mapSubstrateBatchEntityToSubstrateBatch(batch)
}

export function mapSubstrateBatchEntityToSubstrateBatch(entity: SubstrateBatchEntity): SubstrateBatchV2 {
    return newSubstrateBatchV2(
        entity.id,
        entity.batchCode,
        entity.createdDate,
        mapSpawnBatchItemEntitySpawnBatchItem(entity.spawnBatchItem),
        entity.blankBatchItems.map(mapBlankBatchItemEntityToBlankBatchItem),
    )
}
