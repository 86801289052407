import {CreateFlushRequest, Harvest, newHarvest} from "./entities";
import {autoIncrementID} from "../../lib/uuid/uuid";
import {substrateBatchesV2Store} from "../substrate/inmemory-batch";

let harvestStore: Harvest[] = []

export async function inMemGetHarvests(): Promise<Harvest[]> {
    return harvestStore
}

export async function inMemCreateHarvest({batchID, flush, harvestNumbers}: CreateFlushRequest): Promise<Harvest> {
    const batch = substrateBatchesV2Store.findFirst(({id}) => id === batchID)
    if (batch===undefined ){
        throw new Error(`unable to resolve batch ${batchID}`)
    }

    const created =  newHarvest(autoIncrementID(), batch, flush, harvestNumbers)
    harvestStore = [...harvestStore, created]

    return created
}

