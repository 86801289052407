import {BlankBatch, BlankBatchItem} from "../../query/blank-batch/entities";
import {useModal} from "../../hooks/useModal";
import {Button} from "semantic-ui-react";
import {IconCheck, IconClose, IconList} from "../../components/icon/common";
import {BasicModal} from "../../components/modal/basic";
import React, {useState} from "react";
import {authUpdateBlankBatchItem, updateBlankBatchFormCache} from "../../query/blank-batch/query";
import {useMutation, useQueryClient} from "react-query";
import queryKeys from "../../query/keys";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";


type BlankBatchItemsModelProps = {
    items: BlankBatchItem[]
}

export function BlankBatchItemsModal({items}: BlankBatchItemsModelProps) {
    const {isOpen, onOpen, onClose} = useModal()

    const header = "Blank Batch Items"

    // const trigger = <Button size={"mini"} ><IconList/>{`/(${items.length})`}</Button>
    const trigger = <div><IconList/>{`(${items.length})`}</div>

    return (
        <BasicModal header={header} isOpen={isOpen} open={onOpen} close={onClose} trigger={trigger}>
            <ItemsList items={items}/>
        </BasicModal>
    )
}


type ItemsListProps = {
    items: BlankBatchItem[]
}
function ItemsList({items}: ItemsListProps) {
    const queryClient = useQueryClient()
    const getAuthToken = useAuthToken()
    const [notifications, setNotifications] = useState(emptyNotifications);
    const [contaminatedValues, setContaminatedValues] = useState(initContaminatedMap(items))


    const { mutate: contaminateAction } = useMutation(authUpdateBlankBatchItem(getAuthToken()), {
        onSuccess: (_, variables) => {
            setContaminatedValues( before =>  {
                const after = new Map(before)
                after.set(variables.id, variables.contaminated)

                return after
            })
            queryClient.invalidateQueries(queryKeys.blankBatchesWithItems);
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)]  )
        }
    });

    // contaminate one
    if (items.length === 0) {
        return <div>No items</div>
    }

    const rows = items.map(({id, reference, batchId, inoculated}) => {
        const maybeContaminated = contaminatedValues.get(id)
        if (maybeContaminated === undefined) {
           console.log(`contaminated value for id ${id} does not exist `)
        }

        const contaminated =  maybeContaminated !== undefined ? maybeContaminated : true // if undefined set to contaminated so no action can be taken
        return (
            <tr key={id}>
                <td data-label="ID">{id}</td>
                <td data-label="Reference">{reference}</td>
                <td data-label="Inoculated">{inoculated ? <IconCheck/> : <IconClose/> }</td>
                <td data-label="Contaminated">{contaminated ? <IconCheck/> : <IconClose/> }</td>
                <td data-label="Action">
                    <Button
                        disabled={contaminated}
                        color={"red"}
                        onClick={() => {
                            contaminateAction({id, batchId, contaminated: !contaminated})
                        }}
                    >
                        {"Contaminate"}
                    </Button>
                </td>
            </tr>
        )
    })

    return (
        <div >
            <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
            <table className="ui celled table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Reference</th>
                        <th>Inoculated</th>
                        <th>Contaminated</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    )
}


function initContaminatedMap(items: BlankBatchItem[]) {
    return items.reduce((acc, {id, contaminated}, i) => {
        acc.set(id, contaminated)

        return acc
    }, new Map<string, boolean>())
}