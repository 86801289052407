/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpawnBatchItemPartialEntity
 */
export interface SpawnBatchItemPartialEntity {
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchItemPartialEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SpawnBatchItemPartialEntity
     */
    reference: string;
    /**
     * 
     * @type {boolean}
     * @memberof SpawnBatchItemPartialEntity
     */
    spent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SpawnBatchItemPartialEntity
     */
    contaminated: boolean;
}

/**
 * Check if a given object implements the SpawnBatchItemPartialEntity interface.
 */
export function instanceOfSpawnBatchItemPartialEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "spent" in value;
    isInstance = isInstance && "contaminated" in value;

    return isInstance;
}

export function SpawnBatchItemPartialEntityFromJSON(json: any): SpawnBatchItemPartialEntity {
    return SpawnBatchItemPartialEntityFromJSONTyped(json, false);
}

export function SpawnBatchItemPartialEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpawnBatchItemPartialEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reference': json['reference'],
        'spent': json['spent'],
        'contaminated': json['contaminated'],
    };
}

export function SpawnBatchItemPartialEntityToJSON(value?: SpawnBatchItemPartialEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reference': value.reference,
        'spent': value.spent,
        'contaminated': value.contaminated,
    };
}

