import {useMutation, useQuery, useQueryClient} from "react-query";
import queryKeys from "../../query/keys";
import {
    FormGroup,
    LabelInputStacked, LabelSelectionStacked, NoOptionSelectedID, selectOption,
} from "../../components/form/field";
import React, {ChangeEvent, useState} from "react";
import {Form, Formik} from "formik";
import {FormErrors} from "../../components/form/errors";
import {Form as UIForm} from "semantic-ui-react";
import * as yup from "yup";
import {disableSubmit} from "../../components/form/validation";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {BlankBatch} from "../../query/blank-batch/entities";
import {AuthedFormAction} from "../../components/form/types";
import {getBlankBatchFormCache, updateBlankBatchFormCache} from "../../query/blank-batch/query";
import {resolvePriorityData} from "../../lib/data/resolve";
import {formActionLabel} from "../../components/form/label";
import {DateInput, DateInputOptional} from "../../components/form/DateInput";
import {getUnitTypes} from "../../query/unit-type/query";
import {getRecipes} from "../../query/recipe/query";
import {CreateRecipeModal as RecipeCreateModal} from "../recipe/recipe-modal";
import {CreateUnitTypeModal} from "../unit-type/modal";
import {actualOrNull} from "../../lib/null/null";

type OutsourcedFormProps = {
    batch?: BlankBatch
    onSuccess: () => void
    formAction: AuthedFormAction<BlankBatchFormValues>
};


const toForm = (batch: BlankBatch | undefined): BlankBatchFormValues | undefined => {
    return batch === undefined ? undefined : {
        id: batch.id,
        batchCode: batch.batchCode,
        notes: batch.notes,
        createdDate: batch.createdDate,
        pasteurizedDate: actualOrNull(batch.pasteurizedDate),
        recipeId: batch.recipe.id,
        unitTypeId: batch.unitType.id,
        numberOfItems: `${batch.numberOfItems}`,
    }
}


export type BlankBatchFormValues = {
    id: string
    batchCode: string
    createdDate: Date,
    pasteurizedDate: Date | null,
    recipeId: string,
    unitTypeId: string,
    numberOfItems: string,
    notes: string
}

const initialFormValues: BlankBatchFormValues = {
    id: "",
    batchCode: "",
    createdDate: new Date(),
    pasteurizedDate: null,
    recipeId: "",
    unitTypeId: "",
    numberOfItems: "0",
    notes:"",
}

export function BlankBatchForm({batch, onSuccess, formAction}: OutsourcedFormProps) {
    const isCreate = batch === undefined
    const getAuthToken = useAuthToken()
    const [notifications, setNotifications] = useState(emptyNotifications);
    const queryClient = useQueryClient()

    const submitLabel = formActionLabel(batch)

    // form cache
    const formCacheQuery = useQuery([queryKeys.blankBatchFormCache], getBlankBatchFormCache)
    const unitTypesQuery = useQuery([queryKeys.unitTypeGet], () => getUnitTypes(getAuthToken()))
    const recipesQuery = useQuery([queryKeys.recipesGet], () => getRecipes(getAuthToken()))

    const { mutate: formCacheMutate } = useMutation(updateBlankBatchFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.blankBatchFormCache);
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)]  )
        }
    });

    // form submit
    const { mutate: formSubmitMutate } = useMutation(formAction(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.blankBatchesWithItems);
            onSuccess()
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `submit error ${err?.message}`)]  )
        }
    });


    const [formValues, setFormValues] = useState(resolvePriorityData(toForm(batch), formCacheQuery.data, initialFormValues))

    const [createdDate, setCreatedDate ] = useState(formValues.createdDate)
    const [pasteurizedDate, setPasteurizedDate ] = useState(formValues.pasteurizedDate)


    const recipeOptions = recipesQuery.data
        ? recipesQuery.data.map(({name, id}) => (selectOption(id, name)))
        : [selectOption(NoOptionSelectedID, "no recipes please create")]


    const unitTypeOptions = unitTypesQuery.data
        ? unitTypesQuery.data.map(({name, id}) => (selectOption(id, name)))
        : [selectOption(NoOptionSelectedID, "no units available please create")]


    const formSchema = buildValidationScheme()

    return (
        <div>
            <Formik
                initialValues={formValues}
                validationSchema={formSchema}
                onSubmit={(formValues: BlankBatchFormValues, {setSubmitting}) => {
                    setSubmitting(true);
                    // ignore submit her and submit from the model pass form values up the stack
                    formValues.id = batch?.id || ""
                    formValues.createdDate = createdDate
                    formValues.pasteurizedDate = pasteurizedDate

                    console.log("formValues", formValues)

                    // Submit
                    setTimeout(() => {
                        formSubmitMutate(formValues)
                        setSubmitting(false);
                    }, 1)


                    // reset state
                    setFormValues(initialFormValues)
                    setNotifications([])
                    //Clear cache
                    formCacheMutate(initialFormValues)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                  }) => {
                    const setFormValuesWithInputChanges  = (setFN: (formValues: BlankBatchFormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                        let newValues = {...values}
                        setFN(newValues, e.target.value)

                        handleChange(e)
                        setFormValues(newValues)
                        formCacheMutate(newValues)
                    }

                    const disabled = disableSubmit(isValid, isSubmitting, touched)
                    console.log("isSubmitting", isSubmitting)

                    return (
                        <Form className={"ui form"}>
                            <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                            <FormErrors errors={JSON.stringify(errors)}/>


                            <FormGroup>
                                <LabelInputStacked
                                    name={"batchCode"}
                                    label={"Batch Code"}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.batchCode = v)}
                                    disabled={!isCreate}
                                />

                                <LabelSelectionStacked
                                    blankOption={true}
                                    options={recipeOptions}
                                    name={"recipeId"}
                                    label={"Recipe"}
                                    selected={values.recipeId}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.recipeId = v)}
                                />
                                <RecipeCreateModal size={"sml"}/>



                            </FormGroup>

                            <FormGroup>
                                <DateInput
                                    name={"createdDate"}
                                    date={createdDate}
                                    label={"Created"}
                                    onChange={setCreatedDate}
                                    touched={true}
                                />
                                <DateInputOptional
                                    name={"pasteurizedDate"}
                                    date={pasteurizedDate}
                                    label={"Pasteurized Date"}
                                    onChange={setPasteurizedDate}
                                    touched={true}
                                />

                            </FormGroup>

                            <FormGroup>
                                <LabelInputStacked
                                    name={"numberOfItems"}
                                    type={"number"}
                                    label={"# Bags"}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.numberOfItems = v)}
                                    disabled={!isCreate}
                                />
                                <LabelSelectionStacked
                                    blankOption={true}
                                    options={unitTypeOptions}
                                    name={"unitTypeId"}
                                    label={"Unit Type"}
                                    selected={values.unitTypeId}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.unitTypeId = v)}
                                />
                                <CreateUnitTypeModal size={"sml"} supplierID={""} supplierName={""}/>

                            </FormGroup>

                            <FormGroup>
                                <LabelInputStacked
                                    type={"text"}
                                    name={"notes"}
                                    label={"Notes"}
                                    as={"textarea"}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.notes = v)}
                                />
                            </FormGroup>

                            <UIForm.Button positive type={"submit"} disabled={disabled}>{submitLabel}</UIForm.Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>)
}

function buildValidationScheme() {
    return yup.object().shape({
        batchCode: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        recipeId: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        numberOfItems: yup.number()
            .min(1, 'Batch should have at least one bag')
            .required('# bags required'),
        unitTypeId: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        createdDate: yup.date().required("start date required"),
    });
}
