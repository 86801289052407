import {Table} from "semantic-ui-react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import React from "react";
import {getRecipes, Recipe} from "../../query/recipe/query";
import {CreateRecipeModal, UpdateModal} from "./recipe-modal";
import {useAuthToken} from "../../query/jwt";


export function RecipeTablePage(props: {}) {
    const getAuthToken = useAuthToken()
    const title = "Recipes"
    const queryKey = queryKeys.recipesGet
    const numberOfCols = 2

    const header = (
        <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
    )

    const rowsMapper = (recipe: Recipe) => {
        return (
            <Table.Row key={recipe.id}>
                <Table.Cell>{recipe.name}</Table.Cell>
                <Table.Cell><UpdateModal recipe={recipe} size={"mini"}/></Table.Cell>
            </Table.Row>
        )
    }

    const createButton = <CreateRecipeModal size={"sml"}/>
    return(
        <CRUDQueryTablePage<Recipe>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getRecipes(getAuthToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberOfCols}
        />
    )
}