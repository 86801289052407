


// this does decending order, need to have asecding configuration
export function sortByDate<T>(getDate: (i: T) => Date) {
    // return sort function
    return (a: T, b: T) => {
        const aTime = getDate(a).getTime()
        const bTime = getDate(b).getTime()

        if (aTime < bTime) {
            return 1;
        } else if (aTime > bTime) {
            return -1;
        }

        return 0;
    }
}
