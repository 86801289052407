import React, {ChangeEvent, useState} from "react";
import {useAuthToken} from "../../query/jwt";
import {disableSubmit} from "../../components/form/validation";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {FormErrors} from "../../components/form/errors";
import {FormGroup, LabelInputStacked, LabelSelectionStacked, selectOption} from "../../components/form/field";
import {Field, Form, Formik} from "formik";
import {useQuery, useQueryClient} from "react-query";
import queryKeys from "../../query/keys";
import {getStrains} from "../../query/strain/query";
import {CreateUpdateStrainModal} from "../strain/modal";
import * as yup from "yup";
import {getSpawnBatches} from "../../query/spawn-batch/query";
import {sortByDate} from "../../util/sort/datesort";
import {
    noneContaminatedSpawnItem,
    noneSpent,
    SpawnBatch,
    SpawnBatchItemPartial
} from "../../query/spawn-batch/entities";
import {DateInput, formatDateString} from "../../components/form/DateInput";
import {mapFromSingleValue, toggleKey, toggleKeys} from "../../lib/map/map";
import {CreateUpdateBlankBatchModal} from "../blank-batch/batch-modals";
import {getBlankBatchesWithItems} from "../../query/blank-batch/query";
import {BlankBatch, BlankBatchItem, noneContaminated} from "../../query/blank-batch/entities";
import {ConfirmModal} from "./confirm-modal";
import {Label} from "semantic-ui-react";
import {CreateSpawnBatchModel} from "../spawn-batch/spawn";
import {SubstrateBatchV2} from "../../query/substrate/entities";

export type CreateSubstrateBatchFormValues = {
    batchCode: string
}

const initCreateSubstrateBatchFormValues: CreateSubstrateBatchFormValues = {
    batchCode: ""
}

function blankBatchFormValuesInit(batch?: SubstrateBatchV2): CreateSubstrateBatchFormValues {
    return {
        batchCode: !!batch ? batch.batchCode : ""
    }
}

type CreateSubstrateBatchFormProps = {
    batch?: SubstrateBatchV2
    onSuccess: () => void
}

export function CreateSubstrateBatchForm({batch, onSuccess}: CreateSubstrateBatchFormProps ) {
    const isCreate = !batch


    const getAuthToken = useAuthToken()
    const [notifications, setNotifications] = useState(emptyNotifications);
    const [selectedStrain, setSelectedStrain] = useState("");
    const strainQuery = useQuery([queryKeys.strainGet], () => getStrains(getAuthToken()))
    const spawnBatchQuery = useQuery([queryKeys.spawnBatchesGet], () => getSpawnBatches(getAuthToken()))
    const blankBatchQuery = useQuery([queryKeys.blankBatchesWithItems], () => getBlankBatchesWithItems(getAuthToken()))

    const strainOptions = strainQuery.data
        ? strainQuery.data.map(({name, id}, i) => (selectOption(id, name)))
        : [selectOption("0", "no strain please create")]


    // form values
    const [blankBatchItemIds, setBlankBatchItemIds] = useState(blankBatchIdsInit(batch))
    const [spawnItemId, setSpawnItemId] = useState(batch?.spawnBatchItem?.id ?? "")
    const [formValues, setFormValues] = useState(blankBatchFormValuesInit(batch))
    const [createdDate, setCreatedDate]  = useState(new Date())

    const spawnBatches = spawnBatchQuery.data === undefined
        ? []
        : spawnBatchQuery.data
            .filter(({liquidCultureBatchItem}: SpawnBatch) => selectedStrain !== ""
                ? liquidCultureBatchItem.batch.cultureStarter.strain.id === selectedStrain
                : true)
            .map((batch) => {
                batch.items = batch.items.filter((item: SpawnBatchItemPartial) =>
                    item.id === spawnItemId || noneSpent(item) || noneContaminatedSpawnItem(item)
                )

                return batch
            })
            .filter(batch => batch.items.length > 0)
            .sort(sortByDate<SpawnBatch>(({startDate}) => startDate))


    const spawnBatchItems = spawnBatches.reduce((acc, batch) => {
        batch.items.forEach((item) => {
            acc.set(item.id, item)
        })

        return acc
    }, new Map<string, SpawnBatchItemPartial>)

    const blankBatches = blankBatchQuery.data === undefined
        ? []
        : blankBatchQuery.data
            .map((batch: BlankBatch) => {
                console.log("batch", batch)
                batch.items = batch.items
                    .filter((item) => {
                        // if this is an update form then we want none inculated items or items already assocaited with
                        // this batch
                        return !item.inoculated || blankBatchItemIds.has(item.id)
                    })

                return batch
            })
            .filter((batch) => batch.items.length > 0)
            .sort(sortByDate<BlankBatch>(({createdDate}) => createdDate))
            .map((batch) => newNestedItem(
                batch.id,
                `${batch.batchCode} (${formatDateString(batch.createdDate)})`,
                batch.items.filter(noneContaminated).map((item) => newIdName(item.id, item.reference))
            ))

    console.log("CreateSubstrateBatchForm", Array.from(blankBatchItemIds.keys()))
    return (
        <Formik
            initialValues={formValues}
            validationSchema={buildFormSchema()}
            onSubmit={(formValues: CreateSubstrateBatchFormValues, {setSubmitting}) => {
                console.log("submit", formValues);

                return Promise.resolve()
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
              }) => {
                const setFormValuesWithInputChanges = (setFN: (formValues: CreateSubstrateBatchFormValues, value: string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                    let newValues = {...values}
                    setFN(newValues, e.target.value)
                    handleChange(e)
                    setFormValues(newValues)

                }

                return (
                    <Form className={"ui form"}>
                        <div className="ui grid">
                            <div  className="row">
                                <div className="sixteen wide column">
                                    <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                                    <FormErrors errors={JSON.stringify(errors)}/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="sixteen wide column">
                                    <FormGroup>
                                        <DateInput name={"createdDate"} date={createdDate} onChange={setCreatedDate} label={"Created Date"} touched={true}/>
                                        <LabelInputStacked name={"batchCode"} label={"Batch Code"} onChange={setFormValuesWithInputChanges((f, v) => f.batchCode = v )}/>
                                    </FormGroup>
                                </div>
                            </div>


                            <div  className="row">
                                <div className="eight wide column">
                                    {/* Spawn Batches*/}
                                    <FormGroup>
                                        <LabelSelectionStacked
                                            name={"strainID"}
                                            label={"Filter Spawn Items by Strian"}
                                            onChange={(e) => setSelectedStrain(e.target.value)}
                                            options={strainOptions}
                                            blankOption={true}
                                            selected={""}/>

                                    </FormGroup>
                                </div>
                                <div className="eight wide column">
                                        <CreateSpawnBatchModel  size={"mini"} onSuccess={() => {}}/>
                                        <CreateUpdateBlankBatchModal size={"mini"}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="eight wide column">
                                    <FormGroup>
                                        <NestedListSelect
                                            label={"Select A Spawn Batch Item"}
                                            items={spawnBatches.map((batch) => newNestedItem(
                                                    batch.id,
                                                    batch.batchCode,
                                                    batch.items.map((item) => newIdName(item.id, item.reference))
                                                )
                                            )}

                                            selected={mapFromSingleValue(spawnItemId)}
                                            onChange={(...ids: string[]) => {
                                                if (ids.length === 0 ) {
                                                    return
                                                }

                                                setSpawnItemId(ids[0])
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="eight wide column">
                                    <FormGroup>
                                        <NestedListSelect
                                            label={"Select one or more Blank Batch Items"}
                                            items={blankBatches}
                                            selected={blankBatchItemIds}
                                            onChange={(...ids: string[]) => {
                                                const idsCache = new Map<string, string>(blankBatchItemIds)
                                                if (ids.length === 0) {
                                                    return
                                                }

                                                if (ids.length === 1) {
                                                    setBlankBatchItemIds(toggleKey(idsCache, ids[0]))
                                                    return;
                                                }

                                                setBlankBatchItemIds(toggleKeys(idsCache, ids))
                                                return;
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <ConfirmModal
                            batch={batch}
                            spawnBatchItem={spawnBatchItems.get(spawnItemId)}
                            blankBatchItemIds={Array.from(blankBatchItemIds.keys())}
                            createdDate={createdDate} batchCode={formValues.batchCode}
                            onSuccess={() => {
                                setFormValues(initCreateSubstrateBatchFormValues)
                                setSpawnItemId("")
                                setBlankBatchItemIds(new Map())
                                setCreatedDate(new Date())
                                setNotifications([])
                                onSuccess()
                            }}
                            onError={(err: string) => setNotifications((notifactions) => [...notifactions, newNotification("error", err)]  )  }
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}


function buildFormSchema() {
    return yup.object().shape({
        batchCode: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    });
}

type IdName = {
    id: string
    name: string
}

type NestedItem = {
    id: string
    name: string
    children: IdName[]
}

function newIdName(id: string, name: string): IdName {
    return {id, name}
}

function newNestedItem(id: string, name: string, children: IdName[]): NestedItem {
    return {id, name, children: children}
}

type NestedListSelectProps = {
    label: string
    selected: Map<string, string>
    onChange: (...ids: string[]) => void
    items: NestedItem[]
}
function NestedListSelect({label, items, onChange, selected}: NestedListSelectProps) {
    if (items.length === 0) {
        return <div>No Data to display</div>
    }

    const itemElements = [<Label>{label}</Label>, ...items
        .map((item) => <CheckBoxItemParent allowParentToSelectAllChildren={true} parent={newIdName(item.id, item.name)} children={item.children} onChange={onChange} selected={selected}/>)]



    return (
        <div className="ui celled relaxed list">
            {itemElements}
        </div>
    )
}

type CheckBoxItemMasterProps = {
    selected: Map<string, string>
    parent: IdName
    children: IdName[]
    allowParentToSelectAllChildren?: boolean
    onChange: (...ids: string[]) => void
}

function CheckBoxItemParent({parent, children, onChange, selected, allowParentToSelectAllChildren}: CheckBoxItemMasterProps) {
    const parentOnChangeCanBeSelect = allowParentToSelectAllChildren !== undefined && allowParentToSelectAllChildren
    const childElements = children.map(child => <CheckBoxChild selected={selected} key={child.id} idName={child} onChange={onChange}/>)

    const parentElement =  parentOnChangeCanBeSelect
        ? (<>
            <input checked={selected.has(parent.id)} type="checkbox" name={parent.id} onChange={() => onChange(...children.map(({id}) => id))}/>
            <label>{parent.name}</label>
        </>)
        : <div>{parent.name}</div>

    return (
        <div className="item">
            <div className="ui master checkbox">
                {parentElement}
            </div>
            <div className="list">
                {childElements}
            </div>
        </div>
    )
}


type CheckBoxChildProps = {
    idName: IdName
    onChange: (...ids: string[]) => void
    selected: Map<string, string>
}
function CheckBoxChild({idName, onChange, selected}: CheckBoxChildProps) {
    const {id, name} = idName

    console.log("CheckBoxChild", idName, selected)

    return (
        <div className="item">
            <div className="ui child checkbox">
                <input type="checkbox" checked={selected.has(id)} name={id} onChange={() => onChange(id)}/>
                <label>{name}</label>
            </div>
        </div>
    )
}


function blankBatchIdsInit(batch: SubstrateBatchV2 | undefined): Map<string, string> {
    if (!batch) {
        return new Map<string, string>()
    }

    console.log("blankBatchIdsInit", batch.blankBatchItems)

    return batch.blankBatchItems.reduce((acc, {id}: BlankBatchItem) => {
        if (!id) {
            return acc
        }

        acc.set(id, id)

        return acc
    }, new Map<string, string>())
}