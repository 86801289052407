/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubstrateBatchItemEvent
 */
export interface SubstrateBatchItemEvent {
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchItemEvent
     */
    state: string;
    /**
     * 
     * @type {Date}
     * @memberof SubstrateBatchItemEvent
     */
    date?: Date;
}

/**
 * Check if a given object implements the SubstrateBatchItemEvent interface.
 */
export function instanceOfSubstrateBatchItemEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function SubstrateBatchItemEventFromJSON(json: any): SubstrateBatchItemEvent {
    return SubstrateBatchItemEventFromJSONTyped(json, false);
}

export function SubstrateBatchItemEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubstrateBatchItemEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function SubstrateBatchItemEventToJSON(value?: SubstrateBatchItemEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
    };
}

