/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create liquidCultureBatch request object
 * @export
 * @interface UpdateLiquidCultureBatchRequest
 */
export interface UpdateLiquidCultureBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLiquidCultureBatchRequest
     */
    cultureStarterId: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLiquidCultureBatchRequest
     */
    volumePerContainer: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateLiquidCultureBatchRequest
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateLiquidCultureBatchRequest
     */
    disposalDate?: Date;
}

/**
 * Check if a given object implements the UpdateLiquidCultureBatchRequest interface.
 */
export function instanceOfUpdateLiquidCultureBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cultureStarterId" in value;
    isInstance = isInstance && "volumePerContainer" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function UpdateLiquidCultureBatchRequestFromJSON(json: any): UpdateLiquidCultureBatchRequest {
    return UpdateLiquidCultureBatchRequestFromJSONTyped(json, false);
}

export function UpdateLiquidCultureBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLiquidCultureBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cultureStarterId': json['cultureStarterId'],
        'volumePerContainer': json['volumePerContainer'],
        'startDate': (new Date(json['startDate'])),
        'disposalDate': !exists(json, 'disposalDate') ? undefined : (new Date(json['disposalDate'])),
    };
}

export function UpdateLiquidCultureBatchRequestToJSON(value?: UpdateLiquidCultureBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cultureStarterId': value.cultureStarterId,
        'volumePerContainer': value.volumePerContainer,
        'startDate': (value.startDate.toISOString()),
        'disposalDate': value.disposalDate === undefined ? undefined : (value.disposalDate.toISOString()),
    };
}

