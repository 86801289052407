import React, {ChangeEvent, useState} from "react";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAuthToken} from "../../query/jwt";
import {
    getSupplierCreateFormCache,
    Supplier,
    updateSupplierCreateFormCache
} from "../../query/supplier/query";
import keys from "../../query/keys";
import * as yup from "yup";
import {Formik} from "formik";
import {disableSubmit} from "../../components/form/validation";
import {FormErrors} from "../../components/form/errors";
import {FormGroup, LabelInputStacked} from "../../components/form/field";
import {Form as UIForm} from "semantic-ui-react";
import {AuthedFormAction} from "../../components/form/types";
import {CreateUpdateRecipeFormValues} from "../recipe/form";
import {resolvePriorityData} from "../../lib/data/resolve";

export type FormValues = {
    id: string
    name: string
}

const initialSupplierFormValues: FormValues = {
    id: "",
    name: ""
}

type CreateSupplierFormProps = {
    onSubmit: () => void
    supplier?: Supplier
    formAction: AuthedFormAction<FormValues>
}

export function SupplerCreateUpdateForm({onSubmit, formAction, supplier}: CreateSupplierFormProps) {
    const id = supplier?.id || ""

    const [notifications, setNotifications] = useState(emptyNotifications);
    const queryClient = useQueryClient()
    const getAuthToken = useAuthToken()

    const { mutate: mutateCreateUpdateSupplier } = useMutation(formAction(getAuthToken({})), {
        onSuccess: () => {
            queryClient.invalidateQueries(keys.suppliersGet);
            onSubmit()
        },
        onError: (err:any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `${err?.message}`)]  )
        }
    });


    const { mutate: mutateFormCache } = useMutation(updateSupplierCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(keys.suppliersCreateFormCache);
        },
        onError: (err) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${JSON.stringify(err)}`)]  )
        }
    });
    const formCacheQuery = useQuery(["suppliersCreateFormCache"], getSupplierCreateFormCache)

    const [formValues, setFormValues] = useState(resolvePriorityData(formDataFrom(supplier), formCacheQuery.data,  initialSupplierFormValues))

    const formSchema = yup.object().shape({
        name: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Name required'),
    });

    return (<div>
        <Formik
            initialValues={formValues}
            validationSchema={formSchema}
            onSubmit={(formData, {setSubmitting}) => {
                console.log('supplier: submitting form', formData)
                setSubmitting(true)

                formData.id = id
                mutateCreateUpdateSupplier(formData)
                setFormValues(initialSupplierFormValues)
                mutateFormCache(initialSupplierFormValues)
                setNotifications([])

                // allow form cache to clear
                setTimeout(() => {
                    setSubmitting(false)
                }, 1)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
              }) => {
                const setFormValuesWithChanges = (setFN: (formValues: FormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement>) => {
                    const newValues = {...values, id: ""} // never cache id
                    setFN(newValues, e.target.value)

                    handleChange(e)
                    setFormValues(newValues)
                    mutateFormCache(newValues)
                }

                const disable = disableSubmit(isValid, isSubmitting, touched)

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <FormErrors errors={JSON.stringify(errors)}/>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormGroup>
                            <LabelInputStacked
                                name="name"
                                label="Name"
                                onChange={setFormValuesWithChanges((f, v) => f.name = v)}
                            />
                        </FormGroup>

                        <UIForm.Button positive type={"submit"} disabled={disable}>{id==="" ? "Create" : "Update"}</UIForm.Button>
                    </form>)
            }}
        </Formik>
    </div>)
}

function formDataFrom(supplier?: Supplier): CreateUpdateRecipeFormValues | undefined {
    return supplier === undefined ? undefined : {
        id: supplier.id,
        name: supplier.name
    }
}