import {CreateSpawnBatchFormValues} from "../../pages/spawn-batch/form";
import {
    emptySpawnBatchDetails,
    newSpawnBatchItem,
    newSpawnBatchItemFromIndex,
    newSpawnBatchItemPartial,
    SpawnBatch,
    SpawnBatchItem, SpawnBatchItemPartial, spawnBatchItemToPartial, spawnBatchToDetails, toDetails
} from "./entities";
import {liquidCultureBatchItemStore} from "../liquid-culture-batch/inmemory";
import {arrayRange} from "../../lib/collections/collection";
import {autoIncrementID} from "../../lib/uuid/uuid";
import {newSpawnBatch} from "./entities";
import {ref} from "yup";
import {UpdateSpawnBatchItemFormValues} from "./query";
import {newInmemoryStore} from "../inmemory";
import {BlankBatch} from "../blank-batch/entities";


// data




export const spawnBatch1Item1 = newSpawnBatchItemPartial("11", "sp-batch-1","sp-batch-id-1", false, false)
export const spawnBatch1Item2 = newSpawnBatchItemPartial("12", "sp-batch-1","sp-batch-id-1", false, false)
const spawnBatch1Item3 = newSpawnBatchItemPartial("13", "sp-batch-1","sp-batch-id-1", false, false)
const spawnBatch2Item1 = newSpawnBatchItemPartial("21", "sp-batch-2","sp-batch-id-2", false, false)
const spawnBatch2Item2 = newSpawnBatchItemPartial("22", "sp-batch-2","sp-batch-id-2", false, false)
const spawnBatch2Item3 = newSpawnBatchItemPartial("23", "sp-batch-2","sp-batch-id-2", true, false)
const spawnBatch3Item1 = newSpawnBatchItemPartial("31", "sp-batch-2","sp-batch-id-3", true, false)
const spawnBatch3Item2 = newSpawnBatchItemPartial("32", "sp-batch-2","sp-batch-id-3", true, false)
const spawnBatch3Item3 = newSpawnBatchItemPartial("33", "sp-batch-2","sp-batch-id-3", true, false)




export const inMemSpawnBatchOne = newSpawnBatch(
    "sp-batch-id-1",
    "sp-batch-1",
    3,
    10,
    "a note",
    new Date('2022-12-23T03:24:00'),
    liquidCultureBatchItemStore.toArray()[0],
    [spawnBatch1Item1, spawnBatch1Item2, spawnBatch1Item3])

export const inMemSpawnBatchTwo = newSpawnBatch(
    "sp-batch-id-2",
    "sp-batch-2",
    3,
    11,
    "a second note",
    new Date('2022-12-22T03:24:00'),
    liquidCultureBatchItemStore.toArray()[0],
    [spawnBatch2Item1, spawnBatch2Item2, spawnBatch2Item3])

const inMemSpawnBatchThree = newSpawnBatch(
    "sp-batch-id-3",
    "sp-batch-3-spent",
    3,
    11,
    "a second note",
    new Date('2022-12-21T03:24:00'),
    liquidCultureBatchItemStore.toArray()[0],
    [spawnBatch3Item1, spawnBatch3Item2, spawnBatch3Item3])
// stores

export const spawnBatchItemPartialStore = newInmemoryStore<SpawnBatchItemPartial>()
// spawn batch 1 items
spawnBatchItemPartialStore.add(spawnBatch1Item1)
spawnBatchItemPartialStore.add(spawnBatch1Item2)
spawnBatchItemPartialStore.add(spawnBatch1Item3)
// spawn batch 2 items
spawnBatchItemPartialStore.add(spawnBatch2Item1)
spawnBatchItemPartialStore.add(spawnBatch2Item2)
spawnBatchItemPartialStore.add(spawnBatch2Item3)

// spawn batch 3 items
spawnBatchItemPartialStore.add(spawnBatch3Item1)
spawnBatchItemPartialStore.add(spawnBatch3Item2)
spawnBatchItemPartialStore.add(spawnBatch3Item3)




export const spawnBatchStore = newInmemoryStore<SpawnBatch>()
spawnBatchStore.add(inMemSpawnBatchOne)
spawnBatchStore.add(inMemSpawnBatchTwo)
spawnBatchStore.add(inMemSpawnBatchThree)

const emptySpawnBatchItems: SpawnBatchItem[] = []




export function updateInMemorySpawnBatchItems(items: SpawnBatchItemPartial[]) {
    items
        .forEach(item =>
            spawnBatchItemPartialStore.updateWith(i => i.id === item.id, _ => item)
        )
}

export const getSpawnsData = () => spawnBatchStore.toArray()
export const getSpawnByID = (id:string|undefined) => getSpawnsData().find(_spawn => _spawn.id === (id || ""))

export const getSpawnBatchItemsData = () => spawnBatchItemPartialStore.toArray()
export const getSpawnBatchItemByID = (id:string|undefined) => getSpawnBatchItemsData().find(_liquidCulture => _liquidCulture.id === (id || ""))

export async function inMemGetSpawnBatches(): Promise<SpawnBatch[]> {
    const spawnBatches = getSpawnsData()
    console.log("inMemGetSpawnBatches", spawnBatches)
    return getSpawnsData()
}

export async function inMemGetSpawnBatchItems(): Promise<SpawnBatchItem[]> {
    return spawnBatchItemPartialStore.toArray().map((item: SpawnBatchItemPartial) => {
        // get batch details
        const batch = spawnBatchStore.findFirst(batch => batch.id === item.spawnBatchID)
        if (batch === undefined) {
            throw new Error(`spawn batch ${item.spawnBatchID} not found`)
        }

        return newSpawnBatchItem(
            item.id,
            item.reference,
            spawnBatchToDetails(batch),
            item.spent,
            item.contaminated
        )
    })
}

export async function inMemCreateSpawn(create: CreateSpawnBatchFormValues): Promise<void> {
    console.log("CreateSpawnFormValues - query", create)

    const numberContainers = parseInt(create.numberContainers, 10)
    const weight = parseInt(create.weightGrams, 10)

    const liquidCultureBatchItem = liquidCultureBatchItemStore.findFirst(({id} ) => id === create.liquidCultureBatchItemID)
    if (liquidCultureBatchItem === undefined) {
        console.log("error", liquidCultureBatchItemStore.toArray(), create.liquidCultureBatchItemID)

        throw new Error(`spawn create could not find liquidCultureBatchItem ${create.liquidCultureBatchItemID}`)
    }


    const  {batchCode, notes, startDate} = create


    const created = newSpawnBatch(
        `spawn-id-${spawnBatchStore.length()+1}`,
        batchCode,
        numberContainers,
        weight,
        notes,
        startDate,
        liquidCultureBatchItem,
        [],
    )

    spawnBatchStore.add(created)

    console.log("CreateSpawnFormValues spawn batch", spawnBatchStore.toArray())

    const newSpawnBatchItems = arrayRange(numberContainers).map((_, i)=> newSpawnBatchItemFromIndex(autoIncrementID(), i+1, created ))


    newSpawnBatchItems.forEach( i => spawnBatchItemPartialStore.add(spawnBatchItemToPartial(i)))

    console.log("CreateSpawnFormValues spawn batch items", spawnBatchItemPartialStore.toArray())
}

export async function inMemUpdateSpawnItem(vales: UpdateSpawnBatchItemFormValues) {
    const batchFound = spawnBatchStore.findFirst((batch) => batch.id === vales.batchID)
    if (batchFound === undefined) {
        throw new Error("batch not found")
    }


    batchFound.items = batchFound.items.map((item) => {
        if (item.id === vales.id) {
            return newSpawnBatchItemPartial(item.id, item.reference, batchFound.id, item.spent, vales.contaminated)
        }

        return item
    })

    spawnBatchStore.update(({id}) => batchFound.id === id, (_) => batchFound )
}