import {
    inMemCreateBlankBatchesWithItems,
    inMemGetBlankBatchesWithItems,
    inMemUpdateBlankBatchesWithItems, inMemUpdateBlankBatchItem
} from "./inmemory";
import {BlankBatchFormValues} from "../../pages/blank-batch/batch-form";
import {JWTToken} from "../jwt";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {sdCreateBlankBatches, sdGetBlankBatches, sdUpdateBlankBatches, sdUpdateBlankBatchItem} from "./sdapi";

export async function getBlankBatchesWithItems(token: Promise<string>) {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetBlankBatches(token)
    }


    return inMemGetBlankBatchesWithItems()
}

export function authCreateBlankBatch(token: Promise<JWTToken>) {
    return async function (values: BlankBatchFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await  sdCreateBlankBatches(token, values)
            return
        }


        return inMemCreateBlankBatchesWithItems(values)
    }
}

export function authUpdateBlankBatch(token: Promise<JWTToken>) {
    return async function (values: BlankBatchFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await  sdUpdateBlankBatches(token, values)
            return
        }


        return inMemUpdateBlankBatchesWithItems(values)
    }
}


export type UpdateBlankBatchItemFormValues = {
    id: string
    batchId: string
    contaminated: boolean
}


export function authUpdateBlankBatchItem(token: Promise<JWTToken>) {
    return async function (values: UpdateBlankBatchItemFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await  sdUpdateBlankBatchItem(token, values)
            return
        }


        return inMemUpdateBlankBatchItem(values)
    }
}


let formCache: BlankBatchFormValues = {
    id: "",
    batchCode: "",
    createdDate: new Date(),
    pasteurizedDate: null,
    recipeId: "",
    unitTypeId: "",
    numberOfItems: "0",
    notes:"",
}

export async function getBlankBatchFormCache(): Promise<BlankBatchFormValues> {
    return formCache
}
export async function updateBlankBatchFormCache(values: BlankBatchFormValues) {
    formCache = values
}
