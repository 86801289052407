import React from "react";
import {Header, Modal} from "semantic-ui-react";

type BasicModalProps = {
    header: string
    trigger: JSX.Element
    children: JSX.Element | JSX.Element[]
    isOpen: boolean
    open: () => void
    close: () => void
    size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
}

export function BasicModal({header, trigger, children, isOpen, open, close, size}: BasicModalProps) {
    return (
        <Modal
            className={"pad-left-10"}
            closeIcon
            open={isOpen}
            trigger={trigger}
            onClose={close}
            onOpen={open}
            size={size}
        >
            <Header icon='address card' content={header} />
            <Modal.Content>
                {children}
            </Modal.Content>
        </Modal>
    )
}