import React, {ChangeEvent, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    getUnitTypeCreateFormCache,
    updateUnitTypeCreateFormCache
} from "../../query/unit-type/query";
import {Form as UIForm} from "semantic-ui-react";
import {Formik} from "formik";
import queryKeys from "../../query/keys";
import {getSuppliers} from "../../query/supplier/query";
import {FormGroup, LabelInputStacked, LabelSelectionStacked, selectOption} from "../../components/form/field";
import * as yup from "yup";
import {FormErrors} from "../../components/form/errors";
import {disableSubmit} from "../../components/form/validation";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {CreateSupplerModal} from "../supplier/modal";
import {UnitType} from "../../query/unit-type/inmemory";
import {resolvePriorityData} from "../../lib/data/resolve";
import {AuthedFormAction} from "../../components/form/types";

export type CreateUpdateUnitTypeFormValues = {
    id: string
    name: string
    supplierID: string
    fillWeight: string,
}

const initialUnitTypeFormValues: CreateUpdateUnitTypeFormValues = {
    id: "",
    name: "",
    supplierID: "",
    fillWeight: "",
}

type CreateUnitTypeFormProps = {
    // onChange: (form: CreateUnitTypeFormValues) => void
    onSuccess: () => void
    supplierID: string | undefined
    supplierName: string | undefined
    unitType?: UnitType | undefined
    formAction: AuthedFormAction<CreateUpdateUnitTypeFormValues>
}

export function UnitTypeForm({onSuccess, supplierID, supplierName, unitType, formAction}: CreateUnitTypeFormProps) {
    // todo next set up form action / mutation
    const id = unitType?.id || "";

    const getAuthToken = useAuthToken()
    const [notifications, setNotifications] = useState(emptyNotifications);

    const queryClient = useQueryClient()
    const { mutate: mutateFormCache } = useMutation(updateUnitTypeCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.unityTypeCreateFormCache);
        },
        onError: (err) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${JSON.stringify(err)}`)]  )
        }
    });

    const { mutate: mutateCreateUnitType } = useMutation(formAction(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.unitTypeGet);
            onSuccess()
        },
        onError: (err) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${JSON.stringify(err)}`)]  )
        }
    });


    const formCacheQuery = useQuery([queryKeys.unityTypeCreateFormCache], getUnitTypeCreateFormCache)


    const startingState = resolvePriorityData(formValuesFrom(unitType), formCacheQuery.data , initialUnitTypeFormValues)
    startingState.supplierID = supplierID || ""

    const [formValues, setFormValues] = useState(startingState)

    const fixSupplier = supplierID !== undefined && supplierID.length > 0

    const suppliersQuery = useQuery([queryKeys.suppliersGet], () => getSuppliers(getAuthToken({})))
    let suppliersOptions = suppliersQuery.data
        ? suppliersQuery.data.map(({name, id}, i) => (selectOption(id, name)))
        : [selectOption("0", "no suppliers please create")]

    if (fixSupplier) {
        const supplier = suppliersQuery.data?.find((s => s.id === supplierID))
        if (!supplier) {
            console.log("Error supplier can not be found", suppliersQuery.data, supplierID)
        } else {
            suppliersOptions = suppliersOptions.filter(supplierOption => supplierOption.id === supplier.id)
        }
    }

    const formSchema = yup.object().shape({
        name: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        supplierID: yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required(),
        fillWeight: yup.number()
            .min(0)
            .required('Required'),
    });

    const disableSupplier = unitType !== undefined // can't change supplier if updating
    const supplierButton = disableSupplier ? <></> : <CreateSupplerModal size={"mini"}/>
    const title = !!supplierName ? `Create Unit Type for '${supplierName}'` : "Create Unit Type"

    return (<div>
        <Formik
            initialValues={formValues}
            validate={createFormValidate}
            validationSchema={formSchema}
            onSubmit={(formValues, {setSubmitting}) => {
                setSubmitting(true)

                formValues.id = id

                // reset states
                // setFormValues(initialUnitTypeFormValues)
                mutateFormCache(initialUnitTypeFormValues)
                setNotifications([])

                // allow form cache to clear
                setTimeout(() => {
                    setSubmitting(false)
                    mutateCreateUnitType(formValues)
                }, 10)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
              }) => {
                const setFormValuesWithInputChanges = (setFN: (formValues: CreateUpdateUnitTypeFormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                    let newValues = {...values}
                    setFN(newValues, e.target.value)

                    console.log("newValues", newValues)

                    handleChange(e)
                    setFormValues(newValues)
                    mutateFormCache(newValues)
                }
                const disable = disableSubmit(isValid, isSubmitting, touched)

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormErrors errors={JSON.stringify(errors)}/>
                        <h3>{title}</h3>

                        <FormGroup>
                            <LabelSelectionStacked
                                disabled={disableSupplier}
                                selected={""}
                                blankOption={!fixSupplier}
                                options={suppliersOptions}
                                name={"supplierID"}
                                label={"Supplier"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.supplierID = v)}
                            />
                            {supplierButton}
                        </FormGroup>
                        <FormGroup>
                            <LabelInputStacked
                                name={"name"}
                                label={"Name"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.name = v)}
                            />
                            <LabelInputStacked
                                name={"fillWeight"}
                                label={"Fill Weight (g)"}
                                type={"number"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.fillWeight = v)}
                            />
                        </FormGroup>
                        <UIForm.Button positive type={"submit"} disabled={disable}>Create</UIForm.Button>
                    </form>)
            }}
        </Formik>
    </div>)
}

function createFormValidate() {
    return undefined
}

function formValuesFrom(unitType: UnitType | undefined): CreateUpdateUnitTypeFormValues | undefined {
    return unitType === undefined ? undefined : {
        id: unitType.id,
        name: unitType.name,
        supplierID: unitType.supplierID,
        fillWeight: `${unitType.fillWeight}`,
    }
}