


// setOrUnsetItem if key is in map it is removed else it is set
export function toggleKey(map: Map<string, string>, key: string): Map<string, string> {
    if (map.has(key)) {
        map.delete(key)

        return map
    }

    map.set(key, key)

    return map
}


// setAllOrRemoveAll takes a map and list of keys. if any of the keys are in the map all keys they are removed from the map.
export function toggleKeys(map: Map<string, string>, keys: string[]): Map<string, string>  {
    if (map.has(keys[0])) {
        keys.forEach(id => map.delete(id))
        return map
    }

    keys.forEach(id => map.set(id, id))

    return map
}


export function mapFromSingleValue<T>(value: T): Map<T, T> {
    return new Map([[value, value]])
}

