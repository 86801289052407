import React from "react";

type ErrorsProps = {
    errors: string // json
};

export function FormErrors(props: ErrorsProps) {
    let jsonObject = JSON.parse(props.errors)

    let map = new Map<string, string>()
    for (var value in jsonObject) {
        map.set(value,jsonObject[value])
    }

    if (map.size === 0) {
        return (<div></div>)
    }

    const errors: string[] = []
    map.forEach((v, k) => errors.push(`${k}: ${v}`))


    return (
        <div className={"ui message negative"}>
            <h5>Errors</h5>
            <ul className={"ui list"}>{errors.map((s, i) => <li key={i}>{s}</li>)}</ul>
        </div>
    );
};