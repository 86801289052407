


let inMemoryID = 1


// not to be used for DB, page reloads can set value back to 0
export function autoIncrementID(): string {
    const idStr = `${inMemoryID}`
    inMemoryID += 1

    return idStr
}