import {CreateUpdateUnitTypeFormValues} from "../../pages/unit-type/form";
import {inMemCreateUnitType, inMemGetUnitTypes, inMemUpdateUnitType, UnitType} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {sdCreateUnitType, sdGetUnitTypes, sdUpdateUnitType} from "./sdapi";

export async function getUnitTypes(token: Promise<string>): Promise<UnitType[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetUnitTypes(token)
    }

    return inMemGetUnitTypes()
}

export function createUnitTypeFN(token: Promise<string>) {
    return async function (values: CreateUpdateUnitTypeFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdCreateUnitType(token, values)
            return
        }

        return inMemCreateUnitType(values)
    }
}

export function updateUnitTypeFN(token: Promise<string>) {
    return async function (values: CreateUpdateUnitTypeFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateUnitType(token, values)
            return
        }

        return inMemUpdateUnitType(values)
    }
}


let formCache: CreateUpdateUnitTypeFormValues = {id: "", name: "", supplierID: "", fillWeight:""}

export async function getUnitTypeCreateFormCache(): Promise<CreateUpdateUnitTypeFormValues> {
    return formCache
}
export async function updateUnitTypeCreateFormCache(values: CreateUpdateUnitTypeFormValues) {
    formCache = values
}
