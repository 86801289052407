export const formikFormClasses = (error: string | undefined, touched: boolean | undefined): string => {
    let defaultClasses = "form-control";

    if (!!error && !!touched) {
        return defaultClasses + " is-invalid"
    }

    if (!error && !!touched) {
        return defaultClasses + " is-valid"
    }

    return defaultClasses
};



export function disableSubmit(isValid: boolean, isSubmitting: boolean, touched?: object | undefined): boolean {
    if (isSubmitting) {
        return true
    }

    if (!isValid) {
        return true
    }

    if (touched === undefined) {
        // enable
        return false
    }

    // if touch object is empty, nothing has been touched
    if (Object.keys(touched).length === 0) {
        return true
    }

    // enable
    return false
}