import SideNavContent from "../../components/side-nav-content";
import {DateInputOptional, initOptionalDate} from "../../components/form/DateInput";
import {useState} from "react";

export function ComponentLibrary(props: {}) {
    return (
            <SideNavContent>
                <OptionalFormDate/>
                <hr/>
            </SideNavContent>
    )
}



function OptionalFormDate(props: {}) {
    const [date, setDate] = useState(initOptionalDate);
    const onChange = (date: Date | null) => {
        setDate(date)
   }

    return (
        <div>
            <h1>Optional Data Picker</h1>

            <DateInputOptional name={"date"} label={"Optional Picker"} touched={false} date={date} onChange={onChange}/>
        </div>
    )
}