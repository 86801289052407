import React from "react";
import {Button} from "semantic-ui-react";
import {UnitTypeForm} from "./form";
import {BasicModal} from "../../components/modal/basic";
import {useModal} from "../../hooks/useModal";
import {UnitType} from "../../query/unit-type/inmemory";
import {IconEdit} from "../../components/icon/common";
import {createUnitTypeFN, updateUnitTypeFN} from "../../query/unit-type/query";


type CreateUnitTypeModelProps = {
    size: string
    supplierID: string | undefined
    supplierName: string | undefined
}

export function CreateUnitTypeModal(props: CreateUnitTypeModelProps) {
    const {isOpen, onOpen, onClose} = useModal()
    const isSmall = props.size === "sml" ? "mini " : ""
    const title = props.supplierID !== undefined? `Create Unit Type for ${props.supplierName}` : "Create Unit Type"

    return <BasicModal
        header={title}
        trigger={<Button type={"button"} className={isSmall} color='blue'>{title}</Button>}
        isOpen={isOpen}
        open={onOpen}
        close={onClose}>
            <UnitTypeForm
                supplierID={props.supplierID}
                supplierName={props.supplierName}
                onSuccess={onClose}
              formAction={createUnitTypeFN}
            />
    </BasicModal>

}


type UpdateUnitTypeModelProps = {
    unitType: UnitType
}

export function UpdateUnitTypeModal({unitType}: UpdateUnitTypeModelProps) {
    const {isOpen, onOpen, onClose} = useModal()
    let supplierName = unitType.supplier.name;
    const title = `Update Unit Type for ${supplierName}`


    return <BasicModal
        header={title}
        trigger={<Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>}
        isOpen={isOpen}
        open={onOpen}
        close={onClose}>
            <UnitTypeForm
                supplierID={unitType.supplierID}
                supplierName={supplierName}
                unitType={unitType}
                onSuccess={onClose}
                formAction={updateUnitTypeFN}
            />
    </BasicModal>

}


// UpdateUnitTypeModal