/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create blankBatch request object
 * @export
 * @interface CreateBlankBatchRequest
 */
export interface CreateBlankBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBlankBatchRequest
     */
    batchCode: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateBlankBatchRequest
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateBlankBatchRequest
     */
    pasteurizedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateBlankBatchRequest
     */
    recipeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBlankBatchRequest
     */
    unitTypeId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBlankBatchRequest
     */
    numberOfItems: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBlankBatchRequest
     */
    notes: string;
}

/**
 * Check if a given object implements the CreateBlankBatchRequest interface.
 */
export function instanceOfCreateBlankBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "recipeId" in value;
    isInstance = isInstance && "unitTypeId" in value;
    isInstance = isInstance && "numberOfItems" in value;
    isInstance = isInstance && "notes" in value;

    return isInstance;
}

export function CreateBlankBatchRequestFromJSON(json: any): CreateBlankBatchRequest {
    return CreateBlankBatchRequestFromJSONTyped(json, false);
}

export function CreateBlankBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBlankBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batchCode': json['batchCode'],
        'createdDate': (new Date(json['createdDate'])),
        'pasteurizedDate': !exists(json, 'pasteurizedDate') ? undefined : (new Date(json['pasteurizedDate'])),
        'recipeId': json['recipeId'],
        'unitTypeId': json['unitTypeId'],
        'numberOfItems': json['numberOfItems'],
        'notes': json['notes'],
    };
}

export function CreateBlankBatchRequestToJSON(value?: CreateBlankBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batchCode': value.batchCode,
        'createdDate': (value.createdDate.toISOString()),
        'pasteurizedDate': value.pasteurizedDate === undefined ? undefined : (value.pasteurizedDate.toISOString()),
        'recipeId': value.recipeId,
        'unitTypeId': value.unitTypeId,
        'numberOfItems': value.numberOfItems,
        'notes': value.notes,
    };
}

