import {CreateUpdateStrainFormValues} from "../../pages/strain/form";
import {inMemCreateStrain, inMemGetStrains, inMemUpdateStrain} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {JWTToken} from "../jwt";
import {sdCreateStrain, sdGetStrains, sdUpdateStrain} from "./sdapi";

export const newStrain = (id: string, name: string): Strain => ({id, name})

export function emptyStrain(): Strain {
    return {id: "", name: "",}
}

export type Strain = {
    id: string
    name: string
}


export async function getStrains(token: Promise<JWTToken>): Promise<Strain[]> {
    if (isEnvProduction() || isEnvIntegration()){
        return sdGetStrains(token)
    }

    // ignore the token
    return inMemGetStrains()
}

export function createStrainFN(token: Promise<JWTToken>) {
    return async function createStrain(values: CreateUpdateStrainFormValues) {
        if (isEnvProduction() || isEnvIntegration()){
            await sdCreateStrain(token, values)
            return
        }

        return inMemCreateStrain(values)
    }
}

export function updateStrainFN(token: Promise<JWTToken>) {
    return async function createStrain(values: CreateUpdateStrainFormValues) {
        if (isEnvProduction() || isEnvIntegration()){
            await sdUpdateStrain(token, values)
            return
        }

        return inMemUpdateStrain(values)
    }
}






// form cache
let formCache: CreateUpdateStrainFormValues = {id: "", name: ""}

export async function getStrainCreateFormCache(): Promise<CreateUpdateStrainFormValues> {
    return formCache
}
export async function updateStrainCreateFormCache(values: CreateUpdateStrainFormValues) {
    formCache = values
}
