

export const DEBUG = 0
export const INFO = 1
export const WARNING = 2

let GlobalLevel = DEBUG

export const setLogLevel = (l: number) => GlobalLevel = l

const levelDebug = (level: number) => (...data: any) => {
    if (GlobalLevel > level) {
        return
    }

    console.log(...data)
}

const debug = levelDebug(DEBUG)
const info = levelDebug(INFO)
const warning = levelDebug(WARNING)

const logger = {debug, info, warning}
export default logger


export function tapLogger<T>(toLog: T, label?: string): T {
    if (label) {
        console.log(label, toLog)

        return toLog
    }

    console.log(toLog)

    return toLog
}