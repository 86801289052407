import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    authedCreateLiquidCultureBatch,
    getLiquidCultureCreateFormCache,
    updateLiquidCultureCreateFormCache
} from "../../query/liquid-culture-batch/query";
import queryKeys from "../../query/keys";
import React, {ChangeEvent, useState} from "react";
import {Formik} from "formik";
import {FormErrors} from "../../components/form/errors";
import {FormGroup, LabelInputStacked, LabelSelectionStacked, selectOption} from "../../components/form/field";
import {DateInput, DateInputOptional} from "../../components/form/DateInput";
import {Form as UIForm} from "semantic-ui-react";
import * as yup from "yup";
import {disableSubmit} from "../../components/form/validation";
import {CreateUpdateCultureStarterModal} from "../culture-starter/modal";
import {getCultureStarters} from "../../query/culture-starter/query";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {cultureStartersSorter} from "../culture-starter/table";
import {AuthedFormAction} from "../../components/form/types";
import {resolvePriorityData} from "../../lib/data/resolve";
import {LiquidCultureBatch} from "../../query/liquid-culture-batch/entities";

// const DEBUG_LABEL = "LIQUID_CULTURE"

export type CreateLiquidCultureBatchFormValues = {
    id: string
    batchCode: string
    // change this syringe name to something like startCulture
    cultureStarterID: string
    // syringe
    numberOfContainers: string
    startDate: Date
    disposalDate: Date | null
    // per container
    volume: string
}

export const initialFormValues: CreateLiquidCultureBatchFormValues = {
    id: "",
    batchCode: "",
    // syringe
    cultureStarterID: "",
    numberOfContainers: "",
    startDate: new Date(),
    disposalDate: null,
    volume: "",
}


const formValuesFrom = (batch: LiquidCultureBatch | undefined): CreateLiquidCultureBatchFormValues | undefined  => batch === undefined
    ? undefined
    : {
        id: batch.id,
        batchCode: batch.batchCode,
        cultureStarterID: batch.cultureStarterID,
        numberOfContainers: batch.numberOfContainers.toString(),
        startDate: batch.startDate,
        disposalDate: batch.disposalDate,
        volume: batch.volume.toString(),
    }

type CreateUpdateFormProps = {
    batch: LiquidCultureBatch | undefined
    formAction: AuthedFormAction<CreateLiquidCultureBatchFormValues>
    onSuccess: () => void
}

export function CreateUpdateForm({onSuccess, formAction, batch}: CreateUpdateFormProps) {
    const getAuthToken = useAuthToken()
    const queryClient = useQueryClient()
    const [notifications, setNotifications] = useState(emptyNotifications);

    // setup form cache
    const { mutate: mutateFormCache } = useMutation(updateLiquidCultureCreateFormCache, {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.liquidCulturesBatchCreateFormCache)
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)]  )
        }
    });
    const formCacheQuery = useQuery([queryKeys.liquidCulturesBatchCreateFormCache], getLiquidCultureCreateFormCache)
    const cultureStarterQuery = useQuery([queryKeys.cultureStarterGet], () => getCultureStarters(getAuthToken()))

    // create liquid culture batch mutation
    const { mutate: mutateCreateLiquidCulture } = useMutation(formAction(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.liquidCultureBatchesGet);
            onSuccess();
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `submit error ${err?.message}`)]  )
        }
    });

    // set up form state
    const [formValues, setFormValues] = useState(
        resolvePriorityData(formValuesFrom(batch), formCacheQuery.data, initialFormValues)
    )
    const [startDate, setStartDate ] = useState(formValues.startDate)
    const [disposalDate, setDisposalDate ] = useState(formValues.disposalDate)

    const cultureStarterOptions = cultureStarterQuery.data
        ? cultureStarterQuery.data
            .filter(({spent}) => !spent)
            .sort(cultureStartersSorter)
            .map(({batchCode, source, id, strain}) => (selectOption(id, `${source} | ${strain.name} | ${batchCode}`)))
        : [selectOption("", "No Syringe Cultures please create")]

    const formSchema = yup.object().shape({
        batchCode: yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('batch code required'),
        cultureStarterID: yup.string()
            .label("syringe cultures")
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('syringe required'),
        numberOfContainers: yup.number()
            .min(0, 'Too Short!')
            .required('jars required'),
        startDate: yup.date().required("start date required"),
        disposalDate: yup.date().nullable(),
        volume: yup.number()
            .min(0, 'Too Short!')
            .required('volume required'),

    });

    return (<div>
        <Formik
            initialValues={formValues}
            onSubmit={(formValues, {setSubmitting}) => {
                const newFormValues = {...formValues, startDate, disposalDate}
                setSubmitting(true)
                setFormValues(initialFormValues)
                mutateFormCache(initialFormValues)
                setNotifications([])

                setTimeout(() => {
                    mutateCreateLiquidCulture(newFormValues)
                    setSubmitting(false)
                }, 1)

            }}
            validationSchema={formSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
              }) => {
                const setFormValuesWithInputChanges = (setFN: (formValues: CreateLiquidCultureBatchFormValues, value:string) => any) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
                    let newValues = {...values, startDate, disposalDate}
                    setFN(newValues, e.target.value)

                    mutateFormCache(newValues)
                    setFormValues(newValues)
                    handleChange(e)
                }

                const disable = disableSubmit(isValid, isSubmitting)
                const isCreate = batch === undefined

                return (
                    <form onSubmit={handleSubmit} className={"ui form"}>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormErrors errors={JSON.stringify(errors)}/>
                        <FormGroup>
                            { !isCreate? <></> :
                                <LabelInputStacked
                                    name={"batchCode"}
                                    label={"Batch Code"}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.batchCode = v)}
                                />
                            }

                            { !isCreate? <></> :
                                <LabelInputStacked
                                    name={"numberOfContainers"}
                                    type={"number"}
                                    label={"# Jars"}
                                    onChange={setFormValuesWithInputChanges((f, v) => f.numberOfContainers = v)}
                                />
                            }
                            <LabelInputStacked
                                name={"volume"}
                                type={"number"}
                                label={"Volume/Jar (ml)"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.volume = v)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <LabelSelectionStacked
                                blankOption={true}
                                selected={values.cultureStarterID || ""}
                                options={cultureStarterOptions}
                                name={"cultureStarterID"}
                                label={"Syringe Cultures"}
                                onChange={setFormValuesWithInputChanges((f, v) => f.cultureStarterID = v)}
                            />
                            <CreateUpdateCultureStarterModal size={"mini"}/>
                        </FormGroup>


                        <FormGroup>
                            <DateInput
                                name={"startDate"}
                                date={startDate}
                                label={"Start"}
                                onChange={setStartDate}
                                touched={true}
                            />
                            <DateInputOptional
                                name={"disposalDate"}
                                date={disposalDate}
                                label={"Disposal Date"}
                                onChange={setDisposalDate}
                                touched={true}
                            />

                        </FormGroup>

                        <UIForm.Button positive type={"submit"} disabled={disable}>Create</UIForm.Button>
                    </form>
                )
            }}
        </Formik>
    </div>)
}
