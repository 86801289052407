import React, {useState} from "react";

type TabData = {
    label: string
    pageID: string
    componentFN: () => JSX.Element

}

export const  newTabData = (pageID: string, label: string, componentFN: () => JSX.Element): TabData => ({pageID, label, componentFN})

type TabMenuProps = {
    startingTabPageID: string

    tabData: TabData[]
}

export function TabMenu({startingTabPageID, tabData }: TabMenuProps) {
    const [selectedTab, setSelectedTab] = useState(startingTabPageID);
    const tabContentByPageId = new Map<string, () => JSX.Element>()
    const handleTabClick = (e: React.MouseEvent<HTMLDivElement>) => {
        console.log("click tab", e.currentTarget.id)
        setSelectedTab(e.currentTarget.id)
    }

    const children = tabData.map(({pageID, label, componentFN}: TabData) => {
        // add to map
        tabContentByPageId.set(pageID,componentFN)

        return (
            <TabNavItem key={pageID} pageID={pageID} selectedTab={selectedTab} onHandleClick={handleTabClick} label={label} />
        )
    })

    const tabNotFoundElement = () => <div> tab not found</div>
    const tabPage = tabContentByPageId.get(selectedTab) || tabNotFoundElement


    return (
        <>
            <div className="ui top attached tabular menu">
                {children}
            </div>
            <div className="ui bottom attached active tab segment">
                {tabPage()}
            </div>
        </>
    )
}

type TabNavItemProps = {
    pageID: string,
    selectedTab: string,
    label: string
    onHandleClick: (e: React.MouseEvent<HTMLDivElement>) => void,
}

export function TabNavItem({pageID, selectedTab, onHandleClick, label}: TabNavItemProps) {
    return <div id={pageID} className={tabClasses(selectedTab, pageID)} onClick={onHandleClick}>{label}</div>
}

function tabClasses(selectedID: string, tabID: string) {
    const classes = "item"

    if (selectedID === tabID) {
        return classes + " active"
    }

    return classes
}