import React from "react";

export const newNotification = (level: Level, message: string): Notification => ({level, message})


export const emptyNotifications: Notification[] = []

export type Level = "error" | "warning" | "positive"

export type Notification = {
    level: Level
    message: string
}

type NotificationProps = {
    notifications: Notification[]
    clearAll: () => void
}

export function Notifications({notifications, clearAll}: NotificationProps) {
    if (notifications.length === 0) {
        return <></>
    }

    const positiveNotifications: JSX.Element[] = []
    const errorNotifications: JSX.Element[] = []
    const warningNotifications: JSX.Element[] = []


    notifications.forEach((notification, i) => {
        switch (notification.level) {
            case "error":
                errorNotifications.push(<li key={i}>{notification.message}</li>)
                return;
            case "positive":
                positiveNotifications.push(<li key={i}>{notification.message}</li>)
                return;
            case "warning":
                warningNotifications.push(<li key={i}>{notification.message}</li>)
                return;
        }
    })

    return (
        <div>
            <NotificationsList level={"positive"} notifications={positiveNotifications} clearAll={clearAll}/>
            <NotificationsList level={"warning"} notifications={warningNotifications} clearAll={clearAll}/>
            <NotificationsList level={"error"} notifications={errorNotifications} clearAll={clearAll}/>
        </div>
    )
}

type NotificationsListProps = {
    notifications: JSX.Element[]
    level: Level
    clearAll: () => void

}

function NotificationsList({notifications, level, clearAll}: NotificationsListProps) {
    if (notifications.length === 0) {
        return <></>
    }

    let classNames = "ui message "

    if (level === "error") {
        classNames += "negative"
    }

    if (level === "warning") {
        classNames += "yellow"
    }

    if (level === "positive") {
        classNames += "success"
    }


    return(
        <div className={classNames}>
            <i onClick={clearAll} className="close icon"></i>
            <ul className={"ui list"}>
                {notifications}
            </ul>
        </div>
    )
}