import {JWTToken} from "../jwt";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {CreateCultureStarterRequest, CultureStarterEntity} from "../../generated-sources/openapi";
import {newCultureStarter} from "./inmemory";
import {CultureStarter} from "./query";
import {CreateUpdateCultureStarterFormValues} from "../../pages/culture-starter/form";
import {actualOrUndefined} from "../../lib/null/null";
import {mapStrainEntityToStrain} from "../strain/sdapi";
import {mapSupplierEntityToSupplier} from "../supplier/sdapi";


export async function sdGetCultureStarters(token: Promise<JWTToken>): Promise<CultureStarter[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const cultureStarters = await client.getAllCultureStarters(undefined, authHeaderBearer(jwt))

    return cultureStarters.map(mapCultureStarterEntityToCultureStarter)
}

export async function sdCreateCultureStarter(token: Promise<JWTToken>, values: CreateUpdateCultureStarterFormValues): Promise<CultureStarter> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const createCultureStarterRequest: CreateCultureStarterRequest = {
        name: "DELETE this param",
        batchCode: values.batchCode,
        source: values.source,
        incubationStart: values.incubationStart,
        disposalDate: actualOrUndefined(values.disposalDate),
        strainId: values.strainID,
        supplierId: values.supplierID,
    }

    const cultureStarterEntity = await client.createCultureStarter({createCultureStarterRequest}, authHeaderBearer(jwt))

    return mapCultureStarterEntityToCultureStarter(cultureStarterEntity)
}

export async function sdUpdateCultureStarter(token: Promise<JWTToken>, values: CreateUpdateCultureStarterFormValues): Promise<CultureStarter> {
    const {defaultClient} = getAPIClient()
    const jwt = await token

    const {id} = values

    const updateCultureStarterRequest: CreateCultureStarterRequest = {
        name: "DELETE this param",
        batchCode: values.batchCode,
        source: values.source,
        incubationStart: values.incubationStart,
        disposalDate: actualOrUndefined(values.disposalDate),
        strainId: values.strainID,
        supplierId: values.supplierID,
    }

    const cultureStarterEntity = await defaultClient.updateCultureStarterByID({updateCultureStarterRequest, id}, authHeaderBearer(jwt))

    return mapCultureStarterEntityToCultureStarter(cultureStarterEntity)
}


export function mapCultureStarterEntityToCultureStarter( starter: CultureStarterEntity): CultureStarter {
    return newCultureStarter(
        starter.id,
        starter.batchCode,
        "syringe",
        "insourced",
        starter.incubationStart,
        starter.disposalDate,
        mapStrainEntityToStrain(starter.strain),
        mapSupplierEntityToSupplier(starter.supplier),
        starter.spent
    )
}


