/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubstrateBatchItemEvent } from './SubstrateBatchItemEvent';
import {
    SubstrateBatchItemEventFromJSON,
    SubstrateBatchItemEventFromJSONTyped,
    SubstrateBatchItemEventToJSON,
} from './SubstrateBatchItemEvent';

/**
 * update substrateBatchItems request object. The IDs provided must all belong to the batch
 * @export
 * @interface UpdateSubstrateBatchItemsRequest
 */
export interface UpdateSubstrateBatchItemsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSubstrateBatchItemsRequest
     */
    ids: Array<string>;
    /**
     * 
     * @type {Array<SubstrateBatchItemEvent>}
     * @memberof UpdateSubstrateBatchItemsRequest
     */
    states: Array<SubstrateBatchItemEvent>;
    /**
     * 
     * @type {Date}
     * @memberof UpdateSubstrateBatchItemsRequest
     */
    contaminated?: Date;
}

/**
 * Check if a given object implements the UpdateSubstrateBatchItemsRequest interface.
 */
export function instanceOfUpdateSubstrateBatchItemsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ids" in value;
    isInstance = isInstance && "states" in value;

    return isInstance;
}

export function UpdateSubstrateBatchItemsRequestFromJSON(json: any): UpdateSubstrateBatchItemsRequest {
    return UpdateSubstrateBatchItemsRequestFromJSONTyped(json, false);
}

export function UpdateSubstrateBatchItemsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSubstrateBatchItemsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'states': ((json['states'] as Array<any>).map(SubstrateBatchItemEventFromJSON)),
        'contaminated': !exists(json, 'contaminated') ? undefined : (new Date(json['contaminated'])),
    };
}

export function UpdateSubstrateBatchItemsRequestToJSON(value?: UpdateSubstrateBatchItemsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'states': ((value.states as Array<any>).map(SubstrateBatchItemEventToJSON)),
        'contaminated': value.contaminated === undefined ? undefined : (value.contaminated.toISOString()),
    };
}

