/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create liquidCultureBatch request object
 * @export
 * @interface CreateLiquidCultureBatchRequest
 */
export interface CreateLiquidCultureBatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLiquidCultureBatchRequest
     */
    batchCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiquidCultureBatchRequest
     */
    cultureStarterId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLiquidCultureBatchRequest
     */
    numberOfContainers: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLiquidCultureBatchRequest
     */
    volumePerContainer: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateLiquidCultureBatchRequest
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateLiquidCultureBatchRequest
     */
    disposalDate?: Date;
}

/**
 * Check if a given object implements the CreateLiquidCultureBatchRequest interface.
 */
export function instanceOfCreateLiquidCultureBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "cultureStarterId" in value;
    isInstance = isInstance && "numberOfContainers" in value;
    isInstance = isInstance && "volumePerContainer" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function CreateLiquidCultureBatchRequestFromJSON(json: any): CreateLiquidCultureBatchRequest {
    return CreateLiquidCultureBatchRequestFromJSONTyped(json, false);
}

export function CreateLiquidCultureBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLiquidCultureBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batchCode': json['batchCode'],
        'cultureStarterId': json['cultureStarterId'],
        'numberOfContainers': json['numberOfContainers'],
        'volumePerContainer': json['volumePerContainer'],
        'startDate': (new Date(json['startDate'])),
        'disposalDate': !exists(json, 'disposalDate') ? undefined : (new Date(json['disposalDate'])),
    };
}

export function CreateLiquidCultureBatchRequestToJSON(value?: CreateLiquidCultureBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batchCode': value.batchCode,
        'cultureStarterId': value.cultureStarterId,
        'numberOfContainers': value.numberOfContainers,
        'volumePerContainer': value.volumePerContainer,
        'startDate': (value.startDate.toISOString()),
        'disposalDate': value.disposalDate === undefined ? undefined : (value.disposalDate.toISOString()),
    };
}

