import React from 'react';
import mainClassNames, {classes} from "../styling/css";

type props = {
    children: JSX.Element | JSX.Element[]
}

const SideNavContent = ({children}: props) => {
    return (
        <div className={classes(mainClassNames.mainContent)}>
            {children}
        </div>
    );
};

export default SideNavContent;
