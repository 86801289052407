import {JWTToken} from "../jwt";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {CreateFlushRequest, flushFrom, Harvest, newHarvest} from "./entities";
import {CreateHarvestRequest, HarvestEntity} from "../../generated-sources/openapi";
import {mapSubstrateBatchEntityToSubstrateBatch} from "../substrate/sdapi";

export async function sdGetHarvests(token: Promise<JWTToken>): Promise<Harvest[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const spawnBatches = await client.getAllHarvests(undefined, authHeaderBearer(jwt))

    return spawnBatches.map(mapSpawnBatchEntitySpawnBatch)
}


export async function sdCreateHarvest(token: Promise<JWTToken>, {flush, batchID, harvestNumbers}: CreateFlushRequest): Promise<Harvest> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const createHarvestRequest: CreateHarvestRequest = {
        flush,
        substrateBatchId: batchID,
        weights: harvestNumbers
    }

    const {id, substrateBatch, flush: flushResponse,weights} = await client.createHarvest({createHarvestRequest}, authHeaderBearer(jwt))

    return newHarvest(
        id,
        mapSubstrateBatchEntityToSubstrateBatch(substrateBatch),
        flushFrom(flushResponse),
        weights
    )
}


function mapSpawnBatchEntitySpawnBatch({flush, id, weights, substrateBatch}: HarvestEntity): Harvest {
    return {
        batch: mapSubstrateBatchEntityToSubstrateBatch(substrateBatch), // todo this mapping my blow up
        flush: flushFrom(flush),
        id,
        weights,
    }
}