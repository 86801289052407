import {Table} from "semantic-ui-react";
import queryKeys from "../../query/keys";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import React from "react";
import {getSpawnBatches} from "../../query/spawn-batch/query";
import {SpawnBatch, spawnBatchStrainName} from "../../query/spawn-batch/entities";
import {CreateSpawnBatchModel} from "./spawn";
import {formatDateString} from "../../components/form/DateInput";
import {useAuthToken} from "../../query/jwt";
import {sortByDate} from "../../util/sort/datesort";
import {TableHeader} from "../../components/table/header";
import {CreateUpdateLiquidCultureModal} from "../liquid-culture-batch/modal";
import {ItemModal} from "./items-modal";

export function SpawnBatchTablePage(props: {}) {
    const getAuthToken = useAuthToken()
    const title = "Spawn Batches "
    const queryKey = queryKeys.spawnBatchesGet
    const headers =  ["Batch Code", "Strain", "# Containers", "Weight / Unit (g)", "Start Date", "Action"]
    const numberCols = headers.length

    const header = <TableHeader headers={headers}/>

    const rowsMapper = (batch: SpawnBatch) => {
        const spentItems = batch.items.filter(({spent}) => spent ).length
        const contaminated = batch.items.filter(({contaminated}) => contaminated ).length
        const remaining = batch.numberContainers - (spentItems + contaminated)

        console.log("remaining:", batch.id, remaining)
        const batchSpent = remaining < 1
        const className =  batchSpent ? "negative":""

        // TODO: possible bug in the UI with the record turning red

        return (
            <Table.Row className={className} key={batch.id}>
                <Table.Cell>{batch.batchCode}</Table.Cell>
                <Table.Cell>{spawnBatchStrainName(batch)}</Table.Cell>
                <Table.Cell><ItemModal batch={batch} numberSpentItems={spentItems} available={remaining} isBatchSpent={batchSpent} contaminated={contaminated}/></Table.Cell>
                <Table.Cell>{batch.weightGrams}</Table.Cell>
                <Table.Cell>{formatDateString(batch.startDate)}</Table.Cell>
            </Table.Row>
        )
    }

    const createButton = <CreateSpawnBatchModel onSuccess={() => {}} size={"small"}/>

    return(
        <CRUDQueryTablePage<SpawnBatch>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getSpawnBatches(getAuthToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberCols}
            rowSort={(items) => items.sort(spawnBatchesSortByDate) }
        />
    )
}


export const spawnBatchesSortByDate = sortByDate<SpawnBatch>( ({startDate}) => startDate )
