
/**
 * Returns the highest priority value from the given parameters.
 *
 * @param {T | undefined} highPriority - the value with higher priority
 * @param {T | undefined} lowPriority - the value with lower priority
 * @param {T} defaultValues - the default values to use if both highPriority and lowPriority are undefined
 * @return {T} the highest priority value
 */
export function resolvePriorityData<T>(highPriority: T | undefined, lowPriority: T | undefined, defaultValues: T) {
    return highPriority !== undefined
        ? highPriority
        : lowPriority !== undefined ? lowPriority : defaultValues
}