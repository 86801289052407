import React from "react";
import {
    selectOption
} from "../../components/form/field";
import {CreateFlushRequest, Flush} from "../../query/harvest/entities";
import {SubstrateBatchV2} from "../../query/substrate/entities";

const initialWeights: Weight[] = []


export function HarvestBatchCreateForm({onSuccess}: {onSuccess:()=> void}) {
    return <div>Needs re-implementing due to substrate batch changes</div>
}


type BatchHarvestForm = {
    batchID: string
    flush: Flush | string
    harvestNumbers: number[]
}

const initialFormValues: BatchHarvestForm = {
    batchID: "",
    flush: "",
    harvestNumbers: []
}


function mapToOption(items: SubstrateBatchV2[], label: string) {
    if (items.length===0) {
        return []
    }

    return items.map((item) => selectOption(item.id, `${label} - ${item.id}`))
}



type HarvestInputLabelProps = {
    id: string,
    value: string
    onClick: (id: string) => void
}


function HarvestWeightLabel({id, value, onClick}: HarvestInputLabelProps) {
    return (
        <div key={id} className="ui image label">
            {value}
            <i className="delete icon" onClick={() => onClick(id)}></i>
        </div>
    )
}

type Weight =  {
    id: string
    value: number
}

const newWeight = (id: string, value: number): Weight => ({id, value})
const readable = (flush: Flush | string): string => {
    return flush === "firstFlush"
        ? "First Flush"
        : flush === "secondFlush"
            ? "Second Flush"
            : ""
}


type ValidationMessage = {
    field: string,
    message: string,
}

function validate(values: CreateFlushRequest):  ValidationMessage[] {
    const messages: ValidationMessage[] = []



    if (values.batchID === "") {
        messages.push({field: "batchID", message: "batchID is required"})
    }

    if(values.harvestNumbers.length === 0 ) {
        messages.push({field: "harvestNumbers", message: "harvestNumbers is required"})
    }

    return messages
}