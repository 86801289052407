import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import { Table} from "semantic-ui-react";
import React from "react";
import {getStrains, Strain} from "../../query/strain/query";
import queryKeys from "../../query/keys";
import {CreateUpdateStrainModal} from "./modal";
import {useAuthToken} from "../../query/jwt";
import {TableHeader} from "../../components/table/header";

export function StrainsTablePage() {
    const getToken = useAuthToken()

    const title = "Strains"
    const queryKey = queryKeys.strainGet
    const headers = ["Name", "Actions"]
    const header = <TableHeader headers={headers}/>
    const numberOfCols = headers.length

    const rowsMapper = (strain: Strain) => {
        return (
            <Table.Row key={strain.id}>
                <Table.Cell>{strain.name}</Table.Cell>
                <Table.Cell><CreateUpdateStrainModal strain={strain}/></Table.Cell>
            </Table.Row>
        )
    }
    const createButton = <CreateUpdateStrainModal size={"small"}/>
    return(
        <CRUDQueryTablePage<Strain>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getStrains(getToken({}))}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberOfCols}
        />
    )
}

