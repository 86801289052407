import {CreateRecipe, newRecipe, Recipe, UpdateRecipe} from "./query";
import {findAndReplace} from "../inmemory";

export const RecipeOne = newRecipe("r1", "outsourced oyster")
export const RecipeTwo = newRecipe("r2", "outsourced lions mane")



export let recipes = [
    RecipeOne,
    RecipeTwo,
    newRecipe("r3", "in sourced oyster"),
]

export const getRecipesData = () => recipes
export const getRecipeByID = (id:string|undefined) => getRecipesData().find(_strain => _strain.id === (id || ""))

export async function inMemGetRecipes(): Promise<Recipe[]> {
    return recipes
}

export async function inMemCreateRecipe(create: CreateRecipe): Promise<void> {
    recipes = [...recipes, newRecipe(`r${recipes.length+1}`, create.name)]
}


export async function inMemUpdateRecipe(toUpdate: UpdateRecipe): Promise<void> {
    recipes = findAndReplace(recipes, (recipe) => toUpdate.id === recipe.id , () => newRecipe(toUpdate.id, toUpdate.name))
}

