import {JWTToken} from "../jwt";
import {newRecipe, Recipe} from "./query";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {CreateRecipeRequest, UpdateRecipeRequest} from "../../generated-sources/openapi";
import {CreateUpdateRecipeFormValues} from "../../pages/recipe/form";


export async function sdGetRecipes(token: Promise<JWTToken>): Promise<Recipe[]> {
    const {coreClient: client} = getAPIClient()

    const jwt = await token

    const suppliers = await client.getAllRecipes(undefined, authHeaderBearer(jwt))

    return suppliers.map(mapRecipeEntityToRecipe)
}

export async function sdCreateRecipe(token: Promise<JWTToken>, values: CreateUpdateRecipeFormValues): Promise<Recipe> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const createRecipeRequest: CreateRecipeRequest = {
        name: values.name
    }

    const supplier = await client.createRecipe({createRecipeRequest}, authHeaderBearer(jwt))

    return newRecipe(supplier.id, supplier.name)
}


export async function sdUpdateRecipe(token: Promise<JWTToken>, {id, name}: CreateUpdateRecipeFormValues): Promise<Recipe> {
    const {defaultClient} = getAPIClient()
    const jwt = await token

    const updateRecipeRequest: UpdateRecipeRequest = {name}
    const supplier = await defaultClient.updateRecipeByID({updateRecipeRequest, id}, authHeaderBearer(jwt))

    return newRecipe(supplier.id, supplier.name)
}


export function mapRecipeEntityToRecipe(entity: Recipe): Recipe {
    return newRecipe(entity.id, entity.name)
}