import {JWTToken} from "../jwt";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {CreateUnitTypeRequest, UnitTypeEntity, UpdateUnitTypeRequest} from "../../generated-sources/openapi";
import {newUnitType, UnitType} from "./inmemory";
import {CreateUpdateUnitTypeFormValues} from "../../pages/unit-type/form";

export async function sdGetUnitTypes(token: Promise<JWTToken>): Promise<UnitType[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const unitTypes = await client.getAllUnitTypes(undefined, authHeaderBearer(jwt))

    return unitTypes.map(mapUnitTypeEntityToUnitType)
}
//
export async function sdCreateUnitType(token: Promise<JWTToken>, values: CreateUpdateUnitTypeFormValues): Promise<UnitType> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const fillWeightInt = parseInt(values.fillWeight, 10)

    const createUnitTypeRequest: CreateUnitTypeRequest = {
        name: values.name,
        fillWeight: fillWeightInt,
        supplierId: values.supplierID
    }

    const unitType = await client.createUnitType({createUnitTypeRequest}, authHeaderBearer(jwt))

    return newUnitType(unitType.id, unitType.name, unitType.supplier, unitType.fillWeight)
}

export async function sdUpdateUnitType(token: Promise<JWTToken>, values: CreateUpdateUnitTypeFormValues): Promise<UnitType> {
    const {defaultClient} = getAPIClient()
    const jwt = await token


    const {id, name, supplierID: supplierId, fillWeight: fillWeightStr} = values
    const fillWeight = parseInt(fillWeightStr, 10)
    const updateUnitTypeRequest: UpdateUnitTypeRequest = {name, fillWeight, supplierId}

    const unitType = await defaultClient.updateUnitTypeByID({updateUnitTypeRequest, id}, authHeaderBearer(jwt))

    return newUnitType(unitType.id, unitType.name, unitType.supplier, unitType.fillWeight)
}



export function mapUnitTypeEntityToUnitType(unitType: UnitTypeEntity): UnitType {
    return newUnitType(unitType.id, unitType.name, unitType.supplier, unitType.fillWeight)
}
