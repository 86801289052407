/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create unitType request object
 * @export
 * @interface CreateUnitTypeRequest
 */
export interface CreateUnitTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUnitTypeRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUnitTypeRequest
     */
    fillWeight: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUnitTypeRequest
     */
    supplierId: string;
}

/**
 * Check if a given object implements the CreateUnitTypeRequest interface.
 */
export function instanceOfCreateUnitTypeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "fillWeight" in value;
    isInstance = isInstance && "supplierId" in value;

    return isInstance;
}

export function CreateUnitTypeRequestFromJSON(json: any): CreateUnitTypeRequest {
    return CreateUnitTypeRequestFromJSONTyped(json, false);
}

export function CreateUnitTypeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUnitTypeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fillWeight': json['fillWeight'],
        'supplierId': json['supplierId'],
    };
}

export function CreateUnitTypeRequestToJSON(value?: CreateUnitTypeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fillWeight': value.fillWeight,
        'supplierId': value.supplierId,
    };
}

