/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BlankBatchItemPartialEntity } from './BlankBatchItemPartialEntity';
import {
    BlankBatchItemPartialEntityFromJSON,
    BlankBatchItemPartialEntityFromJSONTyped,
    BlankBatchItemPartialEntityToJSON,
} from './BlankBatchItemPartialEntity';
import type { SpawnBatchItemEntity } from './SpawnBatchItemEntity';
import {
    SpawnBatchItemEntityFromJSON,
    SpawnBatchItemEntityFromJSONTyped,
    SpawnBatchItemEntityToJSON,
} from './SpawnBatchItemEntity';

/**
 * create substrateBatch response object
 * @export
 * @interface SubstrateBatchEntity
 */
export interface SubstrateBatchEntity {
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchEntity
     */
    batchCode: string;
    /**
     * 
     * @type {SpawnBatchItemEntity}
     * @memberof SubstrateBatchEntity
     */
    spawnBatchItem: SpawnBatchItemEntity;
    /**
     * 
     * @type {string}
     * @memberof SubstrateBatchEntity
     */
    notes: string;
    /**
     * 
     * @type {Date}
     * @memberof SubstrateBatchEntity
     */
    createdDate: Date;
    /**
     * 
     * @type {Array<BlankBatchItemPartialEntity>}
     * @memberof SubstrateBatchEntity
     */
    blankBatchItems: Array<BlankBatchItemPartialEntity>;
}

/**
 * Check if a given object implements the SubstrateBatchEntity interface.
 */
export function instanceOfSubstrateBatchEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "batchCode" in value;
    isInstance = isInstance && "spawnBatchItem" in value;
    isInstance = isInstance && "notes" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "blankBatchItems" in value;

    return isInstance;
}

export function SubstrateBatchEntityFromJSON(json: any): SubstrateBatchEntity {
    return SubstrateBatchEntityFromJSONTyped(json, false);
}

export function SubstrateBatchEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubstrateBatchEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'batchCode': json['batchCode'],
        'spawnBatchItem': SpawnBatchItemEntityFromJSON(json['spawnBatchItem']),
        'notes': json['notes'],
        'createdDate': (new Date(json['createdDate'])),
        'blankBatchItems': ((json['blankBatchItems'] as Array<any>).map(BlankBatchItemPartialEntityFromJSON)),
    };
}

export function SubstrateBatchEntityToJSON(value?: SubstrateBatchEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'batchCode': value.batchCode,
        'spawnBatchItem': SpawnBatchItemEntityToJSON(value.spawnBatchItem),
        'notes': value.notes,
        'createdDate': (value.createdDate.toISOString()),
        'blankBatchItems': ((value.blankBatchItems as Array<any>).map(BlankBatchItemPartialEntityToJSON)),
    };
}

