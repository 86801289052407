import React from "react";
import {Button} from "semantic-ui-react";
import {SupplerCreateUpdateForm} from "./form";
import {createSupplierFN, Supplier, updateSupplierFN} from "../../query/supplier/query";
import {IconEdit} from "../../components/icon/common";
import {BasicModal} from "../../components/modal/basic";
import {useModal} from "../../hooks/useModal";
import {SemanticSIZES} from "semantic-ui-react/dist/commonjs/generic";


type ModalProps = {
    size?: SemanticSIZES
}

export function CreateSupplerModal({size}: ModalProps) {
    const {isOpen, onOpen, onClose} = useModal()

    const btnSize = size === undefined ? "small" : size

    return (
        <BasicModal
            header="Update Supplier"
            trigger={<Button size={btnSize} color={"blue"} >Create Supplier</Button>}
            isOpen={isOpen}
            open={onOpen}
            close={onClose}
        >
            <SupplerCreateUpdateForm onSubmit={onClose} formAction={createSupplierFN}/>
        </BasicModal>
    )
}


type UpdateSupplerModalProps = {
    supplier: Supplier
}

export function UpdateSupplerModal({supplier}: UpdateSupplerModalProps) {
    const {isOpen, onOpen, onClose} = useModal()

    return (
        <BasicModal
            header="Update Supplier"
            trigger={<Button size={"mini"} color={"blue"} icon={<IconEdit/>}/>}
            isOpen={isOpen}
            open={onOpen}
            close={onClose}
        >
            <SupplerCreateUpdateForm supplier={supplier} onSubmit={onClose} formAction={updateSupplierFN}/>
        </BasicModal>
    )
}
