import {CreateUpdateCultureStarterFormValues} from "../../pages/culture-starter/form";
import {ContainerType, CultureStarter, Source} from "./query";
import {getStrainByID, strains} from "../strain/inmemory";
import logger from "../../log/logger";
import {Strain} from "../strain/query";
import {getSupplierByID, suppliers} from "../supplier/inmemory";
import {Supplier} from "../supplier/query";
import {findAndReplace} from "../inmemory";


const sourceFrom = (s: string): Source => s === "insourced" ? "insourced" : "outsourced"

export const newCultureStarter= (
    id: string,
    batchCode: string,
    containerType: ContainerType,
    source: Source,
    incubationStart: Date,
    disposalDate: Date | null | undefined,
    strain: Strain,
    supplier: Supplier,
    spent: boolean
): CultureStarter  => ({id, batchCode, source, containerType, incubationStart, disposalDate, strain, supplier, spent})

export let cultureStarters:CultureStarter[] = [
    newCultureStarter("cultureStarter-id-1", "oy-ba-1", "syringe", "insourced", new Date("2022-12-10T03:24:00"), null, strains[0], suppliers[0], false),
    newCultureStarter("cultureStarter-id-2", "oy-ba-2-latest", "syringe", "insourced", new Date(), null, strains[0], suppliers[0], false),
    newCultureStarter("cultureStarter-id-3", "oy-ba-2-spent", "syringe", "insourced", new Date(), null, strains[0], suppliers[0], true),
]

export const CultureStartersData = () => cultureStarters
export const CultureStarterByID = (id:string|undefined) => CultureStartersData().find(_cultureStarter => _cultureStarter.id === (id || ""))

export async function inMemGetCultureStarters(): Promise<CultureStarter[]> {
    return cultureStarters
}

export async function inMemCreateCultureStarter(create: CreateUpdateCultureStarterFormValues): Promise<void> {
    const {batchCode, source: sourceStr, incubationStart, disposalDate, strainID, supplierID} = create

    const source: Source  = sourceFrom(sourceStr)

    const strain = getStrainByID(strainID)
    if (strain === undefined) {
        logger.debug(`could not get strain by id ${strainID} ${strains}`)
        throw new Error(`could not get strain by id ${strainID}`)
    }


    const supplier = getSupplierByID(supplierID)
    if (supplier === undefined) {
        logger.debug(`could not get supplier by id ${supplierID} ${suppliers}`)
        throw new Error(`could not get supplier by id ${supplierID}`)
    }

    const created = newCultureStarter(`cultureStarter-id-${cultureStarters.length+1}`, batchCode, "syringe", source, incubationStart, disposalDate, strain, supplier, false)
    cultureStarters = [...cultureStarters, created]
}

export async function inMemUpdateCultureStarter(update: CreateUpdateCultureStarterFormValues): Promise<void> {
    const {id, batchCode, source: sourceStr, incubationStart, disposalDate, strainID, supplierID} = update

    const source: Source  = sourceFrom(sourceStr)

    const strain = getStrainByID(strainID)
    if (strain === undefined) {
        logger.debug(`could not get strain by id ${strainID} ${strains}`)
        throw new Error(`could not get strain by id ${strainID}`)
    }


    const supplier = getSupplierByID(supplierID)
    if (supplier === undefined) {
        logger.debug(`could not get supplier by id ${supplierID} ${suppliers}`)
        throw new Error(`could not get supplier by id ${supplierID}`)
    }

    cultureStarters = findAndReplace(
        cultureStarters,
        (item) => item.id === id,
        () => newCultureStarter(
            id,
            batchCode,
            "syringe",
            source,
            incubationStart,
            disposalDate,
            strain,
            supplier,
            false,
        ),
    )
}


