import {CreateUpdateStrainFormValues} from "../../pages/strain/form";
import {Strain} from "./query";
import {findAndReplace} from "../inmemory";


// duplicate due to webpack
const newStrain = (id: string, name: string): Strain => ({id, name})

export let strains:Strain[] = [
    newStrain("strain-id-1", "oyster"),
    newStrain("strain-id-2", "lions mane"),
]

export const getStrainsData = () => strains
export const getStrainByID = (id:string|undefined) => getStrainsData().find(_strain => _strain.id === (id || ""))

export async function inMemGetStrains(): Promise<Strain[]> {
    return strains
}
export async function inMemCreateStrain(create: CreateUpdateStrainFormValues): Promise<void> {
    strains = [...strains, newStrain(`strain-id-${strains.length+1}`, create.name)]
}
export async function inMemUpdateStrain(update: CreateUpdateStrainFormValues): Promise<void> {
    strains = findAndReplace(
        strains,
        ({id}): boolean => id === update.id,
        () => newStrain(update.id, update.name),
    )
}





