import React from "react";


type CheckBoxProps = {
    name: string
    label: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string | undefined
}

export function CheckBox({name, label, onChange, className}: CheckBoxProps) {
    const defaultClasses = "ui checkbox"
    const classes = className === undefined ? defaultClasses : defaultClasses + " " + className

    return (
        <div style={{display: "inline"}} className={classes}>
            <input type="checkbox" name={name} onChange={onChange}/>
            <label>{label}</label>
        </div>
    )
}