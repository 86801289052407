import {JWTToken} from "../jwt";
import {newSupplier, Supplier} from "./query";
import {authHeaderBearer, getAPIClient} from "../base-url";
import {FormValues} from "../../pages/supplier/form";
import {CreateSuppliersRequest, SupplierEntity, UpdateSupplierRequest} from "../../generated-sources/openapi";


export async function sdGetSuppliers(token: Promise<JWTToken>): Promise<Supplier[]> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const suppliers = await client.getAllSuppliers(undefined, authHeaderBearer(jwt))

    return suppliers.map(mapSupplierEntityToSupplier)
}

export async function sdCreateSupplier(token: Promise<JWTToken>, values: FormValues): Promise<Supplier> {
    const {coreClient: client} = getAPIClient()
    const jwt = await token

    const createSuppliersRequest: CreateSuppliersRequest = {
        name: values.name
    }

    const supplier = await client.createSupplier({createSuppliersRequest}, authHeaderBearer(jwt))

    return mapSupplierEntityToSupplier(supplier)
}


export async function sdUpdateSupplier(token: Promise<JWTToken>, values: FormValues): Promise<Supplier> {
    const {defaultClient} = getAPIClient()
    const jwt = await token

    const {id, name} = values

    const updateSupplierRequest: UpdateSupplierRequest = {
        name
    }

    const supplier = await defaultClient.updateSupplierByID({updateSupplierRequest, id}, authHeaderBearer(jwt))

    return mapSupplierEntityToSupplier(supplier)
}


export function mapSupplierEntityToSupplier(supplier: SupplierEntity): Supplier {
    return newSupplier(
        supplier.id,
        supplier.name
    )
}