import React from 'react';
import SideNavContent from "../../components/side-nav-content";
import {
    Grid,
} from "semantic-ui-react";
import {StrainsTablePage} from "../strain/table";
import {SubstrateBatchPage} from "../substrate/batch-table";
import {RecipeTablePage} from "../recipe/table";
import {SpawnBatchTablePage} from "../spawn-batch/table";
import {SupplierTablePage} from "../supplier/table";
import {UnitTypeTablePage} from "../unit-type/table";
import {CultureStarterTablePage} from "../culture-starter/table";
import {LiquidCultureBatchTablePage} from "../liquid-culture-batch/table";
import {newTabData, TabMenu} from "../../components/tab-menu";
import {BlankBatchPage} from "../blank-batch/batch-table";

export type BatchType = "outsourced" | "insourced"
export const isOutsourced = (batchType: BatchType) => batchType === "outsourced"

const InventoryPage = () => {
    const pageIDSubstrateBatch = "substrate-batch-tab"

    const tabData = [
        newTabData(pageIDSubstrateBatch, "Substrate Batches", () => <SubstrateBatchPage/>),
        newTabData("blank-batch-tab", "Basic Batches", () => <BlankBatchPage/>),
        newTabData("spawn-batch-tab", "Spawn Batches",  () => <SpawnBatchTablePage/>),
        newTabData("liquid-culture-batch", "Liquid Culture Batches", () =><LiquidCultureBatchTablePage/>),
        newTabData("culture-starter-tab", "Syringes", () =><CultureStarterTablePage/>),
        newTabData("strain-tab", "Strains", () => <StrainsTablePage/>),
        newTabData("recipe-tab", "Recipes", () => <RecipeTablePage/>),
        newTabData("unit-type-tab", "Unit Types", () => <UnitTypeTablePage/>),
        newTabData("supplier-tab", "Suppliers", () => <SupplierTablePage/>),
    ]

    const style = {
        paddingLeft: 20,
    }

    return (
        <SideNavContent>
            <Grid>
                <h1 style={style} >Manage Inventory</h1>
                <TabMenu startingTabPageID={pageIDSubstrateBatch} tabData={tabData} />
            </Grid>
        </SideNavContent>
    );
};


export default InventoryPage;
