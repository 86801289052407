import {Header, Table} from "semantic-ui-react";
import React from "react";
import queryKeys from "../../query/keys";
import {getSubstrateBatchesWithItems} from "../../query/substrate/query";
import { getSubstrateBatchStrain, SubstrateBatchV2} from "../../query/substrate/entities";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import {useAuthToken} from "../../query/jwt";
import {TableHeader} from "../../components/table/header";
import {InoculationModel} from "./create-edit-modal";
import {spawnBatchesSortByDate} from "../spawn-batch/table";
import {sortByDate} from "../../util/sort/datesort";
import {SpawnBatch} from "../../query/spawn-batch/entities";
import {formatDateString} from "../../components/form/DateInput";
import {BlankBatchItemsModal} from "../blank-batch/items-modal";

export function SubstrateBatchPage(props: {}) {
    const getToken = useAuthToken()
    const headers = ["ID", "Batch Code", "Strain", "Spawn Batch Reference", "Created Date", "View Inoculated Inventory", "Actions"]
    const numberOfColumns = headers.length
    const header =  <TableHeader headers={headers}/>

    const rowsMapper = (substrateInventory: SubstrateBatchV2)=> {
        return (
            <Table.Row key={substrateInventory.id}>
                <Table.Cell>{substrateInventory.id}</Table.Cell>
                <Table.Cell>{substrateInventory.batchCode}</Table.Cell>
                <Table.Cell>{getSubstrateBatchStrain(substrateInventory).name}</Table.Cell>
                <Table.Cell>{substrateInventory.spawnBatchItem.reference}</Table.Cell>
                <Table.Cell>{formatDateString(substrateInventory.createdDate)}</Table.Cell>
                <Table.Cell><BlankBatchItemsModal items={substrateInventory.blankBatchItems}/> </Table.Cell>
                {/*actions*/}
                <Table.Cell><InoculationModel batch={substrateInventory}  /></Table.Cell>
            </Table.Row>
        )
    }

    const createButton = (<div>
        <InoculationModel />
        {/*<CreateModal batchType={"outsourced"}/>*/}
    </div>)

    const queryKey = queryKeys.substrateBatchesGet

    return(
        <CRUDQueryTablePage<SubstrateBatchV2>
            title={"Substrate Batches"}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getSubstrateBatchesWithItems(getToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberOfColumns}
            rowSort={(items) => items.sort(substrateBatchesSortByDate) }
        />
    )

}


export const substrateBatchesSortByDate = sortByDate<SubstrateBatchV2>( ({createdDate}) => createdDate )
