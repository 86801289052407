import {useAuth0} from "@auth0/auth0-react";
import {isEnvIntegration, isEnvProduction} from "../env";


export function useUserAuthenticated() {
    let { user, isAuthenticated, isLoading } = useAuth0()
    if (isEnvProduction() || isEnvIntegration()) {
        return { user, isAuthenticated, isLoading }
    }

    return { user: {name: "devmode", email: ""}, isAuthenticated: true, isLoading: false }
}
