import {Table} from "semantic-ui-react";
import React from "react";
import {CRUDQueryTablePage} from "../../components/crud-table/crud-table";
import {TableHeader} from "../../components/table/header";
import queryKeys from "../../query/keys";
import {Harvest, totalWeight} from "../../query/harvest/entities";
import {getHarvests} from "../../query/harvest/query";
import {HarvestModel} from "./modal";
import {useAuthToken} from "../../query/jwt";

export function HarvestTable(props: {}) {
    const getAuthToken = useAuthToken()

    const title = "Harvests"
    const queryKey = queryKeys.harvestsGet
    const headers = ["Reference", "Substrate Batch", "flush", "Weight Total/Individual (g)", "# Units Harvested"]
    const numberCols = headers.length


    const header =  (
        <TableHeader headers={headers}></TableHeader>
    )

    const rowsMapper = (harvest: Harvest)=> {
        return (
            <Table.Row key={harvest.id}>
                <Table.Cell>{harvest.id}</Table.Cell>
                <Table.Cell>{harvest.batch.id}</Table.Cell>
                <Table.Cell>{harvest.flush}</Table.Cell>
                <Table.Cell>{totalWeight(harvest)}: {harvest.weights.join(",")}</Table.Cell>
                <Table.Cell>{harvest.weights.length}</Table.Cell>
            </Table.Row>
        )
    }

    const createButton = (<HarvestModel/>)

    return(
        <CRUDQueryTablePage<Harvest>
            title={title}
            header={header}
            createButtonRow={createButton}
            queryFunction={() => getHarvests(getAuthToken())}
            rowsMapper={rowsMapper}
            queryKey={queryKey}
            numberCols={numberCols}
        />
    )

}