import {useModal} from "../../hooks/useModal";
import {Button} from "semantic-ui-react";
import {IconCheck, IconClose, IconEdit} from "../../components/icon/common";
import {BasicModal} from "../../components/modal/basic";
import React, {useState} from "react";
import {SpawnBatch, SpawnBatchItemPartial} from "../../query/spawn-batch/entities";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {useMutation, useQueryClient} from "react-query";
import queryKeys from "../../query/keys";
import {useAuthToken} from "../../query/jwt";
import {authUpdateSpawnBatchItem} from "../../query/spawn-batch/query";


type ItemModalProps = {
    batch: SpawnBatch
    numberSpentItems: number
    available: number
    contaminated: number
    isBatchSpent: boolean
}

export function ItemModal({batch, numberSpentItems, available, isBatchSpent, contaminated}:ItemModalProps) {
    const title = "Spawn Batch Items"
    const {isOpen, onOpen, onClose} = useModal()

    const spentItems = numberSpentItems
    const remaining = available

    const color = isBatchSpent ? "red" : "grey"

    const trigger = <Button type={"button"} className={"small"} color={color}>{`${remaining}/${batch.numberContainers} Available [spent:${spentItems},contaminated:${contaminated}]`}</Button>


    return (
        <BasicModal
            close={onClose}
            header={title}
            isOpen={isOpen}
            open={onOpen}
            trigger={trigger}
        >
            <SpawnBatchItemsTable batch={batch}/>
        </BasicModal>
    )
}


type SpawnBatchItemsTableProps = {
    batch: SpawnBatch
}

function SpawnBatchItemsTable({batch}: SpawnBatchItemsTableProps) {
    const queryClient = useQueryClient()
    const [notifications, setNotifications] = useState(emptyNotifications);
    const [contaminatedValues, setContaminatedValues] = useState(initContaminatedMap(batch.items))
    const getAuthToken = useAuthToken()

    const { mutate: contaminateAction } = useMutation(authUpdateSpawnBatchItem(getAuthToken()), {
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(queryKeys.spawnBatchesGet).then(() => {
                setContaminatedValues( before =>  {
                    const after = new Map(before)
                    after.set(variables.id, variables.contaminated)

                    return after
                })
            });
        },
        onError: (err: any) => {
            setNotifications((notifications) => [...notifications, newNotification("error", `form cache error ${err?.message}`)]  )
        }
    });
    if (batch.items.length === 0) {
        return <div>No items</div>
    }

    const rows = batch.items.map(({id, reference, spent}) => {
        const maybeContaminated = contaminatedValues.get(id)
        if (maybeContaminated === undefined) {
            console.log(`contaminated value for id ${id} does not exist `)
        }

        const contaminated =  maybeContaminated !== undefined ? maybeContaminated : true // if undefined set to contaminated so no action can be taken
        return (
            <tr key={id}>
                <td data-label="ID">{id}</td>
                <td data-label="Reference">{reference}</td>
                <td data-label="Spent">{spent ? <IconCheck/> : <IconClose/> }</td>
                <td data-label="Contaminated">{contaminated ? <IconCheck/> : <IconClose/> }</td>
                <td data-label="Action">
                    <Button
                        disabled={contaminated || spent}
                        color={"red"}
                        onClick={() => {
                            contaminateAction({id, batchID: batch.id, contaminated: !contaminated})
                        }}
                    >
                        {"Contaminate"}
                    </Button>
                </td>
            </tr>
        )
    })
    return (
        <div >
            <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
            <table className="ui celled table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Reference</th>
                    <th>Spent</th>
                    <th>Contaminated</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    )
}


function initContaminatedMap(items: SpawnBatchItemPartial[]) {
    return items.reduce((acc, {id, contaminated}, i) => {
        acc.set(id, contaminated)

        return acc
    }, new Map<string, boolean>())
}