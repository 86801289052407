import {inMemCreateRecipe, inMemGetRecipes, inMemUpdateRecipe} from "./inmemory";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {JWTToken} from "../jwt";
import {sdCreateRecipe, sdGetRecipes, sdUpdateRecipe} from "./sdapi";
import {CreateUpdateRecipeFormValues} from "../../pages/recipe/form";

export type Recipe = {
    id: string
    name: string
}

export type CreateRecipe =  {
    name: string
}


export type UpdateRecipe =  {
    id: string
    name: string
}

export const newRecipe = (id: string, name: string ) => ({id, name})


export async function getRecipes(token: Promise<JWTToken>): Promise<Recipe[]> {
    if (isEnvProduction() || isEnvIntegration()) {
        return sdGetRecipes(token)
    }

    return inMemGetRecipes()
}

let formCache: CreateUpdateRecipeFormValues = {id: "", name: ""}

export async function getRecipeCreateFormCache(): Promise<CreateUpdateRecipeFormValues> {
    return {...formCache}
}

export async function updateRecipeCreateFormCache(values: CreateUpdateRecipeFormValues) {
    formCache = values

    console.log("recipe: form cache values", formCache)
}


export function createRecipeFN(token: Promise<JWTToken>) {
    return async function createRecipe(values: CreateUpdateRecipeFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdCreateRecipe(token, values)
            return
        }

        return inMemCreateRecipe(values)
    }
}


export function updateRecipeFN(token: Promise<JWTToken>) {
    return async function(values: CreateUpdateRecipeFormValues) {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateRecipe(token, values)
            return
        }

        return inMemUpdateRecipe(values)
    }
}


